/* eslint-disable no-return-await */
import {
  EXPERIENCES_DROPDOWN_API,
  LOCATIONS_DROPDOWN_API,
  REGIONS_DROPDOWN_API,
} from './urls';
import { getAPICall } from './utils';

export async function getDropdownRegionsAPICall(params) {
  return await getAPICall(REGIONS_DROPDOWN_API, params);
}

export async function getDropdownLocationsAPICall(params) {
  return await getAPICall(LOCATIONS_DROPDOWN_API, params);
}

export async function getDropdownExperiencesAPICall(params) {
  return await getAPICall(EXPERIENCES_DROPDOWN_API, params);
}
