/* eslint-disable react/no-unstable-nested-components */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select } from 'antd';
import { isEmpty } from 'lodash';

import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import './_selectAndCreateOptionDropdown.scss';

const SelectAndCreateOptionDropdown = forwardRef(
  (
    {
      addOptionLabel = 'Add item',
      addOptionPlaceholder = 'Please enter item',
      disabledItems = {},
      dropdownError,
      isNewOptionLoading = false,
      isPaginationLoading = false,
      labelInValue = false,
      labelKey = 'name',
      newOptionError,
      newOptionValue,
      nextDataAction = null,
      onAddNewOption,
      onChange,
      onChangeNewOption,
      onTabChange,
      options,
      paginationData = null,
      placeholder,
      selectedOption,
      selectTabs = [],
      showAddOption = false,
      showSearch = true,
      styles = {},
      suffixIcon = null,
      valueKey = 'id',
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(selectTabs[0]);
    const [open, setOpen] = useState(false);

    const activeTabRef = useRef(activeTab);

    const handleNewOptionChange = useCallback(
      (value) => onChangeNewOption?.(value),
      [onChangeNewOption],
    );

    const handleAddItem = useCallback(
      (e) => {
        e.preventDefault();
        onAddNewOption?.();
      },
      [onAddNewOption],
    );

    const handleScrollToEnd = useCallback(
      (e) => {
        const pagination = paginationData ?? activeTab?.paginationData;
        const nextAction = nextDataAction ?? activeTab?.nextDataAction;
        const paginationLoading =
          activeTab?.isPaginationLoading || isPaginationLoading;
        if (!pagination || !nextAction || paginationLoading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const { count, results } = pagination;

        if (
          scrollTop + clientHeight >= scrollHeight &&
          count > results.length
        ) {
          const nextPage = Math.ceil(results.length / DEFAULT_PAGE_SIZE) + 1;
          dispatch(
            nextAction({ page: nextPage, page_size: DEFAULT_PAGE_SIZE }),
          );
        }
      },
      [dispatch, paginationData, nextDataAction, activeTab],
    );

    const handleTabChange = (tab) => {
      onTabChange?.(tab);
      setActiveTab(tab);
    };

    const handleOptionChange = (newOption) => {
      activeTabRef.current = activeTab;
      onChange(newOption, activeTab);
    };

    const onDropdownVisibleChange = (isOpen) => {
      setOpen(isOpen);
      if (!isOpen) {
        setActiveTab(activeTabRef.current);
      }
    };

    const selectOptions = useMemo(() => {
      const currentOptions = activeTab ? activeTab.options : options;
      return currentOptions.map(({ [labelKey]: label, [valueKey]: value }) => ({
        value,
        label,
        disabled: disabledItems[value],
      }));
    }, [options, disabledItems, labelKey, valueKey, activeTab]);

    useImperativeHandle(ref, () => ({
      open,
      setOpen,
    }));

    useEffect(() => {
      if (!selectTabs.length) return;
      let currentTab;
      if (!activeTab) {
        // eslint-disable-next-line prefer-destructuring
        currentTab = selectTabs[0];
      } else {
        currentTab = selectTabs.find((t) => t.id === activeTab.id);
      }
      setActiveTab(currentTab);
      activeTabRef.current = currentTab;
    }, [selectTabs]);

    return (
      <div
        className={`select-and-create-option-dropdown ${
          dropdownError ? 'invalid-dropdown' : ''
        }`}
      >
        <Select
          open={open}
          labelInValue={labelInValue}
          className="select-dropdown"
          popupClassName="select-dropdown-options-container"
          onSearch={handleNewOptionChange}
          onPopupScroll={handleScrollToEnd}
          style={styles}
          value={isEmpty(selectedOption) ? undefined : selectedOption}
          placeholder={placeholder || 'Select an option'}
          onChange={handleOptionChange}
          showSearch={showSearch}
          filterOption={(input, option) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          onDropdownVisibleChange={onDropdownVisibleChange}
          loading={isNewOptionLoading}
          dropdownRender={(menu) => (
            <>
              {selectTabs.length ? (
                <div className="select-and-create-option-dropdown-tabs">
                  {selectTabs.map((tab) => (
                    <button
                      key={tab.id}
                      className={`select-tab ${
                        activeTab?.id === tab.id ? 'active' : ''
                      }`}
                      onClick={() => handleTabChange(tab)}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              ) : null}
              {menu}
              {isPaginationLoading ? (
                <span style={{ padding: '5px 12px', color: '#de0024' }}>
                  <b>Loading...</b>
                </span>
              ) : null}
              {showAddOption ? (
                <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <div
                    className={`add-option-container ${
                      isNewOptionLoading ? 'disabled-new-option' : ''
                    }`}
                  >
                    <div className="add-option">
                      <Input
                        disabled={isNewOptionLoading}
                        className="add-option-input"
                        placeholder={addOptionPlaceholder}
                        value={newOptionValue || ''}
                        onChange={({ target: { value } }) =>
                          handleNewOptionChange(value)
                        }
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        disabled={isNewOptionLoading}
                        type="text"
                        className="add-option-btn"
                        icon={<PlusOutlined />}
                        onClick={handleAddItem}
                      >
                        {addOptionLabel}
                      </Button>
                    </div>
                    <div>
                      {newOptionError ? (
                        <span className="control-error">{newOptionError}</span>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          options={selectOptions}
          {...(suffixIcon !== undefined ? { suffixIcon } : {})}
        />
        {dropdownError ? (
          <span className="control-error">{dropdownError}</span>
        ) : null}
      </div>
    );
  },
);

export default SelectAndCreateOptionDropdown;
