import { toast } from 'react-toastify';

import { createSlice } from '@reduxjs/toolkit';

import { PAGINATED_RESPONSE } from 'utils/constants';

import {
  assignFormtoExperience,
  getEntityLinkedExperiences,
  postExperience,
  updateFormtoExperience,
} from './experienceActions';

const INITIAL_STATE = {
  redirectToSurvey: false,
  isLoading: false,
  entityData: null,
  updatedForms: null,
  entitiyLinkedExperiences: {
    data: PAGINATED_RESPONSE,
    loading: false,
  },
};

const experienceSlice = createSlice({
  name: 'experienceSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetExperienceSlice: () => INITIAL_STATE,
    resetEntityLinkedExperiences: (state) => {
      state.entitiyLinkedExperiences = {
        data: PAGINATED_RESPONSE,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postExperience.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postExperience.rejected, (state) => {
      state.isLoading = false;
      state.redirectToSurvey = false;
      toast.error('Error adding new Experience!');
    });
    builder.addCase(postExperience.fulfilled, (state, action) => {
      state.isLoading = false;
      state.redirectToSurvey = true;
      state.entityData = action.payload;
      toast.success('Successfully added new Experience!');
    });

    builder.addCase(updateFormtoExperience.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFormtoExperience.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFormtoExperience.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedForms = action.payload.data;
      toast.success(action.payload.message);
    });

    builder.addCase(assignFormtoExperience.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignFormtoExperience.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignFormtoExperience.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedForms = action.payload;
      toast.success('Your location has been saved successfully!');
    });

    builder.addCase(getEntityLinkedExperiences.pending, (state) => {
      state.entitiyLinkedExperiences.loading = true;
    });
    builder.addCase(getEntityLinkedExperiences.rejected, (state) => {
      state.entitiyLinkedExperiences.loading = false;
    });
    builder.addCase(
      getEntityLinkedExperiences.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.entitiyLinkedExperiences.loading = false;
        const { page } = arg ?? {};
        // If it is first Page
        if (!page || page < 2) {
          state.entitiyLinkedExperiences.data = payload;
        } else {
          const prevResult = [...state.entitiyLinkedExperiences.data.results];
          state.entitiyLinkedExperiences.data = {
            ...payload,
            results: [...prevResult, ...payload.results],
          };
        }
      },
    );

    return builder;
  },
});

export const { resetExperienceSlice, resetEntityLinkedExperiences } =
  experienceSlice.actions;
export default experienceSlice;
