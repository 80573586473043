import React, { useState } from 'react';

import { Avatar } from 'antd';

import { colorList } from 'utils/constants';
import { changeOpacity } from 'utils/helpers';

function ReviewMetaSection({ icon, heading, value }) {
  const [showDefaultIcon, setShowDefaultIcon] = useState(false);
  const colorIndex = Math.floor(Math.random() * colorList.length);

  return (
    <div className="feedback-meta">
      {!showDefaultIcon && (
        <img
          src={icon}
          alt={heading}
          onError={() => setShowDefaultIcon(true)}
        />
      )}
      {showDefaultIcon && (
        <Avatar
          style={{
            backgroundColor: changeOpacity(colorList[colorIndex], 0.12),
            color: colorList[colorIndex],
          }}
        >
          {value[0].toUpperCase()}
        </Avatar>
      )}
      <div className="feedback-meta-content">
        <h3>{value}</h3>
      </div>
    </div>
  );
}

export default ReviewMetaSection;
