import React from 'react';

import { SortableItem } from 'components/dragAndDrop';
import { Switch } from 'components/switch';
import { markupToText } from 'utils/helpers';

import './_configurationWidget.scss';

function ConfigurationWidget({ data, toggleQuestionSelection }) {
  const handleQuestionStateChange = (questionId) => (e) => {
    toggleQuestionSelection(e.target.checked, questionId);
  };

  return (
    <div className="configWidgetContainer">
      <h5>
        {data.survey_title} -{data.experience}
      </h5>
      <small>
        <b>Locations: </b>
        <span>{data.entities.map(({ name }) => name).join(', ')}</span>
      </small>
      <div className="configWidgetQuestionList">
        {data.questions.map((question) => (
          <SortableItem key={question.id} id={question.id}>
            <div className="configWidgetQuestion">
              <p
                dangerouslySetInnerHTML={{
                  __html: markupToText(question.label),
                }}
              />
              {question.is_from_history ? (
                <small className="discontinued-label">- discontinued</small>
              ) : null}
              <Switch
                checked={question.is_enabled}
                onChange={handleQuestionStateChange(question.id)}
              />
            </div>
          </SortableItem>
        ))}
      </div>
    </div>
  );
}

export default ConfigurationWidget;
