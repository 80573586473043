import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ORGANIZATION_CONFIGURATIONS_URL,
  ORGANIZATION_DETAILS_URL,
} from 'utils/constants/urlConstants';

import OrganisationConfigurations from './OrganizationConfigurations';
import OrganizationDetails from './OrganizationDetails';

import './_regOrganisation.scss';

export default function UpdateOrganisation() {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={ORGANIZATION_DETAILS_URL} replace />}
      />
      <Route
        path={ORGANIZATION_DETAILS_URL}
        element={<OrganizationDetails />}
      />
      <Route
        path={ORGANIZATION_CONFIGURATIONS_URL}
        element={<OrganisationConfigurations />}
      />
      <Route
        path="*"
        element={<Navigate to={ORGANIZATION_DETAILS_URL} replace />}
      />
    </Routes>
  );
}
