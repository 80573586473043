import { createSlice } from '@reduxjs/toolkit';

import {
  getSaasAgreement,
  saveSaasAgreementVersion,
} from './saasAgreementActions';

const INITIAL_STATE = {
  isLoading: false,
  agreement: null,
};

const saasAgreementSlice = createSlice({
  name: 'saasAgreementSlice',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSaasAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSaasAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSaasAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.agreement = action.payload;
    });
    builder.addCase(saveSaasAgreementVersion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveSaasAgreementVersion.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(saveSaasAgreementVersion.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export default saasAgreementSlice;
