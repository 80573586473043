import { createSlice } from '@reduxjs/toolkit';

import { getCities, getCountries, getStates } from './demographicsActions';

const INITIAL_STATE = {
  countries: {
    loading: false,
    data: [],
  },
  states: {
    loading: false,
    data: [],
  },
  cities: {
    loading: false,
    data: [],
  },
};

const demographicsSlice = createSlice({
  name: 'demographicsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    onCountryChangeAction: (state) => {
      state.states.data = [];
      state.cities.data = [];
    },
    onStateChangeAction: (state) => {
      state.cities.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.countries.loading = true;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.countries.loading = false;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries.loading = false;
      state.countries.data = action.payload;
    });

    builder.addCase(getStates.pending, (state) => {
      state.states.loading = true;
    });
    builder.addCase(getStates.rejected, (state) => {
      state.states.loading = false;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.states.loading = false;
      state.states.data = action.payload;
    });

    builder.addCase(getCities.pending, (state) => {
      state.cities.loading = true;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.cities.getCities = false;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities.loading = false;
      state.cities.data = action.payload;
    });
    return builder;
  },
});
export const { onCountryChangeAction, onStateChangeAction } =
  demographicsSlice.actions;
export default demographicsSlice;
