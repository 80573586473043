/* eslint-disable camelcase */

/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { cloneDeep, get, isEmpty, isNil } from 'lodash';

import {
  assignEntitySurveyForm,
  listEntitySurveyAssignment,
  updateEntitySurveyAssignment,
} from 'apis/cxmeter';
import { Loader, SurveyPreview } from 'components';
import SurveyThumbnails from 'components/surveyThumbnail/SurveyThumbnails';
import { Select } from 'components/ui';
import Icon from 'components/ui/icon/Icon';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { postExperience } from 'redux/experience/experienceActions';
import { resetExperienceSlice } from 'redux/experience/experienceSlice';
import { getExperience } from 'redux/filters/filterActions';
import {
  getEntitySurveys,
  getSurveysTemplates,
} from 'redux/surveys/surveysActions';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';
import { branchFormSetupSchema } from 'utils/validations';

import '../regOrgaisation/_regOrganisation.scss';
import './_branchFeedbackFormsSetupScreen.scss';

export default function BranchFeedbackFormsSetupScreen() {
  const [loading, setLoading] = useState(false);
  const { branch, setBranch, setAddedBranch } = useContext(
    OnboardingBranchContext,
  );
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const { branchId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { industry_type: industryType } = useSelector(
    (s) => s.authentication.parentEntity ?? {},
  );
  const { redirectToSurvey, entityData: newExp } = useSelector(
    (s) => s.experience,
  );

  const onSubmit = (values) => {
    setLoading(true);
    (branchId ? updateEntitySurveyAssignment : assignEntitySurveyForm)(
      branch.id,
      values.forms,
    )
      .then(() => {
        setLoading(false);
        setAddedBranch(true);
        navigate(URLS.BRANCH_LIST_URL);
      })
      .catch(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      forms: branch.forms ?? [{}],
    },
    onSubmit,
    validationSchema: branchFormSetupSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const addMore = () => {
    formik.setFieldValue('forms', [...formik.values.forms, {}]);
  };

  const handleOnFormChange = (i, obj, survey) => {
    formik.setFieldValue(`forms[${i}]`, obj);
    if (obj.experience) {
      if (obj.experience.isCustom && !isNil(obj.experience.name)) {
        formik.setFieldError(
          `forms[${i}].experience`,
          isEmpty(obj.experience?.name) ? 'Experience is required.' : false,
        );
      } else {
        formik.setFieldError(
          `forms[${i}].experience`,
          isNil(obj.experience) ? 'Experience is required.' : false,
        );
      }
    }
    setSelectedSurvey(survey);
  };

  const getRedirectUrl = () =>
    branchId
      ? `${URLS.BRANCH_FORM_SETUP_URL}/${branchId}`
      : URLS.BRANCH_FORM_SETUP_URL;

  const handleAddNewForm = async (exp, i) => {
    const errors = await formik.validateForm();
    if (errors.forms?.[i]?.experience) return;
    if (exp.isCustom) {
      dispatch(postExperience({ exp, industryType }));
    } else {
      navigate(URLS.CREATE_SURVEY_URL, {
        state: {
          experience: exp,
          redirect: getRedirectUrl(),
          redirectState: { branch, formik: { ...formik.values } },
        },
      });
    }
  };

  const handleTemplateEdit = (templateId, exp) => {
    navigate(URLS.CREATE_SURVEY_URL, {
      state: {
        template: true,
        id: templateId,
        experience: exp,
        redirect: getRedirectUrl(),
        redirectState: { branch, formik: { ...formik.values } },
      },
    });
  };

  const handleSurveyEdit = (surveyId, exp, isTemplate = false) => {
    navigate(URLS.CREATE_SURVEY_URL, {
      state: {
        template: isTemplate,
        id: surveyId,
        experience: exp,
        redirect: getRedirectUrl(),
        redirectState: { branch, formik: { ...formik.values } },
      },
    });
  };

  const selectFormsFromRes = (assignments) => {
    if (!isEmpty(state?.surveyRes)) {
      const {
        surveyRes: { experience, survey, survey_id },
        formik: { forms },
      } = state || {};
      assignments = forms;
      if (experience && survey_id) {
        const assignment = assignments.find((f) => f.experience === experience);
        if (assignment) {
          assignment.survey_id = survey_id;
        } else {
          assignments = [{ experience, survey_id }];
        }
      }
      setSelectedSurvey(survey);
    }
    return assignments;
  };

  useEffect(() => {
    if (branchId) {
      setLoading(true);
      listEntitySurveyAssignment(branchId)
        .then((data) => {
          setLoading(false);
          const assignments = selectFormsFromRes(data.length ? data : [{}]);
          formik.setValues({ forms: assignments });
        })
        .catch(() => setLoading(false));
    } else {
      const assignments = selectFormsFromRes([{}]);
      formik.setValues({ forms: assignments });
    }
  }, [branchId]);

  useEffect(() => {
    if (!isEmpty(formik.initialTouched))
      toast.error('Please fill in the complete form');
  }, [formik.errors]);

  useEffect(() => {
    if (redirectToSurvey) {
      formik.values.forms = formik.values.forms.filter(
        (form) => !form?.experience?.isCustom,
      );
      if (newExp?.id) {
        formik.values.forms.push({
          experience: newExp.id,
        });
      }

      navigate(URLS.CREATE_SURVEY_URL, {
        state: {
          experience: newExp,
          redirect: getRedirectUrl(),
          redirectState: { branch, formik: { ...formik.values } },
        },
      });
      dispatch(resetExperienceSlice());
    }
  }, [redirectToSurvey, newExp]);

  if (loading) {
    return <Loader />;
  }

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title="Location Forms"
          subtitle="Connect forms with your locations."
        >
          <div className="onboarding-org">
            <Icon type="work" />
            <hgroup>
              <p />
              <h2>{branch?.name}</h2>
            </hgroup>
          </div>
          <div className="expListContainer">
            {formik?.values?.forms.map((f, i) => (
              <div className="mb-10" key={i}>
                <FeedbackFormAssignmentSect
                  handleAddNewForm={(exp) => handleAddNewForm(exp, i)}
                  handleTemplateEdit={handleTemplateEdit}
                  handleSurveyEdit={handleSurveyEdit}
                  showAddNew={i === formik.values.forms.length - 1}
                  selectedExperiences={formik.values.forms.map(
                    (o) => o.experience,
                  )}
                  hideClose={i === 0}
                  onDelete={() =>
                    formik.setFieldValue('forms', [
                      ...formik.values.forms.filter((x, k) => k !== i),
                    ])
                  }
                  onChange={(obj, survey) => {
                    handleOnFormChange(i, obj, survey);
                  }}
                  value={{ ...f }}
                  error={get(formik, `errors.forms[${i}]`, {})}
                  onAddMore={addMore}
                  setSelectedSurvey={(survey) => {
                    setBranch({
                      ...branch,
                      experiences: {
                        ...branch.experiences,
                        [f.experience]: survey._id.$oid,
                      },
                    });
                    setSelectedSurvey(survey);
                  }}
                  selectedExperiencesIndex={i}
                  selectedSurvey={branch.experiences?.[f.experience]}
                  onEdit={(id, exp, isTemplate = false) =>
                    handleSurveyEdit(id, exp, isTemplate)
                  }
                  isOnboarding
                />
              </div>
            ))}
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          disableContinue={formik.values.forms.some(
            ({ survey_id }) => !survey_id,
          )}
          continueText="Next"
          onClickBack={() =>
            navigate(`${URLS.BRANCH_SETUP_URL}/${branchId ?? ''}`)
          }
          onClickContinue={formik.submitForm}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {selectedSurvey ? (
          <SurveyPreview survey={selectedSurvey} isTemplate />
        ) : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}

export function FeedbackFormAssignmentSect({
  onChange,
  value,
  error,
  onDelete,
  hideClose,
  showAddNew,
  selectedExperiences,
  onAddMore,
  handleAddNewForm,
  setSelectedSurvey,
  selectedExperiencesIndex,
  selectedSurvey,
  onEdit = null,
  isOnboarding = false,
  branchAssignmentsLoading = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    entitySurveys: { data: entitySurveys, isEntitySurveysLoading },
    surveyTemplates: { data: surveyTemplates, isSurveysTemplatesLoading },
  } = useSelector((s) => s.surveys);
  const { expList, isLoading } = useSelector((s) => s.filters);
  const [entitySurveysList, setEntitySurveys] = useState(entitySurveys);

  const fetchData = async () => {
    if (selectedExperiencesIndex === 0) {
      dispatch(getEntitySurveys());
      dispatch(getSurveysTemplates());
      dispatch(getExperience());
    }
  };

  const getExperiences = () =>
    expList.filter(
      (o) =>
        !(
          selectedExperiences.includes(`${o.id}`) ||
          selectedExperiences.includes(o.id)
        ) ||
        // eslint-disable-next-line eqeqeq
        o.id == value.experience,
    );

  const getCurrentExperience = () => {
    if (value.experience?.isCustom) return value.experience;
    return getExperiences().find((o) => `${o.id}` === String(value.experience));
  };

  useEffect(() => {
    setEntitySurveys(cloneDeep(entitySurveys));
  }, [entitySurveys]);

  useEffect(() => {
    fetchData();
  }, []);

  if (
    (isLoading ||
      isEntitySurveysLoading ||
      isSurveysTemplatesLoading ||
      branchAssignmentsLoading) &&
    selectedExperiencesIndex === 0
  ) {
    return <Loader />;
  }

  return (
    <>
      <form className="cxmeter-form" onSubmit={(e) => e.preventDefault()}>
        <div>
          <h2 className="onboarding-field-title">
            {t('settings.locations.detail.survey.linkExperienceTitle')}
          </h2>
          <h3 className="onboarding-field-subtitle">
            {t('settings.locations.detail.survey.linkExperienceSubTitle')}
          </h3>
        </div>
        <div className="dropdownRow">
          <div className="experience-selector">
            <Select
              name="entity_experience"
              value={value.experience}
              onChange={(id) => onChange({ experience: id })}
              placeholder="Select Experience Type *"
              options={getExperiences().map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              allowCustomOption
              customOptionPlaceholder="Enter Custom Experience *"
            />
            {!hideClose && (
              <FontAwesomeIcon
                icon={faClose}
                className="closeIcon"
                onClick={() => onDelete(value.id)}
              />
            )}
          </div>
          {error.experience && (
            <p className="control-error">{error.experience}</p>
          )}
        </div>
        {value.experience && (
          <div>
            <SurveyThumbnails
              surveyTemplates={{
                [value.experience]: surveyTemplates[value.experience],
              }}
              entitySurveys={{
                [value.experience]: entitySurveysList[value.experience],
              }}
              exp={getCurrentExperience()}
              value={value}
              setSelectedSurvey={setSelectedSurvey}
              onChange={onChange}
              onEdit={onEdit}
              handleAddNewForm={handleAddNewForm}
              isOnboarding={isOnboarding}
            />
            {selectedSurvey ? null : (
              <p style={{ color: 'red' }}>{error.survey_id}</p>
            )}
          </div>
        )}
      </form>
      {selectedExperiences.length <= expList.length &&
        showAddNew &&
        value.experience && (
          <button
            className="button button-secondary"
            type="button"
            onClick={onAddMore}
          >
            +&nbsp;
            {t('settings.locations.detail.survey.linkAnotherExperience')}
          </button>
        )}
    </>
  );
}
