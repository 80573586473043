import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchMapCoords } from 'apis/maps';
import { Map } from 'components';

export default function BranchMap({ locationData }) {
  const [location, setLocation] = useState();
  const {
    countries: { data: countries },
    states: { data: states },
    cities: { data: cities },
  } = useSelector((state) => state.demographics);

  const getMapValues = ({
    country,
    state,
    city,
    street_address: streetAddress,
    zip_code: zipCode,
  }) => {
    country = countries.find((o) => o.id === country)?.name;
    state = states.find((o) => o.id === state)?.name;
    city = cities.find((o) => o.id === city)?.name;
    return `${streetAddress}, ${city}, ${state}, ${country} ${zipCode}`;
  };

  useEffect(() => {
    if (countries.length && states.length && cities.length) {
      const delayDebounceFn = setTimeout(() => {
        if (locationData.street_address && locationData.city) {
          fetchMapCoords({ text: getMapValues(locationData) }).then(
            setLocation,
          );
        }
      }, 0);

      return () => clearTimeout(delayDebounceFn);
    }
    return () => {};
  }, [locationData, countries, states, cities]);

  return <Map pin={location?.center ?? []} />;
}
