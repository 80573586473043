/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSegmentId } from 'utils/helpers';

function PortalSegmentOption({ segment, setFeedbackStep, setSelectedSegment }) {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState(
    `/assets/icons/icon_${segment}-gray.svg`,
  );

  const submitSegment = () => {
    setSelectedSegment(getSegmentId(segment));
    setFeedbackStep('suggestion');
  };

  return (
    <div className="feedback-segment__option">
      <img
        src={imgSrc}
        alt={segment}
        onMouseOver={() => setImgSrc(`/assets/icons/icon_${segment}.svg`)}
        onFocus={() => setImgSrc(`/assets/icons/icon_${segment}.svg`)}
        onMouseLeave={() => setImgSrc(`/assets/icons/icon_${segment}-gray.svg`)}
        className="feedback-segment__img"
        onClick={() => submitSegment()}
      />
      <p>{t(`portalFeedback.segment.${segment}`)}</p>
    </div>
  );
}

export default PortalSegmentOption;
