import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { PieChart } from 'components/charts';
import { getQuickStatsGraph } from 'redux/dashboard/dashboardActions';
import { QuickStatsColors, URLS } from 'utils/constants';
import { REVIEWS_LISTING_URL } from 'utils/constants/urlConstants';
import { getFilters, renameAndOmitKeys } from 'utils/helpers';

/**
 * @author
 * @function QuickStats
 * */
export function QuickStats({ filters }) {
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.quickStats,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSegmentId = (name) =>
    data?.segments?.find(
      (segment) => segment.name.toLowerCase() === name.toLowerCase(),
    ).id;

  const handleOnChartClick = ({ label }) => {
    const filtersKeyMap = {
      'date_range[0]': 'start_date',
      'date_range[1]': 'end_date',
    };
    const filtersKeysToOmit = ['from_web'];
    navigate({
      pathname: REVIEWS_LISTING_URL,
      search: createSearchParams({
        segment: getSegmentId(label),
        segment_name: _.toLower(label),
        ...renameAndOmitKeys(
          getFilters(filters),
          filtersKeyMap,
          filtersKeysToOmit,
        ),
      }).toString(),
    });
  };

  const parseChartData = () =>
    data?.segments?.reduce((acc, segment) => {
      acc[segment.name] = segment.count;
      return acc;
    }, {});

  const segmentIdMap = {
    Unhappy: 3,
    Neutral: 2,
    Happy: 1,
  };

  const onLegendNavigate = (legend) => {
    if (legend) {
      navigate(
        `${URLS.REVIEWS_LISTING_URL}?segment=${
          segmentIdMap[legend]
        }&segment_name=${legend.toLowerCase()}`,
      );
    } else {
      navigate(URLS.REVIEWS_LISTING_URL);
    }
  };

  useEffect(() => {
    dispatch(getQuickStatsGraph(filters));
  }, [filters]);

  const analytics = parseChartData() || {};
  const quickStatColors = Object.keys(analytics).map(
    (analytic) => QuickStatsColors[analytic],
  );
  return (
    <div className="chartContainer">
      <h3>Happiness Index</h3>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="pieChartHolder">
          <PieChart
            analytics={analytics}
            small
            containerId="legend-container-quickStats"
            onClick={handleOnChartClick}
            additionalLegends={[
              { label: 'Happiness Index', value: data.happiness_index },
            ]}
            statColors={quickStatColors}
            onLegendClick={onLegendNavigate}
          />
          <div id="legend-container-quickStats" className="pieChartLegend" />
        </div>
      )}
    </div>
  );
}

export default QuickStats;
