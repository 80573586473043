import React from 'react';

import { ColorPicker } from 'antd';

import '../_qrSetup.scss';

export default function ColorSelector({ value, onChange, ...props }) {
  return (
    <span className="colorSelectorContainer">
      <ColorPicker
        value={value}
        onChange={(color) => {
          onChange(color.toHexString());
        }}
        {...props}
      />
    </span>
  );
}
