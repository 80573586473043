import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Popover } from 'antd';

import { Icon } from 'components/ui';
import { createHelpItem } from 'redux/dashboard/dashboardActions';

import './_help.scss';

export default function Help() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.dashboard.helpItem);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const afterOpenChange = (isOpen) => {
    if (!isOpen) form.resetFields();
  };

  return (
    <Popover
      afterOpenChange={afterOpenChange}
      content={
        <div className="helpContainer">
          <Form
            form={form}
            onFinish={(values) => {
              dispatch(createHelpItem(values)).then(
                () => setOpen(false),
                form.resetFields(),
              );
            }}
          >
            <p className="title">{t('navigation.helpPopup.title')}</p>
            <Form.Item
              name="subject"
              style={{ marginBottom: '1rem' }}
              rules={[
                { required: true, message: 'This field is required' },
                { max: 256, message: 'Subject cannot exceed 256 characters' },
              ]}
            >
              <Input placeholder="Subject *" />
            </Form.Item>
            <Form.Item
              name="description"
              style={{ marginBottom: '1rem' }}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea placeholder="Please write your message here *" />
            </Form.Item>
            <div className="buttonContainer">
              <button
                type="submit"
                className="button button-primary button-filled"
                aria-busy={isLoading ? true : null}
              >
                {t('navigation.helpPopup.submit')}
              </button>
            </div>
          </Form>
        </div>
      }
      trigger="click"
      onOpenChange={setOpen}
      open={open}
    >
      <button type="button" className="tab-container">
        <span className="cxmeter-nav-icon-label">
          {t('navigation.profilePopup.help')}
        </span>
        <Icon className="cxmeter-nav-icon" type="help" />
      </button>
    </Popover>
  );
}
