import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { SettingsPageContext } from 'contexts';
import { EMAIL_MANAGEMENT_PAGINATION } from 'utils/constants/manageEmails';

function SettingsPageContextProvider({ tabs, children }) {
  const isActiveSubTabRef = useRef();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: '',
  });
  const [params, setParams] = useState({
    ...EMAIL_MANAGEMENT_PAGINATION,
    search: '',
  });
  const [subTabsSearch, setSubTabsSearch] = useState({
    [SEARCH_FILTER]: '',
  });
  const [activeTab, setActiveTab] = useState({});
  const [activeSubTab, setActiveSubTab] = useState(null);

  const subTabHandler = ({
    isActive,
    heading,
    subHeading,
    hideSearchFilter,
  } = {}) => {
    isActiveSubTabRef.current = isActive;
    const subTabs = activeTab.subTabs?.[0] ?? {};
    setActiveSubTab(
      isActive ? { ...subTabs, heading, subHeading, hideSearchFilter } : null,
    );
    setActiveTab((p) => ({ ...p, isActiveSubTab: isActive }));
    if (!isActive) {
      navigate(activeTab.route);
    }
  };

  // To sync the search params key with Backend requirement.
  const onChangeSearch = useCallback(
    (searchValue) => {
      const search = searchValue[SEARCH_FILTER];
      setParams((p) => ({ ...p, page: 1, search }));
      setSearchFilter(searchValue);
    },
    [setSearchFilter],
  );

  const onChangePagination = useCallback(
    (page, pageSize) => {
      setParams((p) => ({ ...p, page, page_size: pageSize }));
    },
    [setParams],
  );

  // If page is rendered for first time or route is changed from anyother page.
  useEffect(() => {
    if (!activeTab.id || state?.updateTab) {
      const isActiveSubTab = state?.updateTab
        ? false
        : isActiveSubTabRef.current;
      const defaultTab =
        tabs.find((tab) => pathname.includes(tab.route)) ?? tabs[0];
      setActiveTab({
        ...defaultTab,
        isActiveSubTab,
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (state?.settingsContextParams) {
      setParams(state.settingsContextParams);
    }
  }, []);

  const contextValues = useMemo(
    () => ({
      searchFilter,
      setSearchFilter: onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      subTabHandler,
    }),
    [
      searchFilter,
      onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      subTabHandler,
    ],
  );

  return (
    <SettingsPageContext.Provider value={contextValues}>
      {children}
    </SettingsPageContext.Provider>
  );
}

export default SettingsPageContextProvider;
