export const RADIO = 'radio';
export const CHECKBOX = 'checkbox';
export const RATING = 'rating';
export const TEXT = 'text';
export const ATTACHMENT = 'attachment';
export const NPS = 'nps';

export const QUESTION_TYPES = {
  [RADIO]: { id: RADIO, label: 'Select a single option only' },
  [CHECKBOX]: { id: CHECKBOX, label: 'Select Multiple Options' },
  [RATING]: { id: RATING, label: 'Ratings' },
  [TEXT]: { id: TEXT, label: 'Provide text based answer' },
  [ATTACHMENT]: { id: 'attachment', label: 'Upload a picture/video/audio' },
  [NPS]: { id: NPS, label: 'NPS' },
};

export const QUESTION_TYPES_DROPDOWN_OPTIONS = Object.values(
  QUESTION_TYPES,
).filter((questionType) => questionType.id !== NPS);

export const ATTACHMENTS = { IMAGE: 'image/video', AUDIO: 'audio' };

export const OPTION_TYPE_MAPPING = {
  [RADIO]: 'radio_option',
  [CHECKBOX]: 'checkbox_option',
  [RATING]: 'rating_option',
};
