import { useState } from 'react';

import { Icon } from 'components/ui';
import { DEVICES } from 'utils/constants';

import SurveyPreviewForm from './SurveyPreviewForm';

import './_surveyPreview.scss';

function PreviewSizeControls({ selectedDevice, onDeviceChange }) {
  return (
    <div className="cxmeter-preview-modes">
      <button
        className={selectedDevice === DEVICES.MOBILE ? 'active' : ''}
        onClick={() => onDeviceChange(DEVICES.MOBILE)}
      >
        <Icon type="smartphone" />
      </button>
      <button
        className={selectedDevice === DEVICES.TABLET ? 'active' : ''}
        onClick={() => onDeviceChange(DEVICES.TABLET)}
      >
        <Icon type="tablet_mac" />
      </button>
      <button
        className={selectedDevice === DEVICES.LAPTOP ? 'active' : ''}
        onClick={() => onDeviceChange(DEVICES.LAPTOP)}
      >
        <Icon type="laptop" />
      </button>
    </div>
  );
}

function SurveyPreview({ survey, innerRef, isTemplate = false }) {
  const [selectedDevice, setSelectedDevice] = useState(DEVICES.MOBILE);

  return (
    <article className="cxmeter-preview">
      <PreviewSizeControls
        selectedDevice={selectedDevice}
        onDeviceChange={setSelectedDevice}
      />
      <section
        className={`cxmeter-preview-device ${selectedDevice.toLowerCase()}`}
      >
        <div className="device-decorations" />
        <div className="device ">
          <SurveyPreviewForm
            survey={survey}
            innerRef={innerRef}
            isTemplate={isTemplate}
          />
        </div>
      </section>
    </article>
  );
}

export default SurveyPreview;
