/* eslint-disable no-return-await */
import { MAPBOX_PLACES_API } from './urls';
import { getAPICall } from './utils';

export async function fetchMapCoords(data) {
  return await getAPICall(
    `${MAPBOX_PLACES_API}${encodeURIComponent(data.text)}`,
  );
}

export default fetchMapCoords;
