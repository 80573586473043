import { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Modal } from 'components/ui';
import { changeBranchStatus } from 'redux/settings/settingsActions';

const useUpdateLocationQuery = (id, status) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onStatusChange = async () => {
    setIsLoading(true);
    const { error: { message } = {} } = await dispatch(
      changeBranchStatus({ entities: [id], activate: status }),
    );
    setIsLoading(false);
    return message;
  };

  return { isLoading, onUpdate: onStatusChange };
};

const LocationStatusToggleModal = forwardRef((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState({});
  const [onSuccess, setOnSuccess] = useState();
  const { isLoading, onUpdate } = useUpdateLocationQuery(
    location.id,
    !location.is_active,
  );
  const plan = useSelector((state) => {
    const {
      payments: {
        subscribedPlan: { data },
      },
    } = state;
    return state.payments.paymentPlans.plans?.find(
      (p) => p.title === data?.name,
    );
  });

  const cost = (plan?.cost_per_branch ?? 0) * (location.is_active ? -1 : 1);

  const onClose = () => {
    if (!isLoading) setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open(loc, onChange) {
      setIsOpen(!isOpen);
      setLocation(loc);
      setOnSuccess(() => onChange);
    },
  }));

  const toggleStatus = async () => {
    const errorMessage = await onUpdate();
    setIsOpen(false);
    if (errorMessage) toast.error(errorMessage);
    else onSuccess(location.id);
  };

  return (
    <Modal backDropClose={!isLoading} open={isOpen} onClose={onClose}>
      <Modal.Header title="Confirmation" onClose={onClose} />
      <Modal.Body>
        <div>
          <p>
            {`Are you sure you want to
              ${location.is_active ? 'Deactivate' : 'Activate'}
              this
              Location?`}
          </p>
          <p>
            {cost === 0
              ? 'No extra charges apply.'
              : cost < 0
              ? `You will be saving $${Math.abs(cost)}`
              : `You will have to pay $${cost} more.`}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        isLoading={isLoading}
        disableSecondaryAction={isLoading}
        disablePrimaryAction={isLoading}
        primaryAction={toggleStatus}
        secondaryAction={onClose}
        primaryLabel={location.is_active ? 'Deactivate' : 'Activate'}
      />
    </Modal>
  );
});

export default LocationStatusToggleModal;
