import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { URLS } from 'utils/constants';

import './_StatBlurWrapper.scss';

export default function StatBlurWrapper({
  children,
  showStat,
  previewUrl,
  alt = 'Preview',
}) {
  const navigate = useNavigate();
  const isSuperuser = useSelector((s) => s.authentication.user?.is_superuser);

  const upgradePlanHandler = () => {
    navigate(URLS.PAYMENT_DASHBOARD_SETTINGS_URL, {
      state: { openChangePaymentPlanModal: true },
    });
  };

  return isSuperuser || showStat ? (
    <Card className="chartCard">{children}</Card>
  ) : (
    <Card className="chartCard">
      <div className="blur-statistics-container">
        <button className="btn" type="button" onClick={upgradePlanHandler}>
          Upgrade Your Plan
        </button>
        <img src={previewUrl} alt={alt} />
      </div>
    </Card>
  );
}
