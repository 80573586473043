import React from 'react';

import dayjs from 'dayjs';

import { ProfileAvatar } from 'components';

export default function ReviewComment({ comment }) {
  return (
    <div className="comment">
      <ProfileAvatar
        photoUrl={comment.user.profile_photo}
        firstName={comment.user.first_name}
        lastName={comment.user.last_name}
        userId={comment.user.id}
      />
      <div className="comment-content">
        <div className="comment-meta">
          <p className="comment-meta-user">
            {comment.user?.first_name}
            &nbsp;
            {comment.user?.last_name}
          </p>
          <p>
            {dayjs.utc(comment.updated_at).local().startOf('second').fromNow()}
          </p>
        </div>
        <div className="comment-text">
          <p>{comment.text}</p>
        </div>
      </div>
    </div>
  );
}
