/* eslint-disable react/no-danger */
import React from 'react';
import ReactWordcloud from 'react-wordcloud';

import { Tooltip } from 'antd';

import { WordCloudColors, chartColors } from 'utils/constants';

import './_wordcloud.scss';

export default function WordCloud({ data, label }) {
  const words = data.map((word) => {
    const { sentiment, ...rest } = word ?? {};
    return {
      sentiment,
      text: Object.keys(rest)[0],
      value: Object.values(rest)[0],
    };
  });

  const options = {
    enableTooltip: true,
    deterministic: true,
    fontFamily: 'times new roman',
    fontSizes: [14, 80],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 2,
    rotations: 1,
    rotationAngles: [0, 0],
    scale: 'linear',
    spiral: 'rectangular',
    transitionDuration: 1000,
    tooltipOptions: {
      theme: 'default',
    },
  };

  const callbacks = {
    getWordColor: (word) => {
      return (
        WordCloudColors[word.sentiment] ??
        chartColors[word.value % chartColors.length]
      );
    },
  };

  return (
    <div className="wordCloudContainer">
      <Tooltip title={<p dangerouslySetInnerHTML={{ __html: `${label}` }} />}>
        <h3
          dangerouslySetInnerHTML={{ __html: `${label}` }}
          className="title"
        />
      </Tooltip>
      {data?.length ? (
        <div className="pieChartHolder">
          <ReactWordcloud
            options={options}
            words={words}
            callbacks={callbacks}
          />
        </div>
      ) : (
        <div className="emptyWordCloud">No data available</div>
      )}
    </div>
  );
}
