// eslint-disable-next-line
export const colorList = [
  'rgba(121, 99, 205, 1)',
  'rgba(159, 0, 173, 1)',
  'rgba(255, 130, 122, 1)',
  'rgba(99, 205, 129, 1)',
  'rgba(99, 205, 129, 1)',
  'rgba(99, 205, 129, 1)',
  'rgba(81, 84, 111, 1)',
];
