import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/ui';
import { getCities } from 'redux/demographics/demographicsActions';

export default function CityDropDown({
  onChange,
  value,
  stateId,
  error,
  placeholder,
}) {
  const dispatch = useDispatch();
  const { data: cities, loading } = useSelector(
    (state) => state.demographics.cities,
  );

  useEffect(() => {
    if (stateId) {
      dispatch(getCities(stateId));
    }
  }, [stateId]);

  return (
    <Select
      name="city"
      isSearchable
      placeholder={placeholder ?? 'Select City'}
      options={cities}
      labelKey="name"
      valueKey="id"
      onChange={onChange}
      value={value}
      isLoading={loading}
      error={error}
      disabled={cities.length === 0}
    />
  );
}
