import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEntityAPICallV2 } from 'apis/cxmeter';
import {
  createRegionAPICall,
  deleteRegionByIdAPICall,
  getRegionByIdAPICall,
  locationsWithoutRegionsAPICall,
  paginatedLocationsAPICall,
  regionsAPICall,
  updateRegionByIdAPICall,
} from 'apis/locationsAndRegions';
import { showErrorToast } from 'utils/helpers';

export const getRegions = createAsyncThunk(
  'locationsAndRegions/getRegions',
  async (params, { rejectWithValue }) =>
    regionsAPICall(params).catch((e) => showErrorToast(e, rejectWithValue)),
);

export const getRegionById = createAsyncThunk(
  'locationsAndRegions/getRegionById',
  async (id, { rejectWithValue }) =>
    getRegionByIdAPICall(id).catch((e) => showErrorToast(e, rejectWithValue)),
);

export const createRegion = createAsyncThunk(
  'locationsAndRegions/createRegion',
  async (data, { rejectWithValue }) =>
    createRegionAPICall(data).catch((e) => showErrorToast(e, rejectWithValue)),
);

export const updateRegionById = createAsyncThunk(
  'locationsAndRegions/updateRegionById',
  async ({ id, data }, { rejectWithValue }) =>
    updateRegionByIdAPICall(id, data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deleteRegionById = createAsyncThunk(
  'locationsAndRegions/deleteRegionById',
  async (id, { rejectWithValue }) =>
    deleteRegionByIdAPICall(id).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getLocationWithoutRegions = createAsyncThunk(
  'locationsAndRegions/getLocationWithoutRegions',
  async (params, { rejectWithValue }) =>
    locationsWithoutRegionsAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getPaginatedLocations = createAsyncThunk(
  'locationsAndRegions/getPaginatedLocations',
  async (params, { rejectWithValue }) =>
    paginatedLocationsAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getLocationById = createAsyncThunk(
  'locationsAndRegions/getLocationById',
  async (params) => getEntityAPICallV2(params).catch(showErrorToast),
);
