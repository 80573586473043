import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LocationInfoForm as LocationForm } from 'components/forms';
import { useLocationSetup } from 'components/hooks';
import { SettingsPageContext } from 'contexts';
import { getPaginatedLocations } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';
import { MANAGE_LOCATIONS_SETTINGS_URL } from 'utils/constants/urlConstants';

export default function LocationInfoForm() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { params } = useContext(SettingsPageContext);

  const { isLoading, isSubmitting, formik } = useLocationSetup({
    locationId: locationId === 'new' ? undefined : locationId,
    location: null,
    onUpdate: (data) => {
      dispatch(resetLocationDetail(data));
      dispatch(getPaginatedLocations(params));
      navigate(`${MANAGE_LOCATIONS_SETTINGS_URL}/${data.id}`);
    },
  });

  return (
    <div className="onboarding-form">
      <LocationForm formik={formik} />
      <button
        aria-busy={isSubmitting || isLoading ? true : null}
        type="button"
        className="button button-primary"
        onClick={formik.handleSubmit}
        style={{ marginTop: '1rem' }}
      >
        Save
      </button>
    </div>
  );
}
