import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Notification from '../../../../assets/icons/icon_notification.svg';
import NotificationCenter from './NotificationCenter';

export default function NotificationBell() {
  const { isActiveOrg } = useSelector((state) => state.authentication);
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const notifications = useSelector(
    (state) => state.notifications.notifications.results,
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(
        () =>
          document.addEventListener('click', () => setIsOpen(false), {
            once: true,
          }),
        10,
      );
    }
  }, [isOpen]);

  useEffect(() => {
    const unReadNotifications = notifications.filter(
      (notification) => !notification.is_read,
    );
    setCount(unReadNotifications.length);
  }, [notifications]);

  return (
    <>
      <button
        type="button"
        className="notification-bell"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          className="cxmeter-nav-icon"
          src={Notification}
          alt="notification"
        />
        <span className="cxmeter-nav-icon-label">Notifications</span>
        {count ? <span className="notification-counter">{count}</span> : null}
      </button>
      {isActiveOrg ? (
        <NotificationCenter open={isOpen} setOpen={setIsOpen} />
      ) : null}
    </>
  );
}
