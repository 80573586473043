import { createContext } from 'react';

const SettingsPageContext = createContext({
  searchFilter: {},
  setSearchFilter() {},
  params: {},
  setParams() {},
});

export default SettingsPageContext;
