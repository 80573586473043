import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

import { HorizontalBarChartGradientColors, chartColors } from 'utils/constants';
import { chartHelper } from 'utils/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const { setHorizontalBarChartXScales, getRatingGradient } = chartHelper;
export const options = (
  onClick,
  dark = false,
  autoScale = true,
  xScales = null,
  xLabel = '',
  yLabel = '',
  tooltipFooter = undefined,
) => ({
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  scales: {
    x: {
      ...setHorizontalBarChartXScales(dark, autoScale, xScales),
      title: {
        text: xLabel,
        display: true,
      },
      ticks: {
        precision: 0,
      },
    },
    y: {
      ticks: {
        ...(dark
          ? {
              color: 'white',
              font: {
                size: 18,
                weight: 500,
              },
            }
          : {
              color: 'black',
              font: {
                size: 10,
              },
            }),
        callback(value) {
          const label = this.getLabelForValue(value);
          return label.length > 23 ? `${label.substring(0, 23)}...` : label;
        },
        crossAlign: 'far',
      },
      grid: {
        display: false,
      },
      title: {
        text: yLabel,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: '',
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      backgroundColor: 'white',
      titleColor: 'black',
      titleFont: {
        size: 18,
      },
      titleFontSize: 28,
      caretSize: 10,
      borderColor: '#868584',
      bodyColor: '#868584',
      borderWidth: 0.5,
      boxHeight: 7,
      boxPadding: 2,
      padding: 20,
      usePointStyle: true,
      callbacks: {
        title(data) {
          return data[0].formattedValue;
        },
        label: () => '',
        afterBody: tooltipFooter,
        labelTextColor: () => 'black',
        beforeBody(data) {
          return `${data[0].label}`;
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  onClick(e, item) {
    if (item.length < 1) return;
    const currentElement = {
      label: e.chart.data.labels[item[0]?.index],
      value: e.chart.data.datasets[0].data[item[0]?.index],
    };
    onClick(currentElement);
  },
});

/**
 *
 * @param {object} data {label: value, label: value}
 * @param {string} label
 * @param {boolean} gradient
 * @param {boolean} dark
 * @returns {React.JSX} A Horizontal Bar Graph of given Data
 */
export function HorizontalBarChart({
  data,
  label,
  onClick,
  dark = false,
  showGradient,
  showRatingGradient,
  autoScale = true,
  xScales,
  xLabel,
  yLabel,
  tooltipFooter,
}) {
  const { createGradient } = chartHelper;
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });

  const getRatingColors = (dataValues) =>
    dataValues?.map((value) =>
      getRatingGradient(value, HorizontalBarChartGradientColors),
    );
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) return;

    const chData = {
      labels: Object.keys(data),
      datasets: [
        {
          label,
          maxBarThickness: 30,
          data: Object.values(data),
          backgroundColor: showGradient
            ? createGradient(chart.ctx, chart.chartArea)
            : showRatingGradient
            ? getRatingColors(Object.values(data))
            : chartColors,
        },
      ],
    };
    setChartData(chData);
  }, [data, label]);

  return (
    <div className="pieChartWrapper">
      {_.isEmpty(_.pickBy(data, (v) => v > 0)) ? (
        <span>No Data Found</span>
      ) : (
        <Bar
          ref={chartRef}
          options={options(
            onClick,
            dark,
            autoScale,
            xScales,
            xLabel,
            yLabel,
            tooltipFooter,
          )}
          data={chartData}
          plugins={[ChartDataLabels]}
        />
      )}
    </div>
  );
}

export default HorizontalBarChart;
