import {
  emailRegex,
  onlyLettersAndNumbersRegex,
  onlyLettersAndSpacesRegex,
  onlyNumbersRegex,
} from 'utils/constants/regex';
import { isNumberOrParsableString } from 'utils/helpers';

import Yup from './Yup';

export const getEmailError = (email) => {
  let error = '';
  if (!emailRegex.test(email)) error = 'Please enter a valid email';
  else if ((email ?? '').length > 128)
    error = "Email can't exceed 128 characters";
  return error;
};

export const addressInfoSchema = {
  country: Yup.string()
    .requiredTrimmed(
      'Country cannot be only blank spaces.',
      'Country is required!',
    )
    .matches(
      onlyLettersAndNumbersRegex,
      'Country name can only contain letters',
    ),
  street_address: Yup.string().requiredTrimmed(
    'Address cannot be only blank spaces.',
    'Address is required!',
  ),
  city: Yup.string()
    .requiredTrimmed('City cannot be only blank spaces.', 'City is required!')
    .matches(onlyLettersAndNumbersRegex, 'City name can only contain letters'),
  state: Yup.string()
    .requiredTrimmed('State cannot be only blank spaces.', 'State is required!')
    .matches(onlyLettersAndNumbersRegex, 'State name can only contain letters'),
  zip_code: Yup.string()
    .required('Zip code is required!')
    .matches(onlyNumbersRegex, 'Zip code must be only digits')
    .min(5, 'Zip code must be at least 5 digits')
    .max(9, 'Zip code cannot exceed 9 digits'),
};

export const customExperienceSchema = (fieldName = 'Type') =>
  Yup.mixed().test(
    'custom-validation',
    `${fieldName} is required!`,
    (value, { createError, path }) => {
      if (isNumberOrParsableString(value)) return true;
      const { isCustom, name, id } = value ?? {};
      if (isCustom) {
        if (!name || name === '') return false;
        let error = '';
        if (name.trim() === '')
          error = `${fieldName} cannot be only blank spaces`;
        else if (!onlyLettersAndSpacesRegex.test(name))
          error = `${fieldName} can only contain letters`;
        else if (name.trim().length > 128)
          error = `${fieldName} must not exceed 128 characters`;
        if (error) {
          return createError({ message: error, path });
        }
        return true;
      }
      return isNumberOrParsableString(id);
    },
  );
