import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Table } from 'antd';
import dayjs from 'dayjs';

import SettingsPageContext from 'contexts/SettingsPageContext';
import { getInvites } from 'redux/emailManagement/emailManagementActions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { MANAGE_EMAILS_SETTINGS_INBOX_URL } from 'utils/constants/urlConstants';
import { getInboxColumns } from 'utils/helpers/emailsManagementHelper';

function Inbox() {
  const navigate = useNavigate();
  const { subTabRecordId } = useParams();
  const dispatch = useDispatch();
  const {
    invites: { data: invites, loading },
  } = useSelector((s) => s.emailManagement);

  const { params, onChangePagination, subTabHandler, activeSubTab } =
    useContext(SettingsPageContext);

  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    if (!activeSubTab) dispatch(getInvites({ ...params }));
  }, [params]);

  const activateSubTab = (record) => {
    setSelectedRecord(record);
    const { sent_datetime: sentDate, subject } = record;
    const date = sentDate ? dayjs(sentDate).format('MMMM DD • h:mm A') : null;
    subTabHandler({
      isActive: true,
      heading: subject,
      subHeading: date,
    });
    navigate(`${MANAGE_EMAILS_SETTINGS_INBOX_URL}/${record.id}`);
  };

  return (
    <div className="inbox-table">
      {!subTabRecordId ? (
        <Table
          className="left-align"
          columns={getInboxColumns(activateSubTab)}
          scroll={{ x: 'max-content' }}
          dataSource={invites.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: invites.count,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            onChange: onChangePagination,
          }}
        />
      ) : (
        <Outlet
          context={{ selectedRecord: selectedRecord ?? { id: subTabRecordId } }}
        />
      )}
    </div>
  );
}

export default Inbox;
