import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CityDropDown from 'components/demographics/cityDropDown/CityDropDown';
import CountryDropDown from 'components/demographics/countryDropDown/CountryDropDown';
import StateDropDown from 'components/demographics/stateDropDown/StateDropDown';
import { Select } from 'components/ui';
import { getLocationsV2 } from 'redux/branchComparison/branchComparisonActions';
import {
  onCountryChangeAction,
  onStateChangeAction,
} from 'redux/demographics/demographicsSlice';

export default function PaymentBillingDetails({
  formik: { values, setValues, setFieldValue, errors, touched, handleChange },
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: locations, isLoading } = useSelector(
    (state) => state.branchComparison.locations,
  );

  const onLocationChange = (streetAddr) => {
    const location = locations.find((loc) => loc.street_address === streetAddr);
    if (!location) {
      setFieldValue('street_address', streetAddr);
    } else {
      setValues(
        {
          ...values,
          street_address: location?.street_address,
          country: location?.country?.id ?? '',
          state: location?.state?.id ?? '',
          city: location?.city?.id ?? '',
          zip_code: location?.zip_code ?? '',
        },
        true,
      );
    }
  };

  const onStateChange = (id) => {
    dispatch(onStateChangeAction());
    setFieldValue('city', '');
    setFieldValue('state', id);
  };

  const onCountryChange = (id) => {
    dispatch(onCountryChangeAction());
    dispatch(onStateChangeAction());
    setFieldValue('state', '');
    setFieldValue('country', id);
  };

  useEffect(() => {
    dispatch(getLocationsV2());
  }, []);

  return (
    <div className="cxmeter-form">
      <label
        className={`control ${
          touched.street_address && errors.street_address
            ? 'control-invalid'
            : ''
        }`}
      >
        <span className="required-field">
          {t('settings.payments.paymentMethod.location')}
          <span>*</span>
        </span>
        <Select
          value={values.street_address}
          options={locations.map((loc) => ({
            label: loc.name,
            value: loc.street_address,
          }))}
          onChange={onLocationChange}
          placeholder="Street Address"
          isLoading={isLoading}
          error={
            touched.street_address && errors.street_address ? (
              <span className="txt-error">{errors.street_address}</span>
            ) : null
          }
          isEditable
        />
      </label>
      <label
        className={`control ${
          touched.country && errors.country ? 'control-invalid' : ''
        }`}
      >
        <span className="required-field">
          Country<span>*</span>
        </span>
        <CountryDropDown
          value={values.country}
          onChange={onCountryChange}
          error={
            touched.country && errors.country ? (
              <span className="txt-error">{errors.country}</span>
            ) : null
          }
        />
      </label>
      <div className="onboarding-location-dropdowns">
        <label
          className={`control ${
            touched.state && errors.state ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field">
            State<span>*</span>
          </span>
          <StateDropDown
            onChange={onStateChange}
            countryId={values.country}
            value={values.state}
            error={
              touched.state && errors.state ? (
                <span className="txt-error">{errors.state}</span>
              ) : null
            }
          />
        </label>
        <label
          className={`control ${
            touched.city && errors.city ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field">
            City<span>*</span>
          </span>
          <CityDropDown
            onChange={(id) => setFieldValue('city', id)}
            value={values.city}
            stateId={values.state}
            error={
              touched.city && errors.city ? (
                <span className="txt-error">{errors.city}</span>
              ) : null
            }
          />
        </label>
        <label
          className={`control ${
            touched.zip_code && errors.zip_code ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field">
            Zip<span>*</span>
          </span>
          <input
            name="zip_code"
            type="text"
            placeholder="Zip Code"
            value={values.zip_code}
            onChange={handleChange}
          />
          {touched.zip_code && errors.zip_code ? (
            <span className="txt-error">{errors.zip_code}</span>
          ) : null}
        </label>
      </div>
    </div>
  );
}
