import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPortalFeedbackFormAPICall,
  submitPortalFeedbackAPICall,
  updatePortalDeedbackAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getPortalFeedbackForms = createAsyncThunk(
  'portalFeedback/getForm',
  async () => getPortalFeedbackFormAPICall().catch(showErrorToast),
);

export const postPortalFeedbackForm = createAsyncThunk(
  'portalFeedback/submitForm',
  async (data) =>
    submitPortalFeedbackAPICall(data.expId, data.formData).catch(
      showErrorToast,
    ),
);

export const updatePortalFeedbackForm = createAsyncThunk(
  'portalFeedback/updateFeedback',
  async ({ expId, feedbackId, data }) =>
    updatePortalDeedbackAPICall(expId, feedbackId, data).catch(showErrorToast),
);
