import dayjs from 'dayjs';
import _ from 'lodash';

import {
  DAILY,
  DESCENDING,
  MONTHLY,
  QUESTION_CONSTANTS,
  WEEKLY,
} from '../constants';
import { formatDate } from './global';
import { getSegmentIconSrc } from './iconHelper';

export const formatReviewsTableData = (
  reviews,
  getStatusValue,
  getSegmentIcon,
) =>
  reviews?.map((review) => ({
    id: review.id,
    entity: review.entity.name,
    experience: review.experience.name,
    status: getStatusValue(review.status),
    segment: getSegmentIcon(getSegmentIconSrc(review.segment?.name)),
    created_at: new Date(review.created_at).toLocaleString('en-Us', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }),
  }));

export const formatSortFields = (sortFields) =>
  Object.entries(sortFields).reduce((result, [key, value], index) => {
    const field = `${value === DESCENDING ? '-' : ''}${key}`;
    result[`sort_by[${index}]`] = field;
    return result;
  }, {});

export const numberOfMillisecondsInDays = (days) => days * 24 * 60 * 60 * 1000;

export const getEndDate = (startDate, timeline) => {
  if (timeline === DAILY) {
    return startDate;
  }
  const givenDate = dayjs(startDate);
  if (timeline === WEEKLY) {
    return givenDate.add(7, 'day').format('YYYY-MM-DD');
  }
  if (timeline === MONTHLY) {
    return givenDate.endOf('month').format('YYYY-MM-DD');
  }
  return null;
};

export const sortDates = (dates) =>
  dates.sort((a, b) => new Date(b.date) - new Date(a.date));

export const getDateRangeBetweenLabels = (dates) => {
  const [startDate, endDate] = sortDates(dates);
  const oneDay = numberOfMillisecondsInDays(1);

  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffInDays = Math.round(Math.abs((start - end) / oneDay));
  if (diffInDays <= 1) return DAILY;
  if (diffInDays <= 8) return WEEKLY;
  return MONTHLY;
};

export const formatDateForChip = (startDate, endDate) =>
  `${startDate?.replaceAll('-', '/')} - ${endDate?.replaceAll('-', '/')}`;

export const updateObjectWithNewValue = (existingArray, newValue) => {
  const index = existingArray.findIndex((val) => val.id === newValue.id);
  if (index === -1) {
    return [...existingArray, newValue];
  }
  existingArray[index].name = newValue.name;
  return existingArray;
};

export const getReviewListingInitialFilters = (params) => {
  if (params?.option_id && params?.type) {
    params[QUESTION_CONSTANTS.OPTION_TYPE_MAPPING[params?.type]] =
      params?.option_id;
  }
  if (params?.start_date)
    params['date_range[0]'] = formatDate(params.start_date);
  if (params?.end_date) params['date_range[1]'] = formatDate(params.end_date);
  const keysToOmit = ['option_id', 'type', 'start_date', 'end_date'];

  return _.omit(params, keysToOmit);
};

export const deleteAndUpdateObjectsFromArray = (
  objArray,
  idsToDelete,
  newDataArray,
) => {
  const updatedArray = objArray.filter(
    (item) => !idsToDelete.includes(item.id),
  );
  return newDataArray.reduce(
    (acc, newObj) => {
      const existingIndex = acc.findIndex((item) => item.id === newObj.id);
      if (existingIndex !== -1) {
        acc[existingIndex].name = newObj.name;
      } else {
        acc.push(newObj);
      }
      return acc;
    },
    [...updatedArray],
  );
};

export const updateAndOmitKeys = (obj, keysToOmit, newObj) => {
  const updateObj = _.omit(obj, keysToOmit);
  return { ...updateObj, ...newObj };
};
