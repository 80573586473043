import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { STATUS } from 'utils/constants';

import './_reviewsFilters.scss';

/**
 * @author
 * @function StatusFilter
 * */

function StatusFilter({ placeholder, ...rest }) {
  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="status-list-filter"
      id="status-list"
      name="status"
      options={STATUS}
      placeholder={placeholder ?? 'Status'}
      {...rest}
    />
  );
}

export default StatusFilter;
