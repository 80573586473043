import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  cancelPlanApiCall,
  createPaymentMethodAPICall,
  deletePaymentMethodAPICall,
  fetchInvoicesAPICall,
  fetchPaymentMethodsAPICall,
  fetchPaymentPlansAPICall,
  fetchSubscribedPlanAPICall,
  resubscriberApiCall,
  setDefaultPaymentMethodAPICall,
  updatePaymentMethodAPICall,
  upgradePlanApiCall,
} from 'apis/payments';
import { getParentEntityAction } from 'redux/authentication/authenticationActions';
import { showErrorToast } from 'utils/helpers';

export const listInvoices = createAsyncThunk(
  'payment/listInvoices',
  async (params) => {
    try {
      const invoices = await fetchInvoicesAPICall(params);
      return invoices;
    } catch (e) {
      showErrorToast(e);
      return Promise.reject();
    }
  },
);

export const getPaymentPlans = createAsyncThunk(
  'payment/getPaymentPlans',
  async (queryParams) =>
    fetchPaymentPlansAPICall(queryParams).catch(showErrorToast),
);

export const getSubscribedPlan = createAsyncThunk(
  'payment/getSubscribedPlan',
  async () => fetchSubscribedPlanAPICall().catch(showErrorToast),
);

export const getPaymentMethods = createAsyncThunk(
  'payment/getPaymentMethods',
  async () => fetchPaymentMethodsAPICall().catch(showErrorToast),
);

export const upgradePlan = createAsyncThunk(
  'payment/upgradePlan',
  async (id, { dispatch }) =>
    upgradePlanApiCall(id)
      .then(() => {
        toast.success('Plan Upgraded.');
        dispatch(getSubscribedPlan());
        dispatch(getParentEntityAction());
      })
      .catch((error) => error.response.data.detail),
);

export const cancelPlan = createAsyncThunk(
  'payment/cancelPlan',
  async (params, { dispatch }) =>
    cancelPlanApiCall()
      .then(async () => {
        await dispatch(getSubscribedPlan({ showLoading: false }));
        toast.success('Plan Canceled.');
      })
      .catch(showErrorToast),
);

export const createPaymentMehtod = createAsyncThunk(
  'payment/createPaymentMehtod',
  async ({ data, replacingCard }) =>
    createPaymentMethodAPICall(data)
      .then((res) => {
        if (!replacingCard) toast.success('Payment Method Created.');
        return replacingCard ? { replacedCard: res } : res;
      })
      .catch(showErrorToast),
);

export const updatePaymentMethod = createAsyncThunk(
  'payment/updatePaymentMethod',
  async ({ id, data }) =>
    updatePaymentMethodAPICall(id, data)
      .then(() => {
        toast.success('Payment Method Updated.');
      })
      .catch(showErrorToast),
);

export const deletePaymentMethod = createAsyncThunk(
  'payment/deletePaymentMethod',
  async (id, { dispatch }) =>
    deletePaymentMethodAPICall(id)
      .then(() => {
        toast.success('Payment Method Deleted.');
        dispatch(getPaymentMethods());
      })
      .catch(showErrorToast),
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'payment/setDefaultPaymentMethod',
  async (id, { dispatch }) =>
    setDefaultPaymentMethodAPICall(id)
      .then(() => {
        toast.success('Payment Method Marked as Primary.');
        dispatch(getPaymentMethods());
      })
      .catch(showErrorToast),
);

export const resubscribePlan = createAsyncThunk(
  'payment/resubscribePlan',
  async (id, { dispatch }) =>
    resubscriberApiCall()
      .then(() => {
        toast.success('You have succesfully resubscribed to the plan');
        return dispatch(getSubscribedPlan());
      })
      .catch(showErrorToast),
);
