import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  locationListAPICall,
  parentEntityListAPICall,
  parentEntitySelectAPICall,
} from 'apis/cxmeter';
import { Loader } from 'components';
import { setParentEntity } from 'redux/authentication/authenticationSlice';
import { setLocations } from 'redux/reducers/feedbackSlice';
import { resetFilters } from 'redux/reducers/filterSlice';
import { ORGANIZATION, URLS } from 'utils/constants';
import { storeParentEntity } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';

import './_organization.scss';

function Organization() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authentication);
  const dataFetched = useRef(false);
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);

  async function fetchDataAndUpdateState() {
    try {
      setOrgs(await parentEntityListAPICall());
    } catch (error) {
      toast.error(error.message ? error.message : error);
    }
    setLoading(false);
  }

  async function handleOrgClick(orgId) {
    setLoading(true);
    try {
      const parentEntity = await parentEntitySelectAPICall(orgId);
      storeParentEntity(parentEntity);
      dispatch(setParentEntity(parentEntity));
      ReactGA.set({ o_id: parentEntity.id });
      dispatch(setLocations(await locationListAPICall()));
      navigate(URLS.DASHBOARD_URL);
    } catch (error) {
      toast.error(error.message ? error.message : error);
    }
    setLoading(false);
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }

  usePageTitle(ORGANIZATION);

  useEffect(() => {
    if (!dataFetched.current) {
      fetchDataAndUpdateState().catch(toast.error);
      dataFetched.current = true;
    }
    dispatch(resetFilters());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="preAppOrgWrap">
      <div className="holder">
        {user.is_superuser && (
          <div className="qrLink">
            <Link to={URLS.GENERATE_QRS_URL}>
              <img alt="QR Button" src="./assets/icons/icon_qr-code.png" />
              <span>Generate QRs</span>{' '}
            </Link>
          </div>
        )}
        <div className="contentMainWrap">
          <div className="logo">
            <img src="/assets/images/appLogo.svg" alt="logo" width={120} />
          </div>
          <h1>Select the Organization</h1>
          <div className="contentWrap">
            <div className="org">
              {orgs.map((org, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleOrgClick(org.id)}
                >
                  <span>
                    <img
                      src={org.logo ? org.logo : '/assets/images/appLogo.svg'}
                      alt="logo"
                      width={120}
                    />
                    {org.name}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
