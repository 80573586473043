import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COUNTRY_LOOKUP_API } from 'apis/urls';
import { Select } from 'components/ui';
import { getCountries } from 'redux/demographics/demographicsActions';

import { EXTREME_IP_LOOKUP_API_KEY } from '../../../config';

export default function CountryDropDown({
  onChange,
  value,
  error,
  placeholder,
}) {
  const dispatch = useDispatch();
  const { data: countries, loading } = useSelector(
    (state) => state.demographics.countries,
  );
  const [countryLookUp, setCountryLookUp] = useState(null);

  useEffect(() => {
    if (!countries || countries.length === 0) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (countries?.length && !value) {
      fetch(`${COUNTRY_LOOKUP_API}?key=${EXTREME_IP_LOOKUP_API_KEY}`).then(
        (res) =>
          res.json().then((location) => {
            if (location?.country) {
              const foundCountry = countries.filter(
                (country) => country.name === location.country,
              )[0];
              setCountryLookUp(foundCountry?.id);
              onChange(foundCountry?.id);
            }
          }),
      );
    }
  }, [countries]);

  return (
    <Select
      name="country"
      placeholder={placeholder ?? 'Select Country'}
      options={countries}
      labelKey="name"
      valueKey="id"
      onChange={onChange}
      value={value || countryLookUp}
      isLoading={loading}
      error={error}
    />
  );
}
