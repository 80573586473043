import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState } from 'draft-js';

import { getFormattedState } from 'utils/helpers/richTextEditorHelpers';

import './_richTextEditor.scss';

function RichTextEditor(
  {
    showCustomLink,
    placeholder,
    setState,
    content,
    readOnly = false,
    error = '',
    toolbarOnFocus = true,
    toolbarConfig,
    warningText,
    customDecorators = [],
    blockRendererFn,
    focusEditor = false,
    errorStyles = { borderBottom: '1px solid red' },
    ...props
  },
  ref,
) {
  const [editorState, setEditorState] = useState(() =>
    getFormattedState(content),
  );

  const textEditorRef = useRef();

  const toolbarOptions = useMemo(() => {
    if (toolbarConfig) {
      return toolbarConfig;
    }
    return {
      options: ['inline'],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
      },
    };
  }, [toolbarConfig]);

  useImperativeHandle(
    ref,
    () => ({
      updateState: (newContent) => {
        const formattedState = getFormattedState(newContent);
        setEditorState(formattedState);
        setState(formattedState.getCurrentContent());
      },
      getEditorState: () => editorState,
    }),
    [editorState],
  );

  useEffect(() => {
    if (!content) setEditorState(EditorState.createEmpty());
  }, [content]);

  useEffect(() => {
    if (textEditorRef?.current && focusEditor) {
      setEditorState(EditorState.moveFocusToEnd(editorState));
    }
  }, []);

  const handleOnEditorStateChange = (event) => {
    setEditorState(event);
    setState(event.getCurrentContent());
  };

  return (
    <>
      {warningText ? <span className="warning-text">{warningText}</span> : null}
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={handleOnEditorStateChange}
        wrapperClassName={`${readOnly ? 'readonly-editor' : ''} editorWrapper`}
        editorClassName="editor"
        toolbarClassName="toolbar"
        toolbarOnFocus={toolbarOnFocus}
        readOnly={readOnly}
        toolbar={toolbarOptions}
        ref={textEditorRef}
        customDecorators={customDecorators}
        editorStyle={error ? errorStyles : {}}
        customBlockRenderFunc={blockRendererFn}
        {...props}
      />
      {error && <span className="editor-error">{error}</span>}
    </>
  );
}

export default forwardRef(RichTextEditor);
