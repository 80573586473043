import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  REGION_FILTER,
} from 'components/filters/filterTypes';

export const COMPARISON_METHODS = [
  {
    id: BRANCH_FILTER,
    name: 'Location',
    filters: {
      [EXPERIENCE_FILTER]: {},
      [DATE_RANGE_FILTER]: {},
    },
  },
  {
    id: REGION_FILTER,
    name: 'Region',
    filters: {
      [EXPERIENCE_FILTER]: {},
      [DATE_RANGE_FILTER]: {},
    },
  },
  {
    id: EXPERIENCE_FILTER,
    name: 'Experience',
    filters: {
      [REGION_FILTER]: {},
      [BRANCH_FILTER]: {},
      [DATE_RANGE_FILTER]: {},
    },
  },
];

export const PERCENTAGE_PREVIEW = 'percentage';
export const VALUES_PREVIEW = 'values';
export const BOTH_PREVIEW = 'both';

export const COMPARISON_PREVIEWS = [
  { id: VALUES_PREVIEW, name: 'Values' },
  {
    id: PERCENTAGE_PREVIEW,
    name: '%',
  },
  {
    id: BOTH_PREVIEW,
    name: 'Both(Val, %)',
  },
];
