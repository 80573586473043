import { createSlice } from '@reduxjs/toolkit';

import { SINGLE_ITEM_DATA, TABLE_DATA } from 'utils/constants';

import {
  deleteRegionById,
  getLocationById,
  getLocationWithoutRegions,
  getPaginatedLocations,
  getRegionById,
  getRegions,
} from './locationsAndRegionsActions';

const INITIAL_STATE = {
  regions: TABLE_DATA,
  region: SINGLE_ITEM_DATA,
  deleteRegionsLoading: {},
  locationsWithoutRegions: SINGLE_ITEM_DATA,
  locations: TABLE_DATA,
  location: SINGLE_ITEM_DATA,
};

const locationAndRegionsSlice = createSlice({
  name: 'locationAndRegionsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetRegion: (state) => {
      state.region = SINGLE_ITEM_DATA;
    },
    resetLocationsWithoutRegions: (state) => {
      state.locationsWithoutRegions = SINGLE_ITEM_DATA;
    },
    resetLocationDetail: (state, action) => {
      state.location.data = action.payload ?? null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegions.pending, (state) => {
      state.regions.loading = true;
    });
    builder.addCase(getRegions.rejected, (state) => {
      state.regions.loading = false;
    });
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.regions.loading = false;
      state.regions.data = action.payload;
    });
    builder.addCase(getRegionById.pending, (state) => {
      state.region.loading = true;
    });
    builder.addCase(getRegionById.rejected, (state) => {
      state.region.loading = false;
    });
    builder.addCase(getRegionById.fulfilled, (state, action) => {
      state.region.loading = false;
      state.region.data = action.payload;
    });
    builder.addCase(deleteRegionById.pending, (state, { meta: { arg } }) => {
      state.deleteRegionsLoading[arg] = true;
    });
    builder.addCase(deleteRegionById.rejected, (state, { meta: { arg } }) => {
      state.deleteRegionsLoading[arg] = false;
    });
    builder.addCase(deleteRegionById.fulfilled, (state, { meta: { arg } }) => {
      state.deleteRegionsLoading[arg] = false;
    });

    builder.addCase(getLocationWithoutRegions.pending, (state) => {
      state.locationsWithoutRegions.loading = true;
    });
    builder.addCase(getLocationWithoutRegions.rejected, (state) => {
      state.locationsWithoutRegions.loading = false;
    });
    builder.addCase(getLocationWithoutRegions.fulfilled, (state, action) => {
      state.locationsWithoutRegions.loading = false;
      state.locationsWithoutRegions.data = action.payload;
    });

    builder.addCase(getPaginatedLocations.pending, (state) => {
      state.locations.loading = true;
    });
    builder.addCase(getPaginatedLocations.rejected, (state) => {
      state.locations.loading = false;
    });
    builder.addCase(getPaginatedLocations.fulfilled, (state, action) => {
      state.locations.loading = false;
      state.locations.data = action.payload;
    });
    builder.addCase(getLocationById.pending, (state) => {
      state.location.loading = true;
    });
    builder.addCase(getLocationById.rejected, (state) => {
      state.location.loading = false;
    });
    builder.addCase(getLocationById.fulfilled, (state, action) => {
      state.location.loading = false;
      state.location.data = action.payload;
    });

    return builder;
  },
});
export const {
  resetRegion,
  resetLocationDetail,
  resetLocationsWithoutRegions,
} = locationAndRegionsSlice.actions;
export default locationAndRegionsSlice;
