import { Spin } from 'antd/lib';

import './_Spinner.scss';

function Spinner({ children, wrapperStyles = {} }) {
  return (
    <div className="custom-spinner" style={wrapperStyles}>
      {children ?? <Spin />}
    </div>
  );
}

export default Spinner;
