function checkValue(str, max) {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10);
    if (Number.isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0), 10) &&
      num.toString().length === 1
        ? `0${num}`
        : num.toString();
  }
  return str;
}

export const expirationDateFormatter = (input) => {
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  const values = input.split('/').map((v) => v.replace(/\D/g, ''));
  if (values[0]) values[0] = checkValue(values[0], 12);
  const output = values.map((v, i) => (v.length === 2 && i < 1 ? `${v}/` : v));
  return output.join('').substr(0, 7);
};
export default expirationDateFormatter;

export const getFormattedAddress = (address) => {
  const { city, country, state, street_address: street } = address ?? {};
  return `${street ? `${street}` : ''}${city?.name ? `, ${city.name}` : ''}${
    state?.name ? `, ${state.name}` : ''
  }${country?.name ? `, ${country.name}` : ''}`;
};
