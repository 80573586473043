import * as Yup from 'yup';

const changePasswordSchema = (isGoogleUser) =>
  Yup.object().shape({
    old_password: Yup.string().when([], {
      is: () => isGoogleUser === true,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Old password is required!'),
    }),
    new_password: Yup.string()
      .required('New password is required!')
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .max(128, 'Password is too long - should be 128 characters maximum.')
      .matches(/\D/, "Password can't be entirely numeric."),
    confirm_password: Yup.string()
      .required('Confirm password is required!')
      .oneOf(
        [Yup.ref('new_password'), null],
        'New password and confirm password must match',
      ),
  });

export default changePasswordSchema;
