import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components';
import SurveyThumbnails from 'components/surveyThumbnail/SurveyThumbnails';
import {
  getEntitySurveys,
  getSurveysTemplates,
} from 'redux/surveys/surveysActions';

import './_FeedbackFormsList.scss';

function FeedbackFormsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    surveyTemplates: { data: surveyTemplates, isSurveysTemplatesLoading },
    entitySurveys: { data: entitySurveys, isEntitySurveysLoading },
  } = useSelector((s) => s.surveys);

  const fetchSurveyTemplates = async () => {
    dispatch(getSurveysTemplates());
  };

  const fetchEntitySurveys = async () => {
    dispatch(getEntitySurveys());
  };

  const getAllSurveys = () => {
    fetchSurveyTemplates();
    fetchEntitySurveys();
  };

  useEffect(() => {
    getAllSurveys();
  }, []);

  return (
    <>
      {isEntitySurveysLoading || isSurveysTemplatesLoading ? <Loader /> : null}
      <div className="fl-1 feedback-forms-container">
        <h2>{t('settings.feedbackForms.title')}</h2>
        <p>{t('settings.feedbackForms.subTitle')}</p>
        <SurveyThumbnails
          surveyTemplates={surveyTemplates}
          entitySurveys={entitySurveys}
        />
      </div>
    </>
  );
}

export default FeedbackFormsList;
