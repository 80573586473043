import React from 'react';
import ReactDatePicker from 'react-datepicker';

import dayjs from 'dayjs';

import './_singleDateFilter.scss';
import 'react-datepicker/dist/react-datepicker.css';

function SingleDateFilter({ value, onChange }) {
  const handleDateSelect = (date) => {
    onChange(dayjs(date).format('YYYY-MM-DD'));
  };

  return (
    <ReactDatePicker
      dateFormat="yyyy-MM-dd"
      selected={new Date(value)}
      onSelect={handleDateSelect}
      maxDate={new Date()}
    />
  );
}

export default SingleDateFilter;
