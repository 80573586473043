/* eslint-disable no-return-await */
import { CONFIG_WIDGETS_API } from './urls';
import { getAPICall, postAPICall } from './utils';

export async function getConfigWidgetsAPICall() {
  return await getAPICall(CONFIG_WIDGETS_API);
}

export async function setConfigWidgetsAPICall(data) {
  return await postAPICall(CONFIG_WIDGETS_API, data);
}
