import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  getComparisonByBranch,
  getComparisonByDateRange,
  getComparisonByExperience,
  getComparisonByRegion,
  getLocationsV2,
} from './branchComparisonActions';

const INITIAL_STATE = {
  isLoading: false,
  comparison: {},
  locations: {
    isLoading: false,
    data: [],
  },
};

const branchComparisonSlice = createSlice({
  name: 'branchComparisonSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setBranchComparison: (state, action) => {
      state.comparison = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocationsV2.pending, (state) => {
      state.locations.isLoading = true;
    });
    builder.addCase(getLocationsV2.rejected, (state) => {
      state.locations.isLoading = false;
    });
    builder.addCase(getLocationsV2.fulfilled, (state, action) => {
      state.locations.isLoading = false;
      state.locations.data = action.payload;
    });

    builder.addMatcher(
      isAnyOf(
        getComparisonByRegion.pending,
        getComparisonByBranch.pending,
        getComparisonByExperience.pending,
        getComparisonByDateRange.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getComparisonByRegion.rejected,
        getComparisonByBranch.rejected,
        getComparisonByExperience.rejected,
        getComparisonByDateRange.rejected,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getComparisonByRegion.fulfilled,
        getComparisonByBranch.fulfilled,
        getComparisonByExperience.fulfilled,
        getComparisonByDateRange.fulfilled,
      ),
      (state, action) => {
        state.isLoading = false;
        state.comparison = action.payload;
      },
    );
    return builder;
  },
});
export const { setBranchComparison } = branchComparisonSlice.actions;
export default branchComparisonSlice;
