import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Modal } from 'components/ui';
import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import {
  createInviteSchedule,
  getInviteSchedules,
  updateInviteById,
} from 'redux/emailManagement/emailManagementActions';
import { resetInviteSchedule } from 'redux/emailManagement/emailManagementSlice';
import {
  DATE_FORMAT,
  EMAIL_MANAGEMENT_PAGINATION,
  INBOX_EMAILS,
  MANAGE_EMAIL_SCHEDULE,
} from 'utils/constants/manageEmails';
import {
  formatInviteDataFromAPI,
  formatInviteDataFromForm,
} from 'utils/helpers/emailsManagementHelper';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { getEmailError } from 'utils/validations/common';
import { inviteEmailValidationSchema } from 'utils/validations/emailMangementValidations';

import TemplateOverlays from '../TemplateOverlays/TemplateOverlays';
import EmailComposition from './ComposeEmail';

import '../_manageEmailModals.scss';

function SendEmailModal() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const {
    inviteSchedule: { data: inviteSchedule, loading: inviteScheduleLoading },
  } = useSelector((s) => s.emailManagement);

  const { setParams, params, activeTab } = useContext(SettingsPageContext);
  const { setIsOpenSendEmailModal, inviteEmailDataRef } =
    useContext(ManageEmailsContext);

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setIsOpenSendEmailModal(false);
  };

  const [currentOverlayPreview, setCurrentOverlayPreview] = useState(null);
  const formik = useFormik({
    initialValues: {
      subject: '',
      email_json_data: '',
      members: [],
      members_file: null,
      groups: [],
      entity_experience: null,
      updateHTMLEditorState: false,
      show_email_schedule: false,
      scheduled_at: {
        date: null,
        time: null,
      },
      show_reminder_schedule: false,
      reminder_schedules: {
        reminder_type: '',
        reminder_time: null,
        custom_reminder_day: [],
        ends_at: dayjs().add(30, 'days').format(DATE_FORMAT),
      },
      ...(inviteEmailDataRef.current?.invite ?? {}),
    },
    validationSchema: inviteEmailValidationSchema,
    onSubmit: async (values) => {
      const { emailInput, ...emailValues } = values;
      const emailError = getEmailError(emailInput);
      if (emailInput && emailInput.trim() && emailError) {
        formik.setFieldError('invalidMembers', emailError);
      } else {
        const inviteData = formatInviteDataFromForm(emailValues);
        setLoading(true);
        if (inviteEmailDataRef.current?.inviteId) {
          const { isError } = await dispatch(
            updateInviteById({
              id: inviteEmailDataRef.current?.inviteId,
              data: inviteData,
            }),
            formik,
          );
          if (!isError) {
            dispatch(getInviteSchedules(params));
            toast.success(t('settings.manageEmails.toasts.updateInvite'));
            handleModalClose();
          }
        } else {
          const { isError } = await dispatch(
            createInviteSchedule(inviteData),
            formik,
          );
          if (!isError) {
            if (
              activeTab.id === INBOX_EMAILS ||
              activeTab.id === MANAGE_EMAIL_SCHEDULE
            ) {
              setParams({ ...EMAIL_MANAGEMENT_PAGINATION });
            }
            toast.success(t('settings.manageEmails.toasts.createInvite'));
            handleModalClose();
          }
        }

        setLoading(false);
      }
    },
  });

  const submitEmailForm = () => {
    setTimeout(() => {
      // To handle the onBlur Event called from Email To Input before form submission
      formik.submitForm();
    }, 0);
  };

  useEffect(() => {
    if (inviteSchedule) {
      const inviteData = formatInviteDataFromAPI(inviteSchedule);
      formik.resetForm({
        values: {
          ...formik.values,
          ...inviteData,
          updateHTMLEditorState: true,
        },
      });
    }
  }, [inviteSchedule]);

  useEffect(() => {
    return () => {
      inviteEmailDataRef.current = null;
      dispatch(resetInviteSchedule());
    };
  }, []);

  return (
    <Modal
      open
      onClose={handleModalClose}
      backDropClose={false}
      className="email-management-modals"
      isLoading={inviteScheduleLoading}
      isCurveredBorderedModal
    >
      <Modal.Header title="New Email" onClose={handleModalClose} />
      <Modal.Body className="compose-email-modal">
        <EmailComposition
          setCurrentOverlayPreview={setCurrentOverlayPreview}
          formik={formik}
        />
      </Modal.Body>
      <Modal.Footer
        isLoading={loading}
        primaryLabel={inviteEmailDataRef.current?.inviteId ? 'Update' : 'Send'}
        primaryAction={submitEmailForm}
      />
      {currentOverlayPreview ? (
        <TemplateOverlays
          formik={formik}
          currentOverlayPreview={currentOverlayPreview}
          setCurrentOverlayPreview={setCurrentOverlayPreview}
        />
      ) : null}
    </Modal>
  );
}

export default SendEmailModal;
