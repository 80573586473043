/* eslint-disable no-return-await */
import {
  COSTS_API,
  INVOICES_API,
  PAYMENT_METHODS_API,
  RESUBSCRIPTION_API,
  SET_DEFAULT_PAYMENT_METHOD_API,
  SUBSCRIBE_API,
  SUBSCRIPTION_DETAILS_API,
  SUBSCRIPTION_PLANS_API,
  UPDATE_PAYMENT_METHOD_API,
  UPGRADE_PLAN_API,
} from './urls';
import { deleteAPICall, getAPICall, patchAPICall, postAPICall } from './utils';

export async function fetchPaymentCostsApiCall(planId) {
  return await getAPICall(`${COSTS_API(planId)}`);
}

export async function subscriberPlanApiCall(planId, data) {
  return await postAPICall(`${SUBSCRIBE_API(planId)}`, data);
}

export async function upgradePlanApiCall(planId, data) {
  return await postAPICall(`${UPGRADE_PLAN_API(planId)}`, data);
}

export async function cancelPlanApiCall() {
  return await deleteAPICall(`${SUBSCRIPTION_DETAILS_API}`, {});
}

export async function resubscriberApiCall() {
  return await postAPICall(`${RESUBSCRIPTION_API}`, {});
}

export async function fetchPaymentPlansAPICall(queryParams = '') {
  return await getAPICall(SUBSCRIPTION_PLANS_API(queryParams), {});
}

export async function fetchSubscribedPlanAPICall() {
  return await getAPICall(SUBSCRIPTION_DETAILS_API, {});
}

export async function fetchPaymentMethodsAPICall() {
  return await getAPICall(PAYMENT_METHODS_API, {});
}

export async function createPaymentMethodAPICall(data) {
  return await postAPICall(PAYMENT_METHODS_API, data);
}

export async function updatePaymentMethodAPICall(id, data) {
  return await patchAPICall(UPDATE_PAYMENT_METHOD_API(id), data);
}

export async function deletePaymentMethodAPICall(id) {
  return await deleteAPICall(UPDATE_PAYMENT_METHOD_API(id), {});
}

export async function setDefaultPaymentMethodAPICall(id) {
  return await getAPICall(SET_DEFAULT_PAYMENT_METHOD_API(id), {});
}

export async function fetchInvoicesAPICall(params) {
  return await getAPICall(INVOICES_API, params);
}
