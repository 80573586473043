import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { Link, useLocation } from 'react-router-dom';

import { useFormik } from 'formik';

import { LoginWithGoogle } from 'components';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { LOGIN, URLS } from 'utils/constants';
import { populateFormikErrors } from 'utils/helpers';
import { useLogin, usePageTitle } from 'utils/hooks';
import { loginSchema } from 'utils/validations';

function Login() {
  const location = useLocation();
  const [loading, login] = useLogin();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await login(values);
      } catch (errors) {
        if (errors) {
          populateFormikErrors(errors, formik);
        }
      }
    },
    validationSchema: loginSchema,
  });

  usePageTitle(LOGIN);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Login">
          <div className="auth-content">
            <LoginWithGoogle />
            <div className="auth-divider">or</div>
            <h2 className="onboarding-field-title">
              Log in using email address
            </h2>
            <Form onSubmit={formik.handleSubmit} className="auth-form">
              <label
                className={`control ${
                  formik.touched.email && formik.errors.email
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address *"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && (
                  <span className="control-error">{formik.errors.email}</span>
                )}
              </label>
              <label
                className={`control ${
                  formik.touched.password && formik.errors.password
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="password"
                  type="password"
                  placeholder="Password *"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && (
                  <span className="control-error">
                    {formik.errors.password}
                  </span>
                )}
              </label>
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <Form.Label className="textRight">
                <Link to={URLS.FORGOT_PASSWORD_URL}>Forgot Password?</Link>
              </Form.Label>
              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                Log in
              </button>
            </Form>

            <span className="askLogin">
              Don&apos;t have an account?{' '}
              <Link to={URLS.SIGNUP_URL}>Sign Up</Link>
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}

export default Login;
