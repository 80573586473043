import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';

const regionValidationSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Region name cannot be only blank spaces.',
      'Region name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'Region name can only contain letters')
    .range(
      1,
      128,
      'Must be at least 1 character',
      'Must not exceed 128 characters',
    ),
});

export default regionValidationSchema;
