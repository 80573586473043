import React, { useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { Filters } from 'components';
import { DATE_RANGE_FILTER } from 'components/filters/filterTypes';
import { Icon } from 'components/ui';

function ComparisonControls({ comparisonMethod, onChangeComparisonParams }) {
  const populateFilters = () => {
    return comparisonMethod.filters;
  };
  const [comparisonFilters, setComparisonFilters] = useState(populateFilters);

  const resetFiltersHandler = () => {
    setComparisonFilters(populateFilters);
  };

  useEffect(() => {
    onChangeComparisonParams(comparisonFilters);
  }, [comparisonFilters]);

  useEffect(() => {
    setComparisonFilters(populateFilters);
  }, [comparisonMethod]);

  const showResetBtn = useMemo(
    () => Object.values(comparisonFilters).find((filter) => !isEmpty(filter)),
    [comparisonFilters],
  );

  const dateFilterPlaceholder = useMemo(() => {
    const { startDate, endDate } = comparisonFilters[DATE_RANGE_FILTER];
    return startDate && endDate ? `${startDate} to ${endDate}` : 'Date Range';
  }, [comparisonFilters[DATE_RANGE_FILTER]]);

  return (
    <div className="comparison__filters-section">
      <Icon type="filter_alt" />
      <div className="comparison__controls">
        <Filters
          value={comparisonFilters}
          onChange={setComparisonFilters}
          branchPlaceholder="Location"
          experiencePlaceholder="Experience"
          regionPlaceholder="Region"
          dateRangePlaceholder={dateFilterPlaceholder}
          isRoundedDropDown
        />
      </div>
      {showResetBtn && (
        <button
          type="button"
          onClick={resetFiltersHandler}
          className="comparison__controls-reset"
        >
          Reset Filters
        </button>
      )}
    </div>
  );
}

export default ComparisonControls;
