import CityDropDown from 'components/demographics/cityDropDown/CityDropDown';
import CountryDropDown from 'components/demographics/countryDropDown/CountryDropDown';
import RegionsDropDown from 'components/demographics/regionsDropdown/RegionsDropdown';
import StateDropDown from 'components/demographics/stateDropDown/StateDropDown';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';

export default function LocationInfoForm({ formik }) {
  const { values, errors, touched } = formik ?? {};

  return (
    <form>
      <FormikLabeledInput
        formik={formik}
        inputName="name"
        placeholder="Location Name"
      />
      <FormikLabeledInput
        formik={formik}
        inputName="street_address"
        placeholder="Street Address"
      />
      <RegionsDropDown
        placeholder="Select Region *"
        value={values.region}
        onChange={(id) => formik.setFieldValue('region', id)}
        error={touched.region ? errors.region : null}
      />
      <CountryDropDown
        placeholder="Select Country *"
        value={values.country}
        onChange={(id) => formik.setFieldValue('country', id)}
        error={
          touched.country && errors.country ? (
            <span className="control-error">{errors.country}</span>
          ) : null
        }
      />
      <div className="onboarding-location-dropdowns">
        <StateDropDown
          placeholder="Select State *"
          onChange={(id) => formik.setFieldValue('state', id)}
          value={values.state}
          countryId={values.country}
          error={
            touched.state && errors.state ? (
              <span className="txt-error">{errors.state}</span>
            ) : null
          }
        />
        <CityDropDown
          placeholder="Select City *"
          onChange={(id) => formik.setFieldValue('city', id)}
          value={values.city}
          stateId={values.state}
          error={
            touched.city && errors.city ? (
              <span className="txt-error">{errors.city}</span>
            ) : null
          }
        />
        <FormikLabeledInput
          formik={formik}
          inputName="zip_code"
          placeholder="Zip Code"
        />
      </div>
    </form>
  );
}
