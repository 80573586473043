import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import { useBranchUpdateQuery } from 'components/queries';
import { getLocationById } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';
import { branchSetupSchema } from 'utils/validations';

const LOCATION_DATA = {
  name: '',
  street_address: '',
  state: '',
  city: '',
  country: '',
  zip_code: '',
  region: '',
};

export default function useLocationSetup({
  isOnboarding = false,
  locationId,
  location,
  onUpdate,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(!!locationId);
  const {
    location: { data: locationData },
  } = useSelector((state) => state.locationsAndRegions);

  const {
    isLoading: isSubmitting,
    onSubmit,
    errors,
  } = useBranchUpdateQuery(locationId, onUpdate);

  const getLocationFormikData = (data) => {
    if (data)
      return {
        ...data,
        region: data.region?.id,
        country: data.country?.id,
        city: data.city?.id,
        state: data.state?.id,
      };
    return { ...LOCATION_DATA };
  };

  const formik = useFormik({
    initialValues: !isEmpty(location)
      ? getLocationFormikData(location)
      : { ...LOCATION_DATA },
    onSubmit,
    validationSchema: branchSetupSchema({
      isRegionRequired: isOnboarding || !locationId,
    }),
    validateOnChange: true,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (locationData) {
      formik.setValues(getLocationFormikData(locationData));
    }
  }, [locationData]);

  useEffect(() => {
    if (locationId) {
      dispatch(getLocationById(locationId)).then(() => {
        setIsLoading(false);
      });
    }
    return () => {
      dispatch(resetLocationDetail(null));
    };
  }, [locationId]);

  useEffect(() => {
    if (errors) {
      formik.setErrors(errors.response.data);
    }
  }, [errors]);

  return {
    formik,
    isLoading,
    isSubmitting,
    onSubmit,
  };
}
