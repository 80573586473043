import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  access_token: null,
};

export const authSlice = createSlice({
  name: 'auth_token',
  initialState: INITIAL_STATE,
  reducers: {
    setUserToken: (state, action) => {
      state.access_token = action.payload.access_token;
    },
  },
});

const { actions, reducer } = authSlice;
export const { setUserToken } = actions;
export default reducer;
