import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider } from 'antd';

import App from './App';
import { GOOGLE_WEB_CLIENT_ID } from './config';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_WEB_CLIENT_ID}>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#FEAD44EE',
              algorithm: true, // Enable algorithm
              colorBorder: '#FEAD44EE',
              colorBorderBg: '#FEAD44EE',
              colorText: '#FEAD44EE',
            },
            token: {
              colorPrimary: '#FEAD44EE',
              borderRadius: 2,
              // colorBgContainer: '#fff',
            },
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
