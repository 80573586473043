import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import { getInviteGroups } from 'redux/emailManagement/emailManagementActions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { getGroupsColumns } from 'utils/helpers/emailsManagementHelper';

function Groups() {
  const dispatch = useDispatch();
  const {
    inviteGroups: { data: inviteGroups, loading },
  } = useSelector((s) => s.emailManagement);

  const { params, onChangePagination } = useContext(SettingsPageContext);
  const {
    inviteGroupIdRef,
    setIsOpenGroupModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(ManageEmailsContext);

  const editInviteGroupHandler = (inviteGroup) => {
    inviteGroupIdRef.current = inviteGroup.id;
    setIsOpenGroupModal(true);
  };

  const composeEmailHandler = (group) => {
    inviteEmailDataRef.current = { invite: { groups: [group] } };
    setIsOpenSendEmailModal(true);
  };

  useEffect(() => {
    dispatch(getInviteGroups({ ...params }));
  }, [params]);

  return (
    <div>
      <div className="contentWrap">
        <Table
          className="left-align"
          scroll={{ x: 'max-content' }}
          columns={getGroupsColumns(
            editInviteGroupHandler,
            composeEmailHandler,
          )}
          dataSource={inviteGroups.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: inviteGroups.count,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            onChange: onChangePagination,
          }}
        />
      </div>
    </div>
  );
}

export default Groups;
