import * as Yup from 'yup';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required!')
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .max(128, 'Password is too long - should be 128 characters maximum.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default resetPasswordSchema;
