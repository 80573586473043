import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from 'react-router-dom';

import { Loader, UserPage } from 'components';
import { DashboardLayout } from 'layouts';
import {
  BranchCompare,
  ChangePassword,
  CreateSurvey,
  CreateSurveyTemplate,
  Dashboard,
  Digest,
  EditProfileTabs,
  FeedbackFormSurveyDetail,
  FeedbackFormsList,
  ForgotPassword,
  GenerateQRs,
  Insights,
  ListSurveyTemplates,
  LocationsAndRegions,
  Login,
  LuckyDraw,
  ManageEmails,
  NotFound,
  Organization,
  PaymentDashboard,
  QrList,
  ResetPassword,
  ResetPasswordSuccess,
  ReviewsListing,
  UpdateOrganisation,
} from 'pages';
import EditProfile from 'pages/editProfile/EditProfile';
import Invoices from 'pages/invoices/Invoices';
import Settings from 'pages/settings/Settings';
import { FINANCIAL_ADMIN, URLS } from 'utils/constants';
import {
  FEEDBACK_FORMS_DETAIL_SETTINGS_URL,
  FEEDBACK_FORMS_SETTINGS_URL,
  INVOICES_SETTINGS_URL,
  PAYMENT_DASHBOARD_SETTINGS_URL,
  SURVEYS_URL,
  UPDATE_ORG_SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { AuthRedirect, ProtectedRoute } from 'utils/hoc';

import OnboardingRoutes from './onboardingRoutes';
import RoutesWrapper from './routesWrapper';

function AppRoutes() {
  const { user } = useSelector((state) => state.authentication);

  const getDashBoard = () => {
    if (user.type === FINANCIAL_ADMIN) {
      return PaymentDashboard;
    }
    return Dashboard;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route element={<RoutesWrapper />}>
            {OnboardingRoutes()}
            <Route
              path={URLS.LOGIN_URL}
              element={
                <AuthRedirect to={URLS.DASHBOARD_URL}>
                  <Login />
                </AuthRedirect>
              }
            />
            <Route
              path={URLS.FORGOT_PASSWORD_URL}
              element={<ForgotPassword />}
            />
            <Route path={URLS.RESET_PASSWORD_URL} element={<ResetPassword />} />
            <Route
              path={URLS.RESET_PASSWORD_SUCCESS_URL}
              element={<ResetPasswordSuccess />}
            />
            <Route
              path={URLS.ORGANIZATION_URL}
              element={<ProtectedRoute isSuperuser Component={Organization} />}
            />
            <Route
              path={URLS.GENERATE_QRS_URL}
              element={<ProtectedRoute isSuperuser Component={GenerateQRs} />}
            />
            <Route
              path={URLS.ADMIN_CREATE_SURVEY_URL}
              element={
                <ProtectedRoute
                  isRestrictedToParentEntityAdmin
                  Component={CreateSurvey}
                />
              }
            />
            <Route
              path={URLS.ADMIN_CREATE_SURVEY_TEMPLATE_URL}
              element={
                <ProtectedRoute isSuperuser Component={CreateSurveyTemplate} />
              }
            />
            <Route
              path={URLS.ADMIN_SURVEY_TEMPLATES}
              element={
                <ProtectedRoute isSuperuser Component={ListSurveyTemplates} />
              }
            />
            <Route element={<DashboardLayout />}>
              <Route path={URLS.DASHBOARD_URL}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      isRestrictedToEntityAdmin
                      isRestrictedToFinancialAdmin
                      Component={getDashBoard()}
                    />
                  }
                />
              </Route>
              <Route
                path={URLS.COMPARE_BRANCH_URL}
                element={
                  <ProtectedRoute
                    isRestrictedToParentEntityAdmin
                    isRestrictedToEntityAdmin
                    Component={BranchCompare}
                  />
                }
              />
              <Route
                path={URLS.INSIGHTS_URL}
                element={
                  <ProtectedRoute
                    isRestrictedToParentEntityAdmin
                    isRestrictedToEntityAdmin
                    Component={Insights}
                  />
                }
              />
              <Route
                path={URLS.EDIT_PROFILE_URL}
                element={
                  <ProtectedRoute
                    isRestrictedToFinancialAdmin
                    isRestrictedToParentEntityAdmin
                    isRestrictedToEntityAdmin
                    Component={EditProfileTabs}
                  />
                }
              >
                <Route
                  index
                  element={<Navigate to={URLS.EDIT_USER_PROFILE_URL} />}
                />
                <Route
                  path={URLS.EDIT_USER_PROFILE_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToFinancialAdmin
                      isRestrictedToParentEntityAdmin
                      isRestrictedToEntityAdmin
                      Component={EditProfile}
                    />
                  }
                />
                <Route
                  path={URLS.CHANGE_PASSWORD}
                  element={
                    <ProtectedRoute
                      isRestrictedToFinancialAdmin
                      isRestrictedToParentEntityAdmin
                      isRestrictedToEntityAdmin
                      Component={ChangePassword}
                    />
                  }
                />
              </Route>
              <Route
                path={URLS.SETTINGS_URL}
                element={
                  <ProtectedRoute
                    isRestrictedToParentEntityAdmin
                    Component={Settings}
                  />
                }
              >
                <Route
                  index
                  element={<Navigate to={URLS.FEEDBACK_FORMS_SETTINGS_URL} />}
                />
                <Route
                  path={FEEDBACK_FORMS_SETTINGS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={FeedbackFormsList}
                    />
                  }
                />
                <Route
                  path={FEEDBACK_FORMS_DETAIL_SETTINGS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={FeedbackFormSurveyDetail}
                    />
                  }
                />
                <Route
                  path={SURVEYS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={QrList}
                    />
                  }
                />
                <Route
                  path={`${URLS.MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL}/*`}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={LocationsAndRegions}
                    />
                  }
                />
                <Route
                  path={URLS.USERS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={UserPage}
                    />
                  }
                />
                <Route
                  path={`${URLS.MANAGE_EMAILS_SETTINGS_URL}/*`}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={ManageEmails}
                    />
                  }
                />
                <Route
                  path={`${UPDATE_ORG_SETTINGS_URL}/*`}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={UpdateOrganisation}
                    />
                  }
                />
                <Route
                  path={PAYMENT_DASHBOARD_SETTINGS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={PaymentDashboard}
                    />
                  }
                />
                <Route
                  path={INVOICES_SETTINGS_URL}
                  element={
                    <ProtectedRoute
                      isRestrictedToParentEntityAdmin
                      Component={Invoices}
                    />
                  }
                />
              </Route>
              <Route
                path={URLS.REVIEWS_LISTING_URL}
                element={
                  <ProtectedRoute
                    isRestrictedToParentEntityAdmin
                    isRestrictedToEntityAdmin
                    Component={ReviewsListing}
                  />
                }
              />
              <Route
                path={URLS.DIGEST_URL}
                element={<ProtectedRoute isSuperuser Component={Digest} />}
              />
              <Route
                path={URLS.LUCKY_DRAW_URL}
                element={<ProtectedRoute isSuperuser Component={LuckyDraw} />}
              />
            </Route>
            <Route path={URLS.NOT_FOUND_URL} element={<NotFound />} />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default AppRoutes;
