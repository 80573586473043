import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { entityListAPICallV2 } from 'apis/cxmeter';
import { LocationThumbnail } from 'components/locationThumbnail';
import { Modal } from 'components/ui';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { updateParentEntity } from 'redux/authentication/authenticationActions';
import { setLastOnboardingStep } from 'redux/authentication/authenticationSlice';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';
import { getFullURL } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';

import './_branchList.scss';

export default function BranchListScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const { setBranch, addedBranch, setAddedBranch } = useContext(
    OnboardingBranchContext,
  );
  const orgType = useSelector(
    (state) => state.authentication.parentEntity.type_name,
  );

  const dispatch = useDispatch();
  const name = useSelector((state) => state.authentication.parentEntity.name);

  usePageTitle('Location Setup');

  const addBranch = () => {
    setBranch({});
    setAddedBranch(false);
    navigate(URLS.BRANCH_SETUP_URL);
  };

  const openBranch = (id) => {
    navigate(`${URLS.BRANCH_SETUP_URL}/${id}`);
  };

  const handleBranchSetupSkip = () => {
    setAddedBranch(false);
    dispatch(
      updateParentEntity({
        last_onboarding_url: getFullURL(URLS.ONBOARDING_SAAS_AGREEMENT),
        name,
      }),
    );
    dispatch(setLastOnboardingStep(URLS.ONBOARDING_SAAS_AGREEMENT));
    navigate(URLS.ONBOARDING_SAAS_AGREEMENT);
  };

  useEffect(() => {
    entityListAPICallV2()
      .then(setBranches)
      .finally(() => setLoading(false));
  }, []);

  return (
    <OnboardingLayout>
      <Modal onClose={() => setAddedBranch(false)} open={addedBranch}>
        <Modal.Header
          title="Location Added Successfully"
          onClose={() => setAddedBranch(false)}
        />
        <Modal.Body>
          <div style={{ maxHeight: 'calc(100% - 100px' }}>
            Location added successfully. Would you like to add more locations?
          </div>
        </Modal.Body>
        <Modal.Footer
          primaryLabel="Yes"
          primaryAction={addBranch}
          secondaryLabel="No"
          secondaryAction={handleBranchSetupSkip}
        />
      </Modal>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title="Location Setup"
          subtitle={`Add locations for your ${orgType || 'organization'}`}
        >
          <div className="branches">
            {branches.map((branch) => (
              <LocationThumbnail
                key={branch.id}
                onSelect={() => openBranch(branch.id)}
              >
                {branch.name}
              </LocationThumbnail>
            ))}
            <LocationThumbnail onSelect={addBranch}>
              <FontAwesomeIcon icon={faPlus} />
            </LocationThumbnail>
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={loading}
          disableContinue={branches.length === 0}
          onClickContinue={handleBranchSetupSkip}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
