import { combineReducers } from '@reduxjs/toolkit';

import authenticationSlice from './authentication/authenticationSlice';
import branchComparisonSlice from './branchComparison/branchComparisonSlice';
import dashboardSlice from './dashboard/dashboardSlice';
import demographicsSlice from './demographics/demographicsSlice';
import emailManagement from './emailManagement/emailManagementSlice';
import experienceSlice from './experience/experienceSlice';
import filterSlice from './filters/filterSlice';
import insightsSlice from './insights/insightsSlice';
import locationAndRegionsSlice from './locationsAndRegions/locationsAndRegionsSlice';
import notificationSlice from './notification/notificationSlice';
import paginatedDropdowns from './paginatedDropdowns/paginatedDropdownsSlice';
import paymentsSlice from './payments/paymentsSlice';
import portalFeedbackSlice from './portalFeedback/portalFeedbackSlice';
import qrSetupSlice from './qrSetup/qrSetupSlice';
import authReducer from './reducers/authSlice';
import feedbackReducer from './reducers/feedbackSlice';
import filterReducer from './reducers/filterSlice';
import pageReducer from './reducers/pageSlice';
import reviewsListingSlice from './reviewsListing/reviewsListing.slice';
import saasAgreementSlice from './saasAgreement/saasAgreementSlice';
import settingsSlice from './settings/settingsSlice';
import surveysSlice from './surveys/surveysSlice';
import usersSlice from './users/usersSlice';

export default combineReducers({
  auth: authReducer, // Redundant Slice
  feedback: feedbackReducer, // Redundant Slice
  filter: filterReducer, // Redundant Slice
  page: pageReducer, // Redundant Slice
  dashboard: dashboardSlice.reducer,
  reviews: reviewsListingSlice.reducer,
  filters: filterSlice.reducer,
  branchComparison: branchComparisonSlice.reducer,
  settings: settingsSlice.reducer,
  authentication: authenticationSlice.reducer,
  surveys: surveysSlice.reducer,
  payments: paymentsSlice.reducer,
  notifications: notificationSlice.reducer,
  qrSetup: qrSetupSlice.reducer,
  experience: experienceSlice.reducer,
  saasAgreement: saasAgreementSlice.reducer,
  portalFeedback: portalFeedbackSlice.reducer,
  users: usersSlice.reducer,
  emailManagement: emailManagement.reducer,
  insightsData: insightsSlice.reducer,
  locationsAndRegions: locationAndRegionsSlice.reducer,
  demographics: demographicsSlice.reducer,
  paginatedDropdowns: paginatedDropdowns.reducer,
});
