/* eslint-disable camelcase */
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import { OrganizationForm } from 'components/forms';
import { updateOrg } from 'redux/settings/settingsActions';
import { dataURLtoFile } from 'utils/helpers';
import { useScreenshot } from 'utils/hooks';
import { regOrganizationSchema } from 'utils/validations';

function OrganizationDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);
  const { parentEntity } = useSelector((state) => state.authentication);

  const avatarRef = useRef();
  const [, takeScreenshot] = useScreenshot();

  const onSubmit = async ({ name, logo, email, phone_number }) => {
    const payload = { name, logo, email, phone_number };
    if (!payload.logo) {
      payload.logo = dataURLtoFile(await takeScreenshot(avatarRef.current));
    }
    if (typeof payload.logo === 'string') {
      delete payload.logo;
    }
    if (!payload.email) {
      payload.email = '';
    }
    if (!payload.phone_number) {
      payload.phone_number = '';
    }
    dispatch(updateOrg({ id: parentEntity.id, ...payload })).then((action) => {
      if (!action.error) {
        toast.success('Organization Updated successfully');
      }
    });
  };

  const form = useFormik({
    initialValues: parentEntity,
    onSubmit,
    validationSchema: regOrganizationSchema,
  });

  return (
    <div className="update-org-container">
      <div className="update-org-header">
        <h3>{t('settings.organization.details.title')}</h3>
        <p>{t('settings.organization.details.subTitle')}</p>
      </div>
      <div className="update-org-content">
        <OrganizationForm form={form} isEditing avatarRef={avatarRef} />
      </div>
      <div className="update-org-footer">
        <button
          type="button"
          className="cstm-btn secondary-cstm-btn"
          onClick={form.handleSubmit}
          disabled={isLoading || !form.dirty}
        >
          {t('settings.organization.submit')}
        </button>
      </div>
    </div>
  );
}

export default OrganizationDetails;
