import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProfileAvatar } from 'components';
import { URLS } from 'utils/constants';

import './_profile.scss';

export default function Profile({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="cxmeter-nav-profile-container">
      <ProfileAvatar
        size={41}
        photoUrl={user.profile_photo}
        firstName={user.first_name}
        lastName={user.last_name}
        userId={user.id}
      />
      <div className="info">
        <p className="name">
          {user.first_name} {user.last_name}
        </p>
        <button
          className="edit-profile"
          onClick={() =>
            navigate(URLS.EDIT_PROFILE_URL, {
              state: { previousPath: location.pathname },
            })
          }
        >
          {t('navigation.profilePopup.edit')}
        </button>
      </div>
    </div>
  );
}
