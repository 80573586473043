import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { isNil } from 'lodash';

import OrgAPIConfigurations from 'components/orgApiKeys/OrgAPIConfigurations';
import { updateOrgConfig } from 'redux/settings/settingsActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

const orgConfigTranslationKey = 'settings.organization.configurations';

function OrganisationConfigurations() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const { parentEntity } = useSelector((state) => state.authentication);

  const onSubmit = async ({ configuration }) => {
    const { isError } = await dispatch(
      updateOrgConfig({
        id: parentEntity.id,
        configuration: { configuration },
      }),
    );
    if (!isError)
      toast.success('Organization configuration updated successfully');
  };

  const formik = useFormik({
    initialValues: {
      configuration: {
        survey_end_sentence: '',
      },
    },
    onSubmit,
  });
  const { configuration } = formik.values;

  const handleSurveyEndSentenceChange = (surveyEndSentence) => {
    formik.setFieldValue(
      'configuration.survey_end_sentence',
      surveyEndSentence,
    );
  };

  useEffect(() => {
    if (parentEntity?.configuration) {
      formik.resetForm({
        values: {
          configuration: parentEntity.configuration,
        },
      });
    }
  }, [parentEntity]);

  return (
    <div className="update-org-container">
      <div className="update-org-header">
        <h3>{t(`${orgConfigTranslationKey}.title`)}</h3>
        <p>{t(`${orgConfigTranslationKey}.subTitle`)}</p>
      </div>
      <div className="update-org-content">
        <div className="org-config-container">
          <div
            style={{ display: 'none' }} // Survey End Sentence is hidden for now. May be removed completely in future.
            className="survey-end-sentence-container"
          >
            <div className="survey-end-sentence">
              <div>
                <h6>
                  {t(`${orgConfigTranslationKey}.surveyEndSentence.title`)}
                </h6>
                <p>
                  {t(`${orgConfigTranslationKey}.surveyEndSentence.subTitle`)}
                </p>
              </div>
              <Switch
                name="survey-end-sentence-toggler"
                className="primary-antd-switch-input"
                onChange={(isChecked) =>
                  handleSurveyEndSentenceChange(isChecked ? '' : null)
                }
                checked={!isNil(configuration?.survey_end_sentence)}
              />
            </div>
            {isNil(configuration?.survey_end_sentence) ? null : (
              <div className="survey-end-sentence-message">
                <p>
                  {t(`${orgConfigTranslationKey}.surveyEndSentence.message`)}
                </p>
                <TextArea
                  className="message-input"
                  showCount
                  maxLength={100}
                  value={configuration?.survey_end_sentence}
                  onChange={({ target }) =>
                    handleSurveyEndSentenceChange(target.value)
                  }
                  placeholder="Type"
                  style={{ marginBottom: '1rem', height: 120, resize: 'none' }}
                />
              </div>
            )}
          </div>
          {/* <hr /> */}
          <OrgAPIConfigurations />
        </div>
      </div>
      {/* <div className="update-org-footer">
        <button
          type="button"
          className="cstm-btn secondary-cstm-btn"
          onClick={formik.handleSubmit}
          disabled={isLoading || !formik.dirty}
        >
          {t('settings.organization.submit')}
        </button>
      </div> */}
    </div>
  );
}

export default OrganisationConfigurations;
