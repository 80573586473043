import React, { useState } from 'react';

import { faStar as faOutlinedStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faFilledStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './_ratings.scss';

function Ratings({ disabled, stars = 0 }) {
  const [rating, setRating] = useState(stars);
  const handleRatingChange = (idx) => {
    setRating(idx + 1);
  };
  return (
    <span className="ratingsWrapper">
      {Array(rating)
        .fill('')
        .map((_el, idx) => (
          <FontAwesomeIcon
            key={idx}
            icon={faFilledStar}
            className={`ratingsStar ${disabled && 'disabledStar'}`}
            onClick={() => handleRatingChange(idx)}
          />
        ))}
      {Array(5 - rating)
        .fill('')
        .map((_el, idx) => (
          <FontAwesomeIcon
            key={idx}
            icon={faOutlinedStar}
            className={`ratingsStar ${disabled && 'disabledStar'}`}
            onClick={() => handleRatingChange(idx + rating)}
          />
        ))}
    </span>
  );
}

export default Ratings;
