import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  entityListAPICallV2,
  experienceListAPICall,
  locationListAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getLocations = createAsyncThunk(
  'dashboard/getLocations',
  async () => locationListAPICall().catch(showErrorToast),
);
export const getBranches = createAsyncThunk(
  'dashboard/getBranches',
  async (params) => entityListAPICallV2(params).catch(showErrorToast),
);
export const getExperience = createAsyncThunk(
  'dashboard/getExperience',
  async () => experienceListAPICall().catch(showErrorToast),
);
