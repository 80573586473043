import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { forgotPassword } from 'redux/authentication/authenticationActions';
import { RESET_PASSWORD, URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import Yup from 'utils/validations/Yup';

import './_forgotPassword.scss';

export default function ForgotPassword() {
  usePageTitle(RESET_PASSWORD);
  const navigate = useNavigate();
  const dispatch = useDispatchWithErrors();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required!').customEmail(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const { isError, data } = await dispatch(
        forgotPassword(values.email),
        formik,
      );
      if (!isError) {
        toast.success(data);
        navigate(URLS.LOGIN_URL);
      }
      setLoading(false);
    },
  });

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Forgot your password?">
          <div className="auth-content">
            <p>
              Don&apos;t worry, lets help you recover it.
              <br />
              Enter your email below to reset your password. A link will be sent
              to your email.
            </p>
            <form onSubmit={formik.handleSubmit} className="auth-form">
              <label
                className={`control ${
                  formik.errors.email ? 'control-invalid' : ''
                }`}
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address *"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={formik.errors.email ? 'error' : ''}
                />
                {formik.touched.email && (
                  <span className="control-error">{formik.errors.email}</span>
                )}
              </label>
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                Send Email
              </button>
            </form>
            <span className="askLogin">
              Want to login instead? Go to{' '}
              <Link to={URLS.LOGIN_URL}>Login</Link> or{' '}
              <Link to={URLS.SIGNUP_URL}>Sign Up</Link> for a new Account.
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
