import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { OnboardingBranchContext } from 'contexts';

export default function OnboardingBranchLayout() {
  const [branch, setBranch] = useState({});
  const [addedBranch, setAddedBranch] = useState(false);

  const contextValue = useMemo(
    () => ({ branch, setBranch, addedBranch, setAddedBranch }),
    [branch, setBranch, addedBranch, setAddedBranch],
  );

  return (
    <OnboardingBranchContext.Provider value={contextValue}>
      <Outlet />
    </OnboardingBranchContext.Provider>
  );
}
