import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCitiesAPICall,
  getCountriesAPICall,
  getStatesAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getCountries = createAsyncThunk(
  'branchComparison/getCountries',
  async () => getCountriesAPICall().catch(showErrorToast),
);

export const getStates = createAsyncThunk(
  'branchComparison/getStates',
  async (countryId) => getStatesAPICall(countryId).catch(showErrorToast),
);

export const getCities = createAsyncThunk(
  'branchComparison/getCities',
  async (stateId) => getCitiesAPICall(stateId).catch(showErrorToast),
);
