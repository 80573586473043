/* eslint-disable no-return-await */
import {
  LOCATIONS_WITHOUT_REGIONS,
  PAGINATED_LOCATIONS_API,
  REGIONS_API,
  REGIONS_DROPDOWN_API,
  REGION_BY_ID_API,
} from './urls';
import { deleteAPICall, getAPICall, patchAPICall, postAPICall } from './utils';

export async function regionsAPICall(params) {
  return await getAPICall(REGIONS_API, params);
}

export async function getRegionByIdAPICall(id) {
  return await getAPICall(REGION_BY_ID_API(id));
}

export async function createRegionAPICall(data) {
  return await postAPICall(REGIONS_API, data);
}

export async function updateRegionByIdAPICall(id, data) {
  return await patchAPICall(REGION_BY_ID_API(id), data);
}

export async function deleteRegionByIdAPICall(id) {
  return await deleteAPICall(REGION_BY_ID_API(id));
}

export async function locationsWithoutRegionsAPICall(params) {
  return await getAPICall(LOCATIONS_WITHOUT_REGIONS, params);
}

export async function regionsDropdownAPICall(params) {
  return await getAPICall(REGIONS_DROPDOWN_API, params);
}

export async function paginatedLocationsAPICall(params) {
  return await getAPICall(PAGINATED_LOCATIONS_API, params);
}
