import { arrayMove } from 'utils/helpers/global';

export const getSurveyIdFromQuestionId = (surveys, questionId) => {
  const overSurvey = surveys.filter((survey) => {
    const overQuestions = survey.questions.filter(
      (question) => question.id === questionId,
    );
    return overQuestions.length > 0;
  });
  return overSurvey[0]?.survey_id;
};

export const reOrderQuestionsInSameSurvey = (
  data,
  activeQuestionId,
  overQuestionId,
) => {
  const tempData = [...data];
  const overSurveyId = getSurveyIdFromQuestionId(data, overQuestionId);
  const overSurveyIndex = data.findIndex((s) => s.survey_id === overSurveyId);
  const overSurvey = { ...data[overSurveyIndex] };
  const sortedQuestions = arrayMove(
    [...overSurvey.questions],
    activeQuestionId,
    overQuestionId,
  );
  overSurvey.questions = sortedQuestions;
  tempData[overSurveyIndex] = overSurvey;
  return tempData;
};

export const reOrderQuestionsInDifferentObjectsOfSameSurvey = (
  data,
  activeQuestionId,
  overQuestionId,
) => {
  const tempData = [...data];
  const activeSurveyId = getSurveyIdFromQuestionId(data, activeQuestionId);
  const overSurveyId = getSurveyIdFromQuestionId(data, overQuestionId);
  const activeSurveyIndex = data.findIndex(
    (s) => s.survey_id === activeSurveyId,
  );
  const activeSurvey = { ...data[activeSurveyIndex] };
  let overSurveyIndex = data.findIndex((s) => s.survey_id === overSurveyId);
  const overSurvey = { ...data[overSurveyIndex] };
  const activeSurveyQuestions = [...activeSurvey.questions];
  activeSurvey.questions = activeSurveyQuestions;
  const activeQuestionIndex = activeSurveyQuestions.findIndex(
    (q) => q.id === activeQuestionId,
  );
  const overQuestionIndex = overSurvey.questions.findIndex(
    (q) => q.id === overQuestionId,
  );
  const overSurveyQuestions = [...overSurvey.questions];
  const [activeQuestion] = activeSurveyQuestions.splice(activeQuestionIndex, 1);
  activeSurvey.questions = activeSurveyQuestions;

  overSurveyQuestions.splice(overQuestionIndex, 0, activeQuestion);
  overSurvey.questions = overSurveyQuestions;
  if (activeSurvey.questions.length === 0) {
    tempData.splice(activeSurveyIndex, 1);
    overSurveyIndex = data.findIndex((s) => s.survey_id === overSurveyId);
  } else tempData[activeSurveyIndex] = activeSurvey;
  tempData[overSurveyIndex] = overSurvey;
  return tempData;
};

export const reOrderQuestionsInDifferentSurveys = (
  data,
  activeQuestionId,
  overQuestionId,
) => {
  const tempData = [...data];
  const activeSurveyId = getSurveyIdFromQuestionId(data, activeQuestionId);
  const overSurveyId = getSurveyIdFromQuestionId(data, overQuestionId);
  const activeSurveyIndex = data.findIndex(
    (s) => s.survey_id === activeSurveyId,
  );
  const activeSurvey = { ...data[activeSurveyIndex] };
  let overSurveyIndex = data.findIndex((s) => s.survey_id === overSurveyId);
  const overSurvey = { ...data[overSurveyIndex] };
  const activeSurveyQuestions = [...activeSurvey.questions];
  const activeQuestionIndex = activeSurveyQuestions.findIndex(
    (q) => q.id === activeQuestionId,
  );
  const overQuestionIndex = overSurvey.questions.findIndex(
    (q) => q.id === overQuestionId,
  );
  const overSurveyQuestions = [...overSurvey.questions];

  const newActiveQuestions = activeSurveyQuestions.splice(
    activeQuestionIndex,
    1,
  );
  activeSurvey.questions = activeSurveyQuestions;
  const newActiveSurvey = {
    ...activeSurvey,
    survey_id: activeSurvey.survey_id + activeQuestionId + overQuestionId,
    questions: newActiveQuestions,
  };
  const newOverQuestionsArray = overSurveyQuestions.splice(
    overQuestionIndex + 1,
  );
  overSurvey.questions = overSurveyQuestions;
  const newOverSurvey = {
    ...overSurvey,
    survey_id: overSurvey.survey_id + activeQuestionId + overQuestionId,
    questions: newOverQuestionsArray,
  };
  if (activeSurvey.questions.length === 0) {
    tempData.splice(activeSurveyIndex, 1);
    overSurveyIndex = data.findIndex((s) => s.survey_id === overSurveyId);
  } else tempData[activeSurveyIndex] = activeSurvey;
  tempData[overSurveyIndex] = overSurvey;
  tempData.splice(overSurveyIndex + 1, 0, newActiveSurvey);
  tempData.splice(overSurveyIndex + 2, 0, newOverSurvey);
  return tempData;
};
