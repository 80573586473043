import React from 'react';

import {
  ATTACHMENT,
  CHECKBOX,
  RADIO,
  RATING,
  TEXT,
} from 'utils/constants/questionTypes';
import { markupToText } from 'utils/helpers';

import ReviewAttachment from './ReviewAttachment';
import ReviewCheckbox from './ReviewCheckbox';
import ReviewRadio from './ReviewRadio';
// import { markupToText } from 'utils/helpers';
import ReviewRating from './ReviewRating';
import ReviewText from './ReviewText';

function Feedback({ question }) {
  switch (question.question_type) {
    case RATING:
      return <ReviewRating question={question} />;
    case CHECKBOX:
      return <ReviewCheckbox question={question} />;
    case RADIO:
      return <ReviewRadio question={question} />;
    case ATTACHMENT:
      return <ReviewAttachment question={question} />;
    case TEXT:
      return <ReviewText question={question} />;

    default:
      return <div />;
  }
}

function ReviewQuestionSection({ question }) {
  return (
    <div className="feedback-question">
      {question.question_type !== ATTACHMENT && (
        <h3
          className="feedback-question-title"
          dangerouslySetInnerHTML={{ __html: markupToText(question.label) }}
        />
      )}
      <Feedback question={question} />
    </div>
  );
}

export default ReviewQuestionSection;
