import { useContext } from 'react';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Filters } from 'components';
import HorizantalTabs from 'components/tabs/HorizantalTabs';
import SettingsPageContext from 'contexts/SettingsPageContext';

import './_settingsPageLayout.scss';

function SettingsPageLayout({ tabs, pageTitle, pageSubTitle, children }) {
  const {
    searchFilter,
    setSearchFilter,
    subTabsSearch,
    setSubTabsSearch,
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    subTabHandler,
  } = useContext(SettingsPageContext);

  return (
    <main>
      <div className="settings-page-layout-container">
        <div className="settings-page-layout-header">
          <div className="heading">
            {activeTab.isActiveSubTab ? (
              <div>
                <button
                  className="back-to-main-tabs"
                  onClick={() => subTabHandler({ isActive: false })}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>Back</span>
                </button>
                <h3>{activeSubTab.heading}</h3>
                <p>{activeSubTab.subHeading}</p>
              </div>
            ) : (
              <>
                <h3>{pageTitle}</h3>
                <p>{pageSubTitle}</p>
              </>
            )}
          </div>
          {/* Page general actions container */}
          {children.generalAction}
        </div>
        <div className="settings-page-layout-tabs-container">
          {activeTab.isActiveSubTab ? (
            <HorizantalTabs
              tabs={activeTab.subTabs}
              setActiveTab={setActiveSubTab}
              activeTab={activeSubTab}
            />
          ) : (
            <HorizantalTabs
              tabs={tabs}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          )}
          <div className="settings-page-layout-tabs-content">
            {activeSubTab?.hideSearchFilter ? null : (
              <div className="settings-page-layout-table-search">
                <Filters
                  value={
                    activeTab.isActiveSubTab ? subTabsSearch : searchFilter
                  }
                  addMainWrapper={false}
                  onChange={
                    activeTab.isActiveSubTab
                      ? setSubTabsSearch
                      : setSearchFilter
                  }
                />
                {/* Current Tab Actions container */}
                {children.currentTabActions}
              </div>
            )}
            {/* Current Tab Content */}
            {children.currentTabContent}
          </div>
        </div>
      </div>
    </main>
  );
}

export default SettingsPageLayout;
