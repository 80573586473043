import { useState } from 'react';

export default function useOrdering(defaultSort = []) {
  const [ordering, setOrdering] = useState(defaultSort);

  function updateSorting(sorter) {
    if (!sorter?.length) {
      sorter = [sorter];
    }
    const sortOrder = [];
    sorter.forEach((sortColumn) => {
      const { order, field } = sortColumn;
      if (order) {
        sortOrder.push(`${order === 'ascend' ? '' : '-'}${field}`);
      }
    });
    setOrdering(sortOrder);
  }

  function getOrderingForTable(orderingParam) {
    const result = {};
    orderingParam.forEach((column) => {
      // if ordering has an element [-created_at, ...]
      if (column.charAt(0) === '-') {
        result[column.slice(1)] = 'descend';
      } else result[column] = 'ascend';
    });

    return result;
  }

  return [ordering, updateSorting, getOrderingForTable];
}
