import React from 'react';

function Switch({ onChange, checked, ...rest }) {
  return (
    <label className="switch">
      <input type="checkbox" onChange={onChange} checked={checked} {...rest} />
      <span className="slider round" />
    </label>
  );
}

export default Switch;
