import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import dayjs from 'dayjs';

import { SEARCH_FILTER } from 'components/filters/filterTypes';
import SettingsPageContext from 'contexts/SettingsPageContext';
import { getInviteById } from 'redux/emailManagement/emailManagementActions';

import PendingInbox from './PendingInbox';
import ReceivedInbox from './ReceivedInbox';

function InboxSubTabs() {
  const { selectedRecord } = useOutletContext();
  const dispatch = useDispatch();
  const {
    invite: { data: invite, loading },
  } = useSelector((s) => s.emailManagement);

  const { subTabsSearch, activeSubTab, subTabHandler } =
    useContext(SettingsPageContext);
  const searchParams = subTabsSearch[SEARCH_FILTER];

  useEffect(() => {
    if (selectedRecord?.id)
      dispatch(
        getInviteById({
          id: selectedRecord.id,
          params: { search: searchParams },
        }),
      );
  }, [searchParams]);

  useEffect(() => {
    if (!activeSubTab && invite) {
      const date = invite.date
        ? dayjs(invite.date).format('MMMM DD • h:mm A')
        : null;
      subTabHandler({
        isActive: true,
        heading: invite.subject,
        subHeading: date,
      });
    }
  }, [invite?.id]);

  return (
    <>
      {activeSubTab?.id === 'receive' && (
        <ReceivedInbox loading={loading} data={invite ?? {}} />
      )}
      {activeSubTab?.id === 'pending' && (
        <PendingInbox
          loading={loading}
          inviteId={selectedRecord.id}
          searchParams={searchParams}
          data={invite ?? {}}
        />
      )}
    </>
  );
}

export default InboxSubTabs;
