import { createSlice } from '@reduxjs/toolkit';

import {
  getPortalFeedbackForms,
  postPortalFeedbackForm,
  updatePortalFeedbackForm,
} from './portalFeedbackActions';

const INITIAL_STATE = {
  forms: null,
  isLoading: false,
  isFormSubmitted: false,
  submittedFormId: null,
};

const portalFeedbackSlice = createSlice({
  name: 'experienceSlice',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPortalFeedbackForms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPortalFeedbackForms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPortalFeedbackForms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.forms = action.payload;
    });
    builder.addCase(postPortalFeedbackForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postPortalFeedbackForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postPortalFeedbackForm.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isFormSubmitted = true;
      state.submittedFormId = action.payload.feedback;
    });
    builder.addCase(updatePortalFeedbackForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePortalFeedbackForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePortalFeedbackForm.fulfilled, (state) => {
      state.isLoading = false;
      state.isFormSubmitted = true;
    });
    return builder;
  },
});

export default portalFeedbackSlice;
