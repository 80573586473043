import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Outlet, useLocation } from 'react-router-dom';

import SettingTabs from 'components/settingTabs/SettingTabs';
import { SETTINGS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import './_setting.scss';

function Settings() {
  const location = useLocation();

  usePageTitle(SETTINGS);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <SettingTabs>
      <SettingTabs.Menu />
      <SettingTabs.Content>
        <Outlet />
      </SettingTabs.Content>
    </SettingTabs>
  );
}

export default Settings;
