import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { createDigestMessage } from 'utils/helpers';

import './_digestComponent.scss';

function DigestItem({
  entityName,
  entityLogo,
  totalHappyCustomers,
  totalNeutralCustomers,
  totalUnhappyCustomers,
  totalCommentsAdded,
  totalAudiosAdded,
  totalImagesAdded,
  branchDetails,
}) {
  const { selectedDate } = useSelector((state) => state.filter);
  const [message, setMessage] = useState('');
  const [copied, setCopied] = useState(false);

  const onMessageChange = (e) => {
    setMessage(e.target.value);
    setCopied(false);
  };

  const onCopyMessage = () => {
    navigator.clipboard.writeText(message);
    setCopied(true);
  };

  const onResetMessage = () => {
    const initialMessage = createDigestMessage({
      entityName,
      totalHappyCustomers,
      totalNeutralCustomers,
      totalUnhappyCustomers,
      totalCommentsAdded,
      totalAudiosAdded,
      totalImagesAdded,
      branchDetails,
      date: selectedDate,
    });
    setMessage(initialMessage);
    setCopied(false);
  };

  useEffect(() => {
    const initialMessage = createDigestMessage({
      entityName,
      totalHappyCustomers,
      totalNeutralCustomers,
      totalUnhappyCustomers,
      totalCommentsAdded,
      totalAudiosAdded,
      totalImagesAdded,
      branchDetails,
      date: selectedDate,
    });

    setMessage(initialMessage);
  }, [selectedDate]);

  return (
    <section className="digestComponent">
      <div className="digestOrgComponentColumn">
        <img
          width={120}
          src={entityLogo || '/assets/images/appLogo.svg'}
          alt={`${entityName}-logo`}
        />
        <h3>{entityName}</h3>
      </div>
      <div className="messageBoxWrapper">
        <textarea
          rows="10"
          className="messageBox"
          value={message}
          onChange={onMessageChange}
        />
        <div className="digestOrgComponentColumn buttonWrapper">
          <button
            className="digestOrgComponentButton"
            onClick={onResetMessage}
            title="Revert message to the original version"
            type="button"
          >
            <img src="./assets/icons/icon-reset.svg" alt="reset" />
          </button>
          <button
            onClick={onCopyMessage}
            title="Copy message to clipboard"
            type="button"
            className={`digestOrgComponentButton ${
              copied && 'digestOrgComponentButtonActive'
            }`}
          >
            <img src="./assets/icons/icon-copy.svg" alt="reset" />
          </button>
          {copied && (
            <div className="digestComponentCopiedBanner">
              Message Copied to clipboard !
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default DigestItem;
