import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { faArchive, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import {
  archiveNotificationsAction,
  getNotificationsAction,
  updateNotificationsAction,
} from 'redux/notification/notificationActions';
import { URLS } from 'utils/constants';

import './notification.scss';

export default function NotificationCenter({ open, setOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const notifications = useSelector(
    (state) => state.notifications.notifications.results,
  );
  const isFetching = useSelector((state) => state.notifications.isFetching);

  const handleClickNotification = (notification) => {
    setOpen(false);
    if (location.pathname === URLS.REVIEWS_LISTING_URL) {
      searchParams.set('openFeedback', notification.feedback);
      setSearchParams(searchParams);
    } else {
      navigate({
        pathname: URLS.REVIEWS_LISTING_URL,
        search: createSearchParams({
          openFeedback: notification.feedback,
        }).toString(),
      });
    }
  };

  const onClickArchive = (id) => dispatch(archiveNotificationsAction(id));

  // get notifications
  useEffect(() => {
    dispatch(getNotificationsAction());
  }, []);

  // once notification centre has been opened, send a call to mark all unseen as read
  useEffect(() => {
    const unseenNotifications = notifications.filter(
      (notification) => !notification.is_read,
    );
    if (open && unseenNotifications?.length > 0) {
      dispatch(
        updateNotificationsAction({
          mark_read_ids: unseenNotifications.map(
            (notification) => notification.id,
          ),
        }),
      );
    }
  }, [open]);

  return (
    // eslint-disable-next-line
    <article
      onClick={(e) => e.stopPropagation()}
      className={`notification-center ${open ? 'notification-open' : ''}`}
    >
      {isFetching ? (
        <small className="spinner-loader">
          <FontAwesomeIcon icon={faSpinner} />
        </small>
      ) : (
        <ul>
          {notifications.length === 0 ? (
            <li className="notification-empty">
              <h3>{t('navigation.notificationCentre.caughtUp')}</h3>
            </li>
          ) : (
            notifications.map((notification) => (
              // eslint-disable-next-line
              <li
                key={notification.id}
                className={`notification notification-${notification.segment.name}`}
                onClick={() => handleClickNotification(notification)}
              >
                <img
                  src={`/assets/icons/icon_${notification.segment.name}.svg`}
                  alt="happy"
                />
                <div className="notification__content">
                  <p>
                    {t('navigation.notificationCentre.text', {
                      name: notification?.customer?.name || 'Anonymous',
                    })}{' '}
                    {dayjs
                      .utc(notification.updated_at)
                      .local()
                      .startOf('second')
                      .fromNow()}
                  </p>
                </div>
                {notification.is_archived ? (
                  <FontAwesomeIcon
                    icon={faArchive}
                    className="notification-archive"
                    onClick={() => onClickArchive(notification.id)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="notification-archive"
                    icon={faArchive}
                  />
                )}
              </li>
            ))
          )}
        </ul>
      )}
    </article>
  );
}
