import { useDispatch } from 'react-redux';

import { populateFormikErrors } from 'utils/helpers';

const useDispatchWithErrors = () => {
  const dispatch = useDispatch();

  const dispatchAction = async (
    mainAction,
    formik,
    { nextAction, params, shouldAwait = true } = {},
  ) => {
    const { error, payload, meta } = await dispatch(mainAction);
    const { data } = payload?.response ?? {};
    if (!error && nextAction) {
      if (shouldAwait) await dispatch(nextAction(params));
      else dispatch(nextAction(params));
    }
    if (error && data && formik) {
      populateFormikErrors(data, formik);
    }
    return { isError: Boolean(error), data: payload, meta };
  };

  return dispatchAction;
};

export default useDispatchWithErrors;
