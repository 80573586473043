import dayjs from 'dayjs';
import { ContentState } from 'draft-js';

import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import { findSurveyLink } from 'utils/helpers/richTextEditorHelpers';

import Yup from './Yup';

const EmptyMembersValidation = (values, { createError }) => {
  const { members, members_file: membersFile, groups } = values;
  let isMembersPresent = false;
  isMembersPresent = members.find((m) => m.email);
  if (groups && !isMembersPresent) {
    isMembersPresent = groups.find((g) => g.id);
  }
  if (!isMembersPresent && !membersFile) {
    return createError({
      message: `Please select a member file${
        groups ? ', a group,' : ''
      } or enter an email address.`,
      path: 'members_file',
    });
  }
  return true;
};

const TextEditorValidation = (validateSurveyLink = true) =>
  Yup.mixed().test('email-body-validation', (body, { createError, path }) => {
    if (!body || (typeof body === 'string' && body.trim() === '')) {
      return createError({ message: 'Email body is required', path });
    }
    if (typeof body !== 'string' && body instanceof ContentState) {
      const bodyPlainText = body.getPlainText();
      if (bodyPlainText.trim() === '') {
        return createError({ message: 'Email body is required', path });
      }
      if (!validateSurveyLink) return true;
      const isSurveyLinkPresent = findSurveyLink(body);
      if (!isSurveyLinkPresent) {
        return createError({
          message: 'Email body should contains survey link',
          path,
        });
      }
    }

    return true;
  });

export const inviteGroupValidationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .requiredTrimmed(
        'Group name cannot be only blank spaces.',
        'Group name is required!',
      )
      .matches(onlyLettersAndSpacesRegex, 'Group name can only contain letters')
      .range(
        1,
        128,
        'Must be at least 1 character',
        'Must not exceed 128 characters',
      ),
    members: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        }),
      )
      .required('Members are required'),
    members_file: Yup.mixed()
      .nullable()
      .test(
        'fileType',
        'Only CSV files are allowed',
        (value) => !value || (value && value.type === 'text/csv'),
      ),
  })
  .test('non-empty-members', EmptyMembersValidation);

export const inviteEmailValidationSchema = Yup.object()
  .shape({
    id: Yup.mixed().notRequired(),
    subject: Yup.string()
      .requiredTrimmed(
        'Subject cannot be only blank spaces.',
        'Subject is required!',
      )
      .matches(onlyLettersAndSpacesRegex, 'Subject can only contain letters')
      .range(
        1,
        256,
        'Must be at least 1 character',
        'Must not exceed 256 characters',
      ),
    members: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        }),
      )
      .required('Members are required'),
    groups: Yup.array(),
    members_file: Yup.mixed()
      .nullable() // Allows null or undefined values
      .test(
        'fileType',
        'Only CSV files are allowed',
        (value) => !value || (value && value.type === 'text/csv'),
      ),
    entity_experience: Yup.mixed()
      .required('Feedback form is required')
      .test(
        'valid-feedback',
        'Feedback form is required',
        (entityExperience) => {
          const id = entityExperience?.id ?? entityExperience;
          return typeof id === 'string' || typeof id === 'number';
        },
      ),
    email_json_data: TextEditorValidation(),
    updateHTMLEditorState: Yup.boolean(),
    show_email_schedule: Yup.boolean(),
    scheduled_at: Yup.object()
      .when('show_email_schedule', {
        is: true,
        then: Yup.object().shape({
          date: Yup.mixed().required('Schedule email date is required'),
          time: Yup.mixed().required('Schedule email time is required'),
        }),
        otherwise: Yup.object().notRequired(),
      })
      .test(
        'date-time-check',
        'The selected date and time must be at least one hour from now',
        function (value) {
          const { date, time } = value;
          const { id, show_email_schedule: showEmailSchedule } = this.parent;
          if (id || !showEmailSchedule) return true;
          const selectedDateTime = dayjs(
            `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format(
              'HH:mm',
            )}`,
          );
          const nextHour = dayjs().add(1, 'hour');
          return selectedDateTime.isAfter(nextHour);
        },
      ),
    show_reminder_schedule: Yup.boolean(),
    reminder_schedules: Yup.object().when('show_reminder_schedule', {
      is: true,
      then: Yup.object().shape({
        reminder_type: Yup.string()
          .required('Reminder type is required')
          .oneOf(
            [
              'Daily',
              'Weekly',
              'Monthly',
              'Annually',
              'Every Weekday',
              'Custom',
            ],
            'Invalid reminder type',
          ),

        reminder_time: Yup.mixed().required('Reminder time is required'),
        custom_reminder_day: Yup.array()
          .of(
            Yup.string().oneOf(
              [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ],
              'Invalid day',
            ),
          )
          .when('reminder_type', {
            is: 'Custom',
            then: Yup.array()
              .min(1, 'At least one day is required for Custom reminder type')
              .required('Custom reminder days are required'),
            otherwise: Yup.array(),
          }),
        ends_at: Yup.mixed().required('End date is required'),
      }),
      otherwise: Yup.object().notRequired(),
    }),
  })
  .test('non-empty-members', EmptyMembersValidation);

export const inviteTemplateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Template name cannot be only blank spaces.',
      'Template name is required!',
    )
    .matches(
      onlyLettersAndSpacesRegex,
      'Template name can only contain letters',
    )
    .range(
      1,
      128,
      'Must be at least 1 character',
      'Must not exceed 128 characters',
    ),
  entity_experience: Yup.mixed()
    .required('Feedback form is required')
    .test('valid-feedback', 'Feedback form is required', (entityExperience) => {
      const id = entityExperience?.id ?? entityExperience;
      return typeof id === 'string' || typeof id === 'number';
    }),
  email_json_data: TextEditorValidation(false),
});
