import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';
import { addressInfoSchema } from './common';

const branchSetupSchema = ({ isRegionRequired = false } = {}) =>
  Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(
        'Location name cannot be only blank spaces.',
        'Location name is required!',
      )
      .matches(
        onlyLettersAndSpacesRegex,
        'Location name can only contain letters',
      )
      .range(
        1,
        65,
        'Must be at least 1 character.',
        'Must not exceed 65 characters',
      ),
    region: isRegionRequired
      ? Yup.mixed().required('Region is required')
      : Yup.mixed().nullable(),
    ...addressInfoSchema,
  });

export default branchSetupSchema;
