import { useEffect } from 'react';

import { CXMETER } from '../constants/global';

const usePageTitle = (title) => {
  useEffect(() => {
    document.title = `${CXMETER} - ${title}`;
  }, [title]);

  return null;
};

export default usePageTitle;
