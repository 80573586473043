import React from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from 'components';
import { useOnboardingRedirect } from 'utils/hooks';

export default function OnboardingNavigator() {
  const { isLoading } = useOnboardingRedirect();

  return isLoading ? <Loader /> : <Outlet />;
}
