import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { URLS } from 'utils/constants';
import { getPathNameFromFullURL } from 'utils/helpers';

export default function useOnboardingRedirect() {
  const lastCompletedStep = useSelector((state) =>
    getPathNameFromFullURL(
      state.authentication.parentEntity?.last_onboarding_url,
    ),
  );
  const isLoading = useSelector((state) => state.authentication.isAppLoading);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (lastCompletedStep === null) {
        if (pathname.startsWith(URLS.SIGNUP_URL)) navigate(URLS.DASHBOARD_URL);
      } else if (pathname !== lastCompletedStep) {
        navigate(getPathNameFromFullURL(lastCompletedStep));
      }
    }
  }, [isLoading]);

  return { isLoading, lastCompletedStep };
}
