import { PERFORMANCE } from 'utils/constants/global';

const getComment = (target) => {
  if (target > 80) return PERFORMANCE.EXCEPTIONAL;
  if (target > 60) return PERFORMANCE.OUTSTANDING;
  if (target > 40) return PERFORMANCE.GREAT_GOING;
  const randomIndex = Math.floor(Math.random() * PERFORMANCE.OTHERS.length);
  return PERFORMANCE.OTHERS[randomIndex];
};

const calculateDailyTarget = (branches) => {
  let totalTarget = 0;
  let topPerformer = { target: 0 };
  const underPerformers = [];
  Object.values(branches).forEach((branch) => {
    const totalFeedbacks = branch.happy + branch.neutral + branch.unhappy;
    let target = 40;
    if (branch.daily_target) {
      target = Math.ceil((totalFeedbacks * 100) / branch.daily_target);
      totalTarget += target;
      if (target < 30) {
        underPerformers.push({
          name: branch.street_address,
          target,
          totalFeedbacks,
        });
      }
      if (target > topPerformer.target) {
        topPerformer = {
          name: branch.street_address,
          target,
          totalFeedbacks,
        };
      }
    }
  });

  totalTarget = Math.ceil(totalTarget / Object.keys(branches).length);

  const dailyTarget = {
    targetAchieved: totalTarget,
    topPerformer,
    underPerformers,
    comment: getComment(totalTarget),
  };
  return dailyTarget;
};

export default calculateDailyTarget;
