import { createSlice } from '@reduxjs/toolkit';

import { MONTHLY_INSIGHTS } from 'utils/constants';

import getInsights from './insightsActions';

const INITIAL_STATE = {
  insights: {
    data: [],
    loading: false,
    date_title: new Date().getFullYear(),
    insights_type: MONTHLY_INSIGHTS,
  },
};

const insightsSlice = createSlice({
  name: 'insightsSlice',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getInsights.pending, (state) => {
      state.insights.loading = true;
    });
    builder.addCase(getInsights.rejected, (state) => {
      state.insights.loading = false;
    });
    builder.addCase(getInsights.fulfilled, (state, action) => {
      state.insights = {
        ...action.payload,
        loading: false,
      };
    });
    return builder;
  },
});

export default insightsSlice;
