import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/ui';

export default function SimilarityConfirmModal({
  onClose,
  isOpenModal,
  primaryAction,
  secondaryAction,
}) {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header
        title={t('formBuilder.similarity.popup.title')}
        onClose={onClose}
      />
      <Modal.Body>
        <div style={{ maxHeight: 'calc(100% - 100px' }}>
          {t('formBuilder.similarity.popup.text')}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryLabel={t('formBuilder.similarity.popup.primaryButton')}
        primaryAction={primaryAction}
        secondaryLabel={t('formBuilder.similarity.popup.secondaryButton')}
        secondaryAction={secondaryAction}
      />
    </Modal>
  );
}
