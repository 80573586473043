/* eslint-disable no-return-await */
import axiosInstance from 'axios';
import { isEmpty } from 'lodash';

import { deactivateOrg } from 'redux/authentication/authenticationSlice';
import {
  BAD_REQUEST_CODE,
  FORBIDDEN_CODE,
  UNAUTHORIZED_CODE,
} from 'utils/constants';
import {
  actionDispatcher,
  clearLocalStorge,
  getAccessToken,
  getRefreshToken,
  logout,
  storeTokens,
} from 'utils/helpers';

import { TOKEN_REFRESH_API } from './urls';

export const axios = axiosInstance.create();

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    const { data, status } = err.response ?? {};
    if (data?.access === 'blocked' && status === FORBIDDEN_CODE) {
      actionDispatcher(deactivateOrg);
    }
    if (data?.code === 'user_inactive' && status === UNAUTHORIZED_CODE) {
      logout();
    }
    return Promise.reject(err);
  },
);

axios.defaults.withCredentials = true;

// eslint-disable-next-line consistent-return
async function refreshAccessToken() {
  try {
    return (await axios.post(TOKEN_REFRESH_API, { refresh: getRefreshToken() }))
      .data;
  } catch (error) {
    if (
      error.response.status === UNAUTHORIZED_CODE ||
      error.response.status === BAD_REQUEST_CODE
    ) {
      clearLocalStorge();
      window.location.href = window.origin;
    } else {
      throw error;
    }
  }
}

async function getRefreshedToken() {
  const accessToken = await refreshAccessToken();
  if (!isEmpty(accessToken)) {
    storeTokens(accessToken);
    return accessToken.access;
  }
  return null;
}

export async function getAPICall(
  url,
  params = {},
  withToken = true,
  responseType = '',
) {
  params.from_web = true;
  const config = {
    headers: { Authorization: withToken ? `Bearer ${getAccessToken()}` : '' },
    responseType,
    params,
  };
  try {
    return (await axios.get(url, config)).data;
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await getAPICall(url, params, withToken);
      }
    }
    throw error;
  }
}

export async function postAPICall(
  url,
  data,
  params = {},
  withToken = true,
  contentType = 'application/json',
) {
  try {
    const config = withToken
      ? {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': contentType,
          },
        }
      : {};
    return (await axios.post(url, data, { ...config, params })).data;
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await postAPICall(url, data, withToken);
      }
    }
    throw error;
  }
}

export async function putAPICall(
  url,
  data,
  withToken = true,
  contentType = 'application/json',
) {
  try {
    const config = withToken
      ? {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': contentType,
          },
        }
      : {};
    return (await axios.put(url, data, config)).data;
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await putAPICall(url, data, withToken);
      }
    }
    throw error;
  }
}

export async function patchAPICall(url, data, withToken = true) {
  try {
    const config = withToken
      ? { headers: { Authorization: `Bearer ${getAccessToken()}` } }
      : {};
    return (await axios.patch(url, data, config)).data;
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await putAPICall(url, data, withToken);
      }
    }
    throw error;
  }
}

export async function deleteAPICall(url, data, withToken = true) {
  try {
    const config = withToken
      ? { headers: { Authorization: `Bearer ${getAccessToken()}` } }
      : {};
    return await axios.delete(url, { data, ...config });
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await deleteAPICall(url, data, withToken);
      }
    }
    throw error;
  }
}

export async function duplicateAPICall(url, data, withToken = true) {
  try {
    const config = withToken
      ? { headers: { Authorization: `Bearer ${getAccessToken()}` } }
      : {};
    return (await axios.put(url, data, config)).data;
  } catch (error) {
    if (error.response.status === UNAUTHORIZED_CODE) {
      const refreshedAccessToken = await getRefreshedToken();
      if (refreshedAccessToken) {
        return await duplicateAPICall(url, withToken);
      }
    }
    throw error;
  }
}
