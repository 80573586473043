import { LATEST_FILTER } from 'utils/constants';

import Yup from './Yup';

const insightFiltersSchema = Yup.object().shape({
  filter: Yup.object().shape({
    id: Yup.string().required('Filter type is required'),
    value: Yup.mixed().when('id', {
      is: LATEST_FILTER,
      then: Yup.mixed().nullable(),
      otherwise: Yup.mixed().required('Year is required'),
    }),
  }),
});

export default insightFiltersSchema;
