import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  downloadCSVTemplateAPICall,
  inviteGroupByIdAPICall,
  inviteGroupCreateAPICall,
  inviteGroupUpdateByIdAPICall,
  inviteGroupsAPICall,
  invitePendingDeleteByIdAPICall,
  inviteReminderByIdAPICall,
  inviteScheduleByIdAPICall,
  inviteScheduleCreateAPICall,
  inviteScheduleDeleteByIdAPICall,
  inviteSchedulesAPICall,
  inviteTemplateByIdAPICall,
  inviteTemplateCreateAPICall,
  inviteTemplateDeleteByIdAPICall,
  inviteTemplateUpdateByIdAPICall,
  inviteTemplatesAPICall,
  inviteUpdateByIdAPICall,
  invitesAPICall,
  invitesByIdAPICall,
} from 'apis/emailsManagement';
import { showErrorToast, showTranslatedToast } from 'utils/helpers';

export const getInvites = createAsyncThunk(
  'emailsManagement/getInvites',
  async (params) => invitesAPICall(params).catch(showErrorToast),
);

export const getInviteById = createAsyncThunk(
  'emailsManagement/getInviteById',
  async ({ id, params }) =>
    invitesByIdAPICall(id, params).catch(showErrorToast),
);

export const updateInviteById = createAsyncThunk(
  'emailsManagement/updateInviteById',
  async ({ id, data }, { rejectWithValue }) =>
    inviteUpdateByIdAPICall(id, data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const sendInviteReminderById = createAsyncThunk(
  'emailsManagement/sendInviteReminderById',
  async ({ params, ...data }, { rejectWithValue }) =>
    inviteReminderByIdAPICall(data, params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deletePendingInviteById = createAsyncThunk(
  'emailsManagement/deletePendingInviteById',
  async (data) =>
    invitePendingDeleteByIdAPICall(data)
      .then(() => {
        showTranslatedToast('settings.manageEmails.toasts.deleteInvite');
      })
      .catch(showErrorToast),
);

export const getInviteGroups = createAsyncThunk(
  'emailsManagement/getInviteGroups',
  async (params) => inviteGroupsAPICall(params).catch(showErrorToast),
);

export const getInviteGroupsForEmailModal = createAsyncThunk(
  'emailsManagement/getInviteGroupsForEmailModal',
  async (params) => inviteGroupsAPICall(params).catch(showErrorToast),
);

export const getInviteGroupById = createAsyncThunk(
  'emailsManagement/getInviteGroupById',
  async (id) => inviteGroupByIdAPICall(id).catch(showErrorToast),
);

export const createInviteGroup = createAsyncThunk(
  'emailsManagement/createInviteGroup',
  async (data, { rejectWithValue }) =>
    inviteGroupCreateAPICall(data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const updateInviteGroupById = createAsyncThunk(
  'emailsManagement/updateInviteGroupById',
  async ({ id, data }, { rejectWithValue }) =>
    inviteGroupUpdateByIdAPICall(id, data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getInviteSchedules = createAsyncThunk(
  'emailsManagement/getInviteSchedules',
  async (params) => inviteSchedulesAPICall(params).catch(showErrorToast),
);

export const getInviteScheduleById = createAsyncThunk(
  'emailsManagement/getInviteScheduleById',
  async (id) => inviteScheduleByIdAPICall(id).catch(showErrorToast),
);

export const createInviteSchedule = createAsyncThunk(
  'emailsManagement/createInviteSchedule',
  async (data, { rejectWithValue }) =>
    inviteScheduleCreateAPICall(data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deleteInviteScheduleById = createAsyncThunk(
  'emailsManagement/deleteInviteScheduleById',
  async ({ itemId, params }, { dispatch }) =>
    inviteScheduleDeleteByIdAPICall(itemId)
      .then(() => {
        showTranslatedToast(
          'settings.manageEmails.toasts.deleteInviteSchedule',
        );
        dispatch(getInviteSchedules(params));
      })
      .catch(showErrorToast),
);

export const getInviteTemplates = createAsyncThunk(
  'emailsManagement/getInviteTemplates',
  async (params) => inviteTemplatesAPICall(params).catch(showErrorToast),
);

export const getInviteTemplateById = createAsyncThunk(
  'emailsManagement/getInviteTemplateById',
  async (id) => inviteTemplateByIdAPICall(id).catch(showErrorToast),
);

export const createInviteTemplate = createAsyncThunk(
  'emailsManagement/createInviteTemplate',
  async (data, { rejectWithValue }) =>
    inviteTemplateCreateAPICall(data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const updateInviteTemplateById = createAsyncThunk(
  'emailsManagement/updateInviteTemplateById',
  async ({ id, data }, { rejectWithValue }) =>
    inviteTemplateUpdateByIdAPICall(id, data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deleteInviteTemplateById = createAsyncThunk(
  'emailsManagement/deleteInviteTemplateById',
  async ({ itemId, params }, { dispatch }) =>
    inviteTemplateDeleteByIdAPICall(itemId)
      .then(() => {
        showTranslatedToast(
          'settings.manageEmails.toasts.deleteInviteTemplate',
        );
        dispatch(getInviteTemplates(params));
      })
      .catch(showErrorToast),
);

export const getInviteTemplatesForEmailModal = createAsyncThunk(
  'emailsManagement/getInviteTemplatesForEmailModal',
  async (params) => inviteTemplatesAPICall(params).catch(showErrorToast),
);

export const downloadCSVTemplate = createAsyncThunk(
  'emailsManagement/downloadCSVTemplate',
  async () =>
    downloadCSVTemplateAPICall()
      .then(async (response) => {
        const blob = new Blob([await response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'template.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(showErrorToast),
);
