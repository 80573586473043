import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alert } from 'antd';
import _ from 'lodash';

import AppQRCodesModal from 'components/appQRCodes/AppQRCodesModal';
import { PARENT_ENTITY_ADMIN, URLS } from 'utils/constants';

import './_DashboardAlerts.scss';

export default function DashboardAlerts() {
  const { parentEntity, user } = useSelector((state) => state.authentication);
  const [isOpenQRModal, setIsOpenQRModal] = useState(false);
  return user.type === PARENT_ENTITY_ADMIN ? (
    <div className="alerts-container">
      {!_.isEmpty(parentEntity) && !parentEntity?.has_linked_qr_codes ? (
        <>
          <Alert
            message={
              <span>
                Go to <Link to={URLS.SURVEYS_URL}>Surveys</Link> to create QR
                codes or If you already have QR codes,{' '}
                <button
                  className="button p-0 link"
                  type="button"
                  onClick={() => setIsOpenQRModal(true)}
                >
                  Download
                </button>{' '}
                the mobile app to link those.
              </span>
            }
            type="warning"
          />
          <AppQRCodesModal
            isOpenModal={isOpenQRModal}
            onClose={() => setIsOpenQRModal(false)}
          />
        </>
      ) : null}
    </div>
  ) : null;
}
