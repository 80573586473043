/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { toast } from 'react-toastify';

import DOMPurify from 'dompurify';
import { ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { cloneDeep } from 'lodash';

import {
  ATTACHMENT,
  ATTACHMENTS,
  CHECKBOX,
  RADIO,
  RATING,
  TEXT,
} from '../constants/questionTypes';

export function createMarkup(contentState) {
  contentState = contentState || ContentState.createFromText('');
  const html = stateToHTML(contentState);
  return {
    __html: DOMPurify.sanitize(html),
  };
}

export function getSectionId(len) {
  return `section-${len + 1}`;
}

export function getQuestionId(sectionId, len) {
  return `${sectionId}-question-${len + 1}`;
}

export function getOptionId(questionId, len) {
  return `${questionId}-option-${len + 1}`;
}

export function formatSurveyOption(id, option_id, label) {
  return { id, option_id, label };
}

export const isChoicesQuestion = (type) =>
  type === CHECKBOX || type === RADIO || type === RATING;

export function formatSurveyQuestion({
  attachment_types = [],
  id,
  is_required = true,
  label,
  max_number_of_choices = 3,
  options = [],
  question_type = TEXT,
  question_uuid = undefined,
  is_active = true,
} = {}) {
  const attachmentTypes = [...attachment_types];
  if (question_type === ATTACHMENT && attachmentTypes.length < 1) {
    attachmentTypes.push(ATTACHMENTS.IMAGE);
  }
  let questionOptions = options;
  if (questionOptions.length < 1 && isChoicesQuestion(question_type)) {
    const choiceId = getOptionId(id, questionOptions.length);
    const blocksFromHTML = convertFromHTML('');
    const optionLabel = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    const choice = formatSurveyOption(choiceId, undefined, optionLabel);
    questionOptions = [choice];
  }

  const question = {
    id,
    label,
    question_type,
    options: questionOptions,
    attachment_types: attachmentTypes,
    is_required,
    max_number_of_choices,
    is_active,
  };

  // we have to send question_uuid only when max_choice for multiple-answer
  // is changed
  if (question_uuid) {
    question.question_uuid = question_uuid;
  }

  return question;
}

export function formatSurveySection(id, questions) {
  return { id, questions };
}

// check if the survey has atleast one rating question in other sections
export function hasOneRatingQuestion(sections, id) {
  let ratingQuestion = false;
  sections.forEach((section) => {
    if (section.id !== id) {
      section.questions.forEach((question) => {
        if (
          question.question_type === 'rating' &&
          question.is_required === true &&
          question.options.length >= 3
        ) {
          ratingQuestion = true;
        }
      });
    }
  });
  return ratingQuestion;
}

export function cleanSurvey(survey, edit = false) {
  const cleanedSurvey = cloneDeep(survey);
  cleanedSurvey.sections.forEach((section, idx) => {
    delete section.id;
    if (!edit) delete section.section_uuid;
    section.order_number = idx;
    section.questions.forEach((question) => {
      delete question.id;
      if (!edit) delete question.question_uuid;
      question.label = createMarkup(question.label).__html;
      question.options = question.options.map((option) => ({
        option_id: option.option_id || null,
        label: createMarkup(option.label).__html,
      }));
    });
  });
  return cleanedSurvey;
}

export function convertToRawSurvey(survey) {
  const rawSurvey = { ...survey };
  rawSurvey.sections.forEach((section) => {
    section.id = section.section_uuid;
    section.questions.forEach((question) => {
      question.id = question.question_uuid;
      const blocksFromHTML = convertFromHTML(question.label);
      question.label = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      question.options = question.options.map((option, idx) => {
        const optionBlocks = convertFromHTML(option.label);
        return {
          id: getOptionId(question.id, idx),
          option_id: option.option_id,
          label: ContentState.createFromBlockArray(
            optionBlocks.contentBlocks,
            optionBlocks.entityMap,
          ),
        };
      });
    });
  });
  return rawSurvey;
}

export function validateSurvey(survey) {
  if (!hasOneRatingQuestion(survey.sections, 0)) {
    toast.error(
      'At least one rating question with 3 options is required in the survey',
    );
    return false;
  }
  return true;
}
