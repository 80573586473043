import { createContext } from 'react';

const OnboardingBranchContext = createContext({
  branch: {},
  setBranch() {},
  addedBranch: {},
  setAddedBranch() {},
});

export default OnboardingBranchContext;
