/* eslint-disable no-restricted-syntax */

/* eslint-disable react/jsx-filename-extension */
import { Link } from 'react-router-dom';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  FEEDBACK_FORMS_DETAIL_SETTINGS_URL,
  FEEDBACK_FORMS_SETTINGS_URL,
  INVOICES_SETTINGS_URL,
  MANAGE_EMAILS_SETTINGS_INBOX_URL,
  MANAGE_EMAILS_SETTINGS_URL,
  MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL,
  PAYMENT_DASHBOARD_SETTINGS_URL,
  PAYMENT_PLANS_SETTINGS_URL,
  SURVEYS_URL,
  UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL,
  UPDATE_ORG_DETAILS_SETTINGS_URL,
  USERS_URL,
} from 'utils/constants/urlConstants';

export const getSettingsMenuItems = (t) => [
  {
    key: 'feedback-forms',
    label: (
      <Link
        to={FEEDBACK_FORMS_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        {t?.('settings.feedbackForms.title')}
      </Link>
    ),
    actvePaths: [
      FEEDBACK_FORMS_SETTINGS_URL,
      FEEDBACK_FORMS_DETAIL_SETTINGS_URL,
    ],
  },
  {
    key: 'qr-setup',
    label: (
      <Link to={SURVEYS_URL} state={{ isSettingMenuLink: true }}>
        {t?.('settings.qrSetup.menuTitle')}
      </Link>
    ),
    actvePaths: [SURVEYS_URL],
  },
  {
    key: 'regions-and-locations',
    label: (
      <Link
        to={MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL}
        state={{ updateTab: true, isSettingMenuLink: true }}
      >
        {t?.('settings.locations.menuTitle')}
      </Link>
    ),
    actvePaths: [MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL],
  },
  {
    key: 'users',
    label: (
      <Link to={USERS_URL} state={{ isSettingMenuLink: true }}>
        {t?.('settings.users.title')}
      </Link>
    ),
    actvePaths: [USERS_URL],
  },
  {
    key: 'email-compaign',
    label: (
      <Link
        to={MANAGE_EMAILS_SETTINGS_INBOX_URL}
        state={{ updateTab: true, isSettingMenuLink: true }}
      >
        {t?.('settings.manageEmails.title')}
      </Link>
    ),
    actvePaths: [MANAGE_EMAILS_SETTINGS_URL],
  },
  {
    key: 'organization-settings',
    label: (
      <Link to={UPDATE_ORG_DETAILS_SETTINGS_URL}>
        {t?.('settings.organization.title')}
      </Link>
    ),
    children: [
      {
        key: 'organization-detail',
        label: (
          <Link
            className="setting-menu-child-item"
            to={UPDATE_ORG_DETAILS_SETTINGS_URL}
            state={{ isSettingMenuLink: true }}
          >
            <FontAwesomeIcon icon={faCircle} />
            <span>Details</span>
          </Link>
        ),
        actvePaths: [UPDATE_ORG_DETAILS_SETTINGS_URL],
      },
      {
        key: 'organization-config',
        label: (
          <Link
            className="setting-menu-child-item"
            to={UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL}
            state={{ isSettingMenuLink: true }}
          >
            <FontAwesomeIcon icon={faCircle} />
            <span>Configuration</span>
          </Link>
        ),
        actvePaths: [UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL],
      },
    ],
  },
  {
    key: 'payment-dashboard',
    label: (
      <Link
        to={PAYMENT_DASHBOARD_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        {t?.('settings.payments.menuTitle')}
      </Link>
    ),
    actvePaths: [PAYMENT_DASHBOARD_SETTINGS_URL, PAYMENT_PLANS_SETTINGS_URL],
  },
  {
    key: 'invoices',
    label: (
      <Link to={INVOICES_SETTINGS_URL} state={{ isSettingMenuLink: true }}>
        {t?.('settings.invoices.title')}
      </Link>
    ),
    actvePaths: [INVOICES_SETTINGS_URL],
  },
];

export const settingMenuOpenKeysList = getSettingsMenuItems().map(
  (menu) => menu.key,
);

export const findSettingsActiveMenu = (items, pathname) => {
  for (const item of items) {
    const isActivePath = (item.actvePaths ?? []).find((path) =>
      pathname.includes(path),
    );
    if (isActivePath) {
      return item.key;
    }
    if (item.children) {
      const childKey = findSettingsActiveMenu(item.children, pathname);
      if (childKey) {
        return childKey;
      }
    }
  }
  return null;
};
