import React from 'react';
import { useTranslation } from 'react-i18next';

function NoDataSection() {
  const { t } = useTranslation();
  return (
    <div className="no-results">
      <img src="/assets/images/no-data-result.svg" alt="No Data" />
      <p className="page-title">{t('locationComparison.noResults.title')}</p>
      <p className="font-body font-body--lg no-results__subtitle">
        {t('locationComparison.noResults.subTitle')}
      </p>
    </div>
  );
}

export default NoDataSection;
