import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Table } from 'antd';

import LocationStatusToggleModal from 'components/modals/LocationStatusToggleModal';
import { SettingsPageContext } from 'contexts';
import { getPaginatedLocations } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { MANAGE_LOCATIONS_SETTINGS_URL } from 'utils/constants/urlConstants';
import { getLocationsColumns } from 'utils/helpers/locationsAndRegionsHelpers';

function Locations() {
  const locationStatusModalRef = useRef();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const dispatch = useDispatch();
  const {
    locations: { data: locations, loading },
  } = useSelector((state) => state.locationsAndRegions);

  const { params, onChangePagination, subTabHandler } =
    useContext(SettingsPageContext);

  useEffect(() => {
    dispatch(getPaginatedLocations({ ...params }));
  }, [params]);

  const onSelectLocation = (location) => {
    const { name } = location;
    subTabHandler({
      isActive: true,
      heading: name,
      subHeading: 'Manage and link customer experience with locations',
      hideSearchFilter: true,
    });
    navigate(`${MANAGE_LOCATIONS_SETTINGS_URL}/${location.id}`);
  };

  const onChangeLocationStatus = (location) => {
    if (locationStatusModalRef.current) {
      locationStatusModalRef.current.open(location, () => {
        dispatch(getPaginatedLocations({ ...params }));
      });
    }
  };

  return (
    <div>
      <div className="contentWrap">
        {!locationId ? (
          <>
            <Table
              className="left-align"
              scroll={{ x: 'max-content' }}
              columns={getLocationsColumns({
                onSelectLocation,
                onChangeLocationStatus,
              })}
              dataSource={locations.results}
              loading={loading}
              pagination={{
                showSizeChanger: false,
                current: params.page,
                total: locations.count,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: onChangePagination,
              }}
            />
            <LocationStatusToggleModal ref={locationStatusModalRef} />
          </>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
}

export default Locations;
