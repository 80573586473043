import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { isEmpty } from 'lodash';

import {
  deleteQr,
  getQrList,
  printAllQrs,
  updateQrListRow,
} from 'redux/qrSetup/qrSetupActions';

import { QrListColumns } from './columns';
import PrintQrModal from './components/PrintQrModal';
import QrDetailModal from './components/QrDetail';
import ShareModal from './components/ShareModal';

import './_qrSetup.scss';

export default function QrList() {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    sort_by: 'created_at',
    sort_order: 'ascend',
  });
  const [selectedQr, setSelectedQr] = useState();
  const [qrToPrint, setQrToPrint] = useState();
  const [qrToShare, setQrToShare] = useState();
  const { qrList, isPrintingAllQrs } = useSelector((s) => s.qrSetup);
  const dispatch = useDispatch();

  const onEdit = (rec) => {
    setSelectedQr(rec);
  };
  const onDelete = (rec) => {
    dispatch(deleteQr(rec.id));
  };
  const onDuplicate = (rec) => {
    setSelectedQr({ isDuplicate: true, qrIdToDuplicate: rec.id });
  };
  const onShareUrl = (rec) => {
    setQrToShare(rec);
  };
  const onDetailClose = () => {
    setSelectedQr(null);
  };
  const onDetailSaveAfter = () => {
    dispatch(getQrList(params));
  };
  const onNew = () => {
    setSelectedQr('new');
  };
  const onUpdateQrRow = (
    id,
    serialNumber,
    updatedParams,
    rowIndex,
    columnKey,
  ) => {
    dispatch(
      updateQrListRow({
        id,
        serialNumber,
        params: updatedParams,
        rowIndex,
        columnKey,
      }),
    );
  };

  const onChange = (pagination, filters, sorter) => {
    if (!isEmpty(sorter)) {
      setParams({
        ...params,
        page: pagination.current,
        sort_by: sorter.field,
        sort_order: sorter.order,
      });
    }
  };

  const printAllQr = () => {
    if (!isPrintingAllQrs) {
      dispatch(printAllQrs()).then((res) => {
        const pdfUrl = URL.createObjectURL(res.payload);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = pdfUrl;

        const printCheckInterval = setInterval(() => {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
            clearInterval(printCheckInterval);
          }
        }, 1000);

        iframe.onafterprint = function () {
          document.body.removeChild(iframe);
        };
      });
    }
  };

  useEffect(() => {
    dispatch(getQrList(params));
  }, [params]);

  return (
    <main>
      <div className="qrSetupContainer">
        <div className="heading mb-30">
          <div>
            <h3>{t('settings.qrSetup.title')}</h3>
            <p>{t('settings.qrSetup.subTitle')}</p>
          </div>
          <div className="qr-controls">
            <div>
              <button
                className="button button-primary"
                size="large"
                onClick={printAllQr}
                disabled={isPrintingAllQrs}
                aria-busy={isPrintingAllQrs ? true : null}
              >
                <FontAwesomeIcon icon={faPrint} />
                <p>{t('settings.qrSetup.printAll')}</p>
              </button>
            </div>
            <div>
              <button
                className="button qr-controls-generate "
                size="large"
                onClick={onNew}
              >
                <FontAwesomeIcon icon={faPlus} />
                <p>{t('settings.qrSetup.addNew.cta')}</p>
              </button>
            </div>
          </div>
        </div>
        <div className="contentWrap">
          <Table
            loading={
              (qrList.isDeleting || qrList.isLoading) && {
                indicator: <Spinner />,
              }
            }
            columns={QrListColumns(
              onEdit,
              onDelete,
              onUpdateQrRow,
              onShareUrl,
              setQrToPrint,
              onDuplicate,
              qrList.rowsLoading,
            )}
            scroll={{ x: 'max-content' }}
            dataSource={qrList.data}
            onChange={onChange}
            pagination={{
              total: qrList.count,
              defaultPageSize: 10,
              onChange: (page, pageSize) => setParams({ page, size: pageSize }),
            }}
          />
        </div>
      </div>
      {!isEmpty(selectedQr) && (
        <QrDetailModal
          selectedQr={selectedQr}
          onClose={onDetailClose}
          onSaveAfter={onDetailSaveAfter}
          onPrint={setQrToPrint}
        />
      )}
      {!isEmpty(qrToPrint) && (
        <PrintQrModal qr={qrToPrint} onClose={() => setQrToPrint(null)} />
      )}
      {!isEmpty(qrToShare) && (
        <ShareModal qr={qrToShare} onClose={() => setQrToShare(null)} />
      )}
    </main>
  );
}
