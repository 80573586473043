/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import {
  generateOrgApiKey,
  getOrgApiKey,
  updateOrgApiKey,
} from 'redux/settings/settingsActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { OrgAPIKeyScehma } from 'utils/validations/regOrganizationValidation';

import OrgApiKeyModal from './OrgApiKeyModal';

import './_orgAPIConfigurations.scss';

const apiTranslationKey = `settings.organization.configurations.api`;

function OrgAPIConfigurations() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const {
    orgApiKey: { data: orgApiKey, loading },
  } = useSelector((state) => state.settings);

  const [isGeneratingApiKey, setIsGeneratingApiKey] = useState(false);
  const [isOpenApiKeyModal, setIsOpenApiKeyModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      expiration_date: null,
      is_active: false,
    },
    validationSchema: OrgAPIKeyScehma,
    onSubmit: async (apiKey) => {
      const { expiration_date, is_active } = apiKey;
      const data = { is_active };
      data.expiration_date = expiration_date;
      const { isError } = await dispatch(
        updateOrgApiKey({ id: apiKey.id, data }),
        formik,
      );
      if (!isError) setIsOpenApiKeyModal(false);
    },
  });

  const handleApiKeyModal = (newValues) => {
    formik.resetForm({
      values: newValues ?? orgApiKey,
    });
    setIsOpenApiKeyModal(true);
  };

  const handleGenerateApiKey = async () => {
    setIsGeneratingApiKey(true);
    const { isError, data } = await dispatch(generateOrgApiKey());
    dispatch(getOrgApiKey());
    if (!isError && data) {
      handleApiKeyModal({ ...data, isNewKey: true });
    }
    setIsGeneratingApiKey(false);
  };

  useEffect(() => {
    dispatch(getOrgApiKey());
  }, []);

  return (
    <div className="api-configuration-container">
      <div>
        <h6>{t(`${apiTranslationKey}.title`)}</h6>
        <p>{t(`${apiTranslationKey}.subTitle`)}</p>
      </div>
      {isEmpty(orgApiKey) ? (
        <div
          className="generate-api-key-btn"
          style={{ justifySelf: 'end', alignSelf: 'center' }}
        >
          <button
            aria-busy={isGeneratingApiKey || loading ? true : null}
            onClick={handleGenerateApiKey}
            style={{ minWidth: 110 }}
            className="button cstm-btn light-cstm-btn"
          >
            {t(`${apiTranslationKey}.apiKeyDetail.generate`)}
          </button>
        </div>
      ) : (
        <div className="config-detail-container">
          <div className="api-actions">
            <h6 style={{ fontSize: 14, marginBottom: 0 }}>
              {t(`${apiTranslationKey}.apiKeyDetail.details`)}
            </h6>
            <button onClick={() => handleApiKeyModal()}>
              <Icon type="edit" />
              &nbsp; &nbsp;
              {t(`${apiTranslationKey}.apiKeyDetail.edit`)}
            </button>
          </div>
          <div className="api-key-detail">
            <div>
              <div>
                <h6>{t(`${apiTranslationKey}.apiKeyDetail.key`)}</h6>
                <p>{orgApiKey.key}</p>
              </div>
            </div>
            <div>
              {orgApiKey.expiration_date ? (
                <div>
                  <h6> {t(`${apiTranslationKey}.apiKeyDetail.expiresOn`)}</h6>
                  <p>
                    {dayjs(orgApiKey.expiration_date).format(
                      'MMMM D, YYYY - h:mm A',
                    )}
                  </p>
                </div>
              ) : null}
              {orgApiKey.created_at ? (
                <div>
                  <h6> {t(`${apiTranslationKey}.apiKeyDetail.createdOn`)}</h6>
                  <p>
                    {dayjs(orgApiKey.created_at).format(
                      'MMMM D, YYYY - h:mm A',
                    )}
                  </p>
                </div>
              ) : null}
            </div>
            <div>
              <div className="api-key-status">
                <h6> {t(`${apiTranslationKey}.apiKeyDetail.status`)}</h6>
                <p className={orgApiKey.is_active ? 'active' : ''}>
                  {orgApiKey.is_active ? 'Active' : 'Inactive'}
                </p>
              </div>
              {orgApiKey.revoked_at ? (
                <div>
                  <h6> {t(`${apiTranslationKey}.apiKeyDetail.revokedAt`)}</h6>
                  <p>
                    {dayjs(orgApiKey.revoked_at).format(
                      'MMMM D, YYYY - h:mm A',
                    )}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <OrgApiKeyModal
        formik={formik}
        isOpen={isOpenApiKeyModal}
        onClose={() => setIsOpenApiKeyModal(false)}
      />
    </div>
  );
}

export default OrgAPIConfigurations;
