export const LOCATION_FILTER = 'locationFilter';
export const SINGLE_DATE_FILTER = 'singleDateFilter';
export const BRANCH_EXP_FILTER = 'branchExpFilter';
export const BRANCH_FILTER = 'branchFilter';
export const MULTI_BRANCH_FILTER = 'multiBranchFilter';
export const EXPERIENCE_FILTER = 'experienceFilter';
export const REGION_FILTER = 'regionFilter';
export const SEARCH_FILTER = 'search_text';
export const REVIEW_FILTER = 'reviewFilter';
export const STATUS_FILTER = 'statusFilter';
export const DATE_RANGE_FILTER = 'dateRangeFilter';
