/* eslint-disable react/jsx-filename-extension */
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Switch } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { Icon } from 'components/ui';
import { firstValueData } from 'utils/helpers/emailsManagementHelper';

export const getRegionsColumns = ({
  onEditRegion,
  onDeleteRegion,
  deleteRegionsLoading,
}) => [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    className: 'region-name',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'CREATED AT',
    className: 'region-creation-date',
    render: (text, rec) =>
      rec.created_at ? dayjs(rec.created_at).format('MMMM DD • h:mm A') : '-',
    width: 210,
    key: 'created_at',
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: 'LOCATIONS',
    render: (text, rec) =>
      !isEmpty(rec.locations) ? firstValueData(rec.locations) : null,
    width: 210,
    key: 'locations',
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    width: 100,
    render: (text, rec) => {
      const isDeleting = deleteRegionsLoading[rec.id];
      return (
        <span
          style={{ pointerEvents: isDeleting ? 'none' : 'all' }}
          className="settings-tables-actions"
        >
          <button disabled={isDeleting} onClick={() => onEditRegion(rec)}>
            <Icon type="visibility" />
          </button>
          <Popconfirm
            disabled={isDeleting}
            title="Delete Region?"
            description={`Do you want to delete ${rec.name} region?`}
            onConfirm={() => onDeleteRegion(rec)}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
          >
            <button disabled={isDeleting}>
              {isDeleting ? (
                <FontAwesomeIcon width={24} height={24} icon={faSpinner} />
              ) : (
                <Icon type="delete" />
              )}
            </button>
          </Popconfirm>
        </span>
      );
    },
  },
];

export const getLocationsColumns = ({
  onSelectLocation,
  onChangeLocationStatus,
}) => [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    className: 'location-name',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'REGION',
    key: 'region',
    render: (text, rec) =>
      rec.region ? (
        <span className="attached-region-name">{rec.region}</span>
      ) : (
        <button className="attach-region" onClick={() => onSelectLocation(rec)}>
          Attach
        </button>
      ),
    width: 210,
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: 'Linked Customer Experience',
    className: 'attached-experiences-count',
    dataIndex: 'experiences_count',
    key: 'experiences_count',
    width: 210,
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
        width: 230,
      },
    }),
  },
  {
    title: 'Activation Status',
    align: 'center !important',
    render: (text, rec) => {
      return (
        <Switch
          checked={rec.is_active}
          onChange={() => onChangeLocationStatus(rec)}
        />
      );
    },
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
        width: 175,
      },
    }),
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    width: 100,
    render: (text, rec) => {
      return (
        <span className="settings-tables-actions">
          <button onClick={() => onSelectLocation(rec)}>
            <Icon type="visibility" />
          </button>
        </span>
      );
    },
  },
];
