import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Ratings } from 'components/ratings';
import { Icon } from 'components/ui';
import { HAPPY, NEUTRAL, UNHAPPY } from 'utils/constants';
import {
  ATTACHMENT,
  ATTACHMENTS,
  CHECKBOX,
  RADIO,
  RATING,
  TEXT,
} from 'utils/constants/questionTypes';
import { createMarkup } from 'utils/helpers/surveyHelpers';

export default function SurveyPreviewForm({ survey, innerRef, isTemplate }) {
  const [selectedMood, setSelectedMood] = useState('');
  const { parentEntity } = useSelector((s) => s.authentication);
  const [npsSection, ...sections] = survey?.sections ?? [];
  const npsQuestion = (npsSection?.questions ?? [])[0];

  return (
    <section className="surveyPreviewWrapper" ref={innerRef}>
      <span className="logoHeader">
        <img src="/assets/images/appLogo.svg" alt="Logo" width={120} />
        <img src={parentEntity.logo} alt="Logo" width={80} />
      </span>
      <h2 className="surveyPreviewHeading">{survey?.title}</h2>
      {npsQuestion && npsQuestion.is_active ? (
        <div className="surveyPreviewSection">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={
              isTemplate
                ? { __html: npsQuestion?.label }
                : createMarkup(npsQuestion?.label)
            }
          />
          <div className="surveyPreviewQuestionWrapper experienceIcons">
            <button onClick={() => setSelectedMood(HAPPY)}>
              <img
                src="/assets/icons/icon_happy.svg"
                className={selectedMood === HAPPY ? 'selected' : ''}
                alt={HAPPY}
              />
            </button>
            <button onClick={() => setSelectedMood(NEUTRAL)}>
              <img
                src="/assets/icons/icon_neutral.svg"
                className={selectedMood === NEUTRAL ? 'selected' : ''}
                alt={NEUTRAL}
              />
            </button>
            <button onClick={() => setSelectedMood(UNHAPPY)}>
              <img
                src="/assets/icons/icon_unhappy.svg"
                className={selectedMood === UNHAPPY ? 'selected' : ''}
                alt={UNHAPPY}
              />
            </button>
          </div>
        </div>
      ) : null}

      {(sections ?? []).map((section) => (
        <div key={section.id} className="surveyPreviewSection">
          {section.questions.map((question) => (
            <div key={question.id} className="surveyPreviewQuestionWrapper">
              <div className="surveyPreviewQuestionLabel">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    isTemplate
                      ? { __html: question?.label }
                      : createMarkup(question?.label)
                  }
                />
                {question.is_required && <span>*</span>}
              </div>
              {question.question_type === CHECKBOX ? (
                <div style={{ marginTop: '-1rem' }}>
                  {`Maximum of
                  ${question.max_number_of_choices || 0} choice(s) allowed.`}
                </div>
              ) : null}

              {(question.question_type === RADIO ||
                question.question_type === CHECKBOX ||
                question.question_type === RATING) &&
                question.options && (
                  <ul className="surveyPreviewOptionsList">
                    {question.options?.map((op, idx) => (
                      <li key={idx} className="surveyPreviewOption">
                        <div
                          htmlFor={`${question.id}-${idx}`}
                          dangerouslySetInnerHTML={
                            isTemplate
                              ? { __html: op.label }
                              : createMarkup(op?.label)
                          }
                        />
                        {question.question_type === RATING ? (
                          <Ratings />
                        ) : (
                          <input
                            type={question.question_type}
                            className="surveyOptionInput"
                            id={`${question.id}-${idx}`}
                            name={`question-${question.id}`}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              {question.question_type === TEXT && (
                <textarea type="text" className="surveyAnswerTextInput" />
              )}
              <div className="attachmentListWrapper">
                {question.question_type === ATTACHMENT &&
                  question.attachment_types.includes(ATTACHMENTS.IMAGE) && (
                    <div className="surveyPreviewAttachment">
                      <input type="file" hidden id="image-attachment" />
                      <label
                        htmlFor="image-attachment"
                        className="labelWrapper"
                      >
                        <Icon type="image" />
                        <p className="label">Upload Image/ Video</p>
                      </label>
                    </div>
                  )}
                {question.question_type === ATTACHMENT &&
                  question.attachment_types.includes(ATTACHMENTS.AUDIO) && (
                    <div className="surveyPreviewAttachment">
                      <input type="file" hidden id="audio-attachment" />
                      <label
                        htmlFor="audio-attachment"
                        className="labelWrapper"
                      >
                        <Icon type="mic" />
                        <p className="label">Upload Audio</p>
                      </label>
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </section>
  );
}
