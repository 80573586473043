import { Spinner } from 'react-bootstrap';

import {
  faBan,
  faCircleCheck,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

import './_locationThumbnail.scss';

export default function LocationThumbnail({
  children,
  selected,
  onSelect,
  onEdit,
  isActive,
  isLoading,
  onStatusChange,
}) {
  return (
    <div
      className={`addItem-location ${selected ? 'selectedItem' : ''}`}
      role="button"
      tabIndex={0}
      onClick={onSelect}
    >
      <p>{children}</p>
      {isLoading ? (
        <div className="actions">
          <Spinner />
        </div>
      ) : (
        <div className="actions">
          {onEdit && (
            <Tooltip title="Edit">
              <FontAwesomeIcon
                className="actionIcon"
                icon={faPencilAlt}
                onClick={onEdit}
              />
            </Tooltip>
          )}
          {onStatusChange && (
            <Tooltip title={isActive ? 'Deactivate' : 'Activate'}>
              <FontAwesomeIcon
                className="actionIcon"
                icon={isActive ? faBan : faCircleCheck}
                onClick={onStatusChange}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}
