import React from 'react';

function PaymentPlan({ plan }) {
  return (
    <div className="planWrapper">
      <div className="planCard">
        <h3>{plan.title} Plan</h3>
        {plan.overall_cost ? (
          <div className="planPrice">
            <h1 className="planCost">${plan.overall_cost}</h1>
          </div>
        ) : (
          <>
            {plan.cost_per_user && (
              <div className="planPrice">
                <h1 className="planCost">${plan.cost_per_user}</h1>
                <p>
                  per user
                  <br />
                  per month
                </p>
              </div>
            )}
            {plan.cost_per_branch && (
              <div className="planPrice">
                <h1 className="planCost">${plan.cost_per_branch}</h1>
                <p>
                  per location
                  <br />
                  per month
                </p>
              </div>
            )}
          </>
        )}
        {plan.max_branches && (
          <div className="planRow">
            <p>Max Number of Locations</p>
            <p>{plan.max_branches}</p>
          </div>
        )}
        {plan.max_users && (
          <div className="planRow">
            <p>Max Number of Users</p>
            <p>{plan.max_users}</p>
          </div>
        )}
        {plan.overall_cost && (
          <div className="planRow">
            <p>Overall Cost</p>
            <p>${plan.overall_cost}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentPlan;
