import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';

import { SettingsPageContext } from 'contexts';
import { FeedbackFormAssignmentSect } from 'pages/onboarding/branchFeedbackFormSetup/BranchFeedbackFormsSetupScreen';
import {
  setConfigWidgets,
  setEnabledWidgets,
} from 'redux/dashboard/dashboardSlice';
import {
  assignFormtoExperience,
  postExperience,
  updateFormtoExperience,
} from 'redux/experience/experienceActions';
import { resetExperienceSlice } from 'redux/experience/experienceSlice';
import { getPaginatedLocations } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { getBranchAssignments } from 'redux/settings/settingsActions';
import { resetBranchAssignments } from 'redux/settings/settingsSlice';
import { FEEDBACK_FORMS_SETTINGS_URL } from 'utils/constants/urlConstants';
import { branchFormSetupSchema } from 'utils/validations';

export default function ExperienceForm() {
  const { state } = useLocation();
  const { locationId } = useParams();
  const { params } = useContext(SettingsPageContext);
  const { isLoading: branchAssignmentsLoading, branchAssignments } =
    useSelector((s) => s.settings);
  const {
    redirectToSurvey,
    entityData: newExp,
    updatedForms,
  } = useSelector((s) => s.experience);
  const { industry_type: industryType } = useSelector(
    (s) => s.authentication.parentEntity ?? {},
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onExperienceInfoCreate = (values) => {
    dispatch(
      assignFormtoExperience({ branchId: locationId, data: values.forms }),
    );
  };

  const onExperienceInfoUpdate = async (values) => {
    const res = await dispatch(
      updateFormtoExperience({ branchId: locationId, data: values.forms }),
    );
    if (!res.error) {
      dispatch(getPaginatedLocations(params));
    }
  };

  const formik = useFormik({
    initialValues: state?.formik
      ? { ...state?.formik }
      : {
          forms: [{}],
        },
    onSubmit: async (values) => {
      if (locationId) {
        return onExperienceInfoUpdate(values);
      }
      return onExperienceInfoCreate(values);
    },
    validationSchema: branchFormSetupSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const addMore = () => {
    formik?.setFieldValue('forms', [...formik.values.forms, {}]);
  };

  const onSave = useCallback(async () => {
    formik.submitForm();
  }, []);

  const navigateToFeedbackFormDetailPage = (
    surveyId,
    experienceId,
    expName,
  ) => {
    navigate(
      `${FEEDBACK_FORMS_SETTINGS_URL}/${surveyId}/?next=${location.pathname}&expId=${experienceId}&&expName=${expName}`,
      {
        state: { formik: formik.values, settingsContextParams: params },
      },
    );
  };

  const handleOnFormChange = async (i, obj) => {
    if (obj.survey_id) {
      dispatch(setEnabledWidgets([]));
      dispatch(setConfigWidgets([]));
    }
    await formik.setFieldValue(`forms[${i}]`, obj);
    onSave();
  };

  const handleAddNewForm = async (exp, i) => {
    const errors = await formik.validateForm();
    if (errors.forms?.[i]?.experience) return;
    if (exp.isCustom) {
      dispatch(postExperience({ exp, industryType }));
    } else {
      navigateToFeedbackFormDetailPage('new', exp.id, exp.name);
    }
  };

  const handleEdit = (id, exp) => {
    navigateToFeedbackFormDetailPage(id, exp.id, exp.name);
  };

  const handleDelete = async (selectedId) => {
    const newForms = {
      ...formik,
      values: {
        ...formik.values,
        forms: formik.values.forms.filter((form) => form.id !== selectedId),
      },
    };
    if (locationId) {
      return onExperienceInfoUpdate(newForms.values);
    }
    return onExperienceInfoCreate(newForms.values);
  };

  useEffect(() => {
    if (locationId) {
      dispatch(getBranchAssignments(locationId));
    }
    return () => {
      dispatch(resetBranchAssignments());
      dispatch(resetExperienceSlice());
    };
  }, [locationId]);

  useEffect(() => {
    // If page is not redirected from Feedback Form Detail page then don't reset the forms
    if (branchAssignments.length && !state?.formik) {
      let forms = [{}];
      forms = branchAssignments;
      formik.setValues({ forms });
    }
  }, [branchAssignments]);

  useEffect(() => {
    if (!isEmpty(formik.initialTouched))
      toast.error('Please fill in the complete form');
  }, [formik.errors]);

  useEffect(() => {
    if (redirectToSurvey) {
      formik.values.forms = formik.values.forms.filter(
        (form) => !form.experience?.isCustom,
      );
      formik.values.forms.push({
        experience: newExp.id,
      });
      navigateToFeedbackFormDetailPage('new', newExp.id, newExp.name);
      dispatch(resetExperienceSlice());
    }
  }, [redirectToSurvey, newExp]);

  useEffect(() => {
    if (updatedForms) {
      formik.setFieldValue('forms', updatedForms);
    }
  }, [updatedForms]);

  return (
    <div className="assignmentFormContainer">
      <div>
        <h1 style={{ marginBottom: 0 }}>Link a survey</h1>
        <p>Link a survey with this branch here</p>
      </div>
      <h3>{state?.branch?.name}</h3>
      <div className="expListContainer">
        {formik?.values?.forms.map((f, i) => (
          <div className="mb-10" key={i}>
            <FeedbackFormAssignmentSect
              handleAddNewForm={(exp) => handleAddNewForm(exp, i)}
              onEdit={handleEdit}
              handleSurveyEdit={handleEdit}
              showAddNew={i === formik.values.forms.length - 1}
              selectedExperiences={formik.values.forms.map((o) => o.experience)}
              hideClose={
                formik.values.forms.length < 2 || updatedForms?.length < 2
              }
              onDelete={(selectedId) => {
                handleDelete(selectedId);
              }}
              onChange={(obj) => {
                handleOnFormChange(i, obj);
              }}
              value={{ ...f }}
              error={get(formik, `errors.forms[${i}]`, {})}
              onAddMore={addMore}
              redirectState={{
                branch: state?.branch,
                formik: { ...formik.values },
              }}
              selectedExperiencesIndex={i}
              branchAssignmentsLoading={branchAssignmentsLoading}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
