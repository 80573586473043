import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AppQRCodes from 'components/appQRCodes/AppQRCodes';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { updateParentEntity } from 'redux/authentication/authenticationActions';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import './_wellcome.scss';

export default function WellcomeScreen() {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.authentication.parentEntity.name);
  const { t } = useTranslation();

  usePageTitle('Welcome');

  useEffect(() => {
    dispatch(updateParentEntity({ last_onboarding_url: null, name }));
  }, []);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title={t('onboarding.welcome.title')}>
          <p>{t('onboarding.welcome.subTitle')}</p>
          <Link to={URLS.SURVEYS_URL} className="button button-primary">
            {t('onboarding.welcome.setupQr')}
          </Link>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        <div className="auth-divider divider-vertical">or</div>
        <div>
          <p>{t('onboarding.welcome.downloadApp')}</p>
          <AppQRCodes />
          <div className="button-group">
            <Link to={URLS.DASHBOARD_URL} className="button button-primary">
              {t('onboarding.welcome.continueToDashboard')}
            </Link>
          </div>
        </div>
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
