import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { URLS, VERIFICATION } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

export default function VerifyEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();

  usePageTitle(VERIFICATION);

  useEffect(() => {
    if (_.isEmpty(state)) {
      navigate(URLS.LOGIN_URL);
    }
  }, [navigate]);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form
          title="Register with CXMeter"
          subtitle="A verification email has been sent to you. Please verify your Email by clicking the link sent to you."
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
