import { useState } from 'react';

import { Icon } from 'components/ui';

import './_passwordField.scss';

function PasswordField({ value, onChange, id, placeholder, error }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <label
      className={`control control-icon ${error ? 'control-invalid' : ''} `}
    >
      <input
        name={id}
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <button
        // type button is necessary to avoid accidental form submissions
        type="button"
        className="control-action-icon"
        onClick={handleTogglePasswordVisibility}
      >
        <Icon type={isPasswordVisible ? 'visibility' : 'visibility_off'} />
      </button>
      <span className="control-error">{error}</span>
    </label>
  );
}

export default PasswordField;
