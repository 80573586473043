import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { getDropdownExperiences } from 'redux/paginatedDropdowns/paginatedDropdownsActions';

/**
 * @author
 * @function BranchList
 * */

function ExpListFilter({ placeholder, ...rest }) {
  const { data: experiences, loading } = useSelector(
    (state) => state.paginatedDropdowns.experiences,
  );

  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="experience-list-filter"
      id="experience-list"
      name="experience-name"
      options={experiences.results}
      paginationData={experiences}
      nextDataAction={getDropdownExperiences}
      isPaginationLoading={loading}
      placeholder={placeholder ?? 'Select Experience'}
      {...rest}
    />
  );
}

export default ExpListFilter;
