import calculateDailyTarget from './calculateDailyTarget';

const createDigestMessage = ({
  entityName,
  totalHappyCustomers,
  totalNeutralCustomers,
  totalUnhappyCustomers,
  totalCommentsAdded,
  totalAudiosAdded,
  totalImagesAdded,
  branchDetails,
  date,
}) => {
  const dailyTarget = calculateDailyTarget(branchDetails);
  let message = `Hi! This is the report of ${entityName} for ${date}.\nYou received ${
    totalHappyCustomers + totalNeutralCustomers + totalUnhappyCustomers
  } feedback(s).\n
😃 ${totalHappyCustomers}, 😐 ${totalNeutralCustomers}, 😡 ${totalUnhappyCustomers}
${totalCommentsAdded} comment(s), ${totalImagesAdded} picture(s), and ${totalAudiosAdded} audio note(s)\n
${dailyTarget.targetAchieved}% feedback target achieved - ${
    dailyTarget.comment
  }!\n`;

  const topPerformer = `Top Performer ${dailyTarget.topPerformer.name} Location: Total ${dailyTarget.topPerformer.totalFeedbacks} feedback(s), Target Met ${dailyTarget.topPerformer.target}%`;

  if (dailyTarget.topPerformer.name) message += topPerformer;
  if (dailyTarget.underPerformers.length > 0)
    message += '\nUnder Performers:\n';

  const branchMessage = dailyTarget.underPerformers
    ?.map(
      (underPerformer) =>
        `\t${underPerformer.name} Location: Total ${underPerformer.totalFeedbacks} feedback(s), Target Met ${underPerformer.target}%`,
    )
    .join(',\n');

  message += `${branchMessage}\n\nTo learn more about CX insights, how to publish reviews, and address customer complaints
Please click ${window.origin}/cx-badge`;
  return message;
};

export default createDigestMessage;
