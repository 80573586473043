import { uploadImageCallBack } from 'utils/helpers/richTextEditorHelpers';

import { URLS } from '.';

export const EMAIL_MANAGEMENT_PAGINATION = {
  page: 1,
  page_size: 10,
  sort_by: 'created_at',
  sort_order: 'ascend',
};

export const DELETE_SCHEDULE_PREFIX = 'schedules-';
export const DELETE_TEMPLATE_PREFIX = 'template-';
export const DELETE_PENDING_INVITE_PREFIX = 'pending-invite-';

// Email Management Page Tabs Names
export const INBOX_EMAILS = 'inbox';
export const EMAIL_GROUPS = 'groups';
export const MANAGE_EMAIL_SCHEDULE = 'schedules';
export const EMAIL_TEMPLATES = 'templates';

export const MANAGE_EMAILS_TABS = [
  {
    id: INBOX_EMAILS,
    route: URLS.MANAGE_EMAILS_SETTINGS_INBOX_URL,
    name: 'Inbox',
    isActiveSubTab: false,
    subTabs: [
      { id: 'receive', name: 'Received', isSubTab: true },
      { id: 'pending', name: 'Pending', isSubTab: true },
    ],
  },
  {
    id: EMAIL_GROUPS,
    route: URLS.MANAGE_EMAILS_SETTINGS_GROUPS_URL,
    name: 'Groups',
    isActiveSubTab: false,
    subTabs: [],
  },
  {
    id: MANAGE_EMAIL_SCHEDULE,
    route: URLS.MANAGE_EMAILS_SETTINGS_SCHEDULES_URL,
    name: 'Manage Schedule',
    isActiveSubTab: false,
    subTabs: [],
  },
  {
    id: EMAIL_TEMPLATES,
    route: URLS.MANAGE_EMAILS_SETTINGS_TEMPLATES_URL,
    name: 'Email Template',
    isActiveSubTab: false,
    subTabs: [],
  },
];

// Compose Email Modal Constants

export const TIME_FORMAT = 'hh:mm:ss Z';
export const DATE_FORMAT = 'YYYY-MM-DD hh:mm:ssZ';

export const FEEDBACK_TEMPLATES_OVERLAY = 'feedback_templates';
export const EMAIL_TEMPLATES_OVERLAY = 'email_templates';

export const CXMETER_TEMPLATES_TAB = 'cxmeter-templates';
export const ENTITY_TEMPLATES_TAB = 'entity-templates';

// This array should be sync with Backend if needs to be changed.
export const REPEAT_OPTIONS = [
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Annually', label: 'Annually' },
  { value: 'Custom', label: 'Custom' },
];

// This array should be sync with Backend if needs to be changed.
export const DAYS_OPTIONS = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

export const fontSizOptions = [
  8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
];
export const fontFamilyOptions = [
  'Arial',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
export const EMAIL_EDITOR_TOOLBAR_CONFIG = {
  options: [
    'inline',
    'list',
    'image',
    'link',
    'textAlign',
    'fontSize',
    'blockType',
    'fontFamily',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    className: 'blockType',
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: '/assets/icons/icon_fontSize.svg',
    className: undefined,
    options: fontSizOptions,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: fontFamilyOptions,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link'],
    link: { icon: '/assets/icons/icon_link.svg', className: undefined },
    linkCallback: undefined,
  },
  textAlign: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { className: undefined },
    center: { className: undefined },
    right: { className: undefined },
    justify: { className: undefined },
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: false,
    uploadCallback: uploadImageCallBack,
    previewImage: true,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: '75%',
      width: '75%',
    },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
    unordered: { className: undefined },
    ordered: { className: undefined },
  },
};
