import React from 'react';

import { Avatar } from 'antd';

import { colorList } from 'utils/constants';
import { changeOpacity } from 'utils/helpers';

export default function ProfileAvatar({
  size,
  photoUrl,
  firstName,
  lastName,
  userId,
}) {
  const colorIndex = userId % colorList.length;
  return (
    <Avatar
      size={size}
      src={photoUrl}
      style={
        !photoUrl && {
          backgroundColor: changeOpacity(colorList[colorIndex], 0.12),
          color: colorList[colorIndex],
        }
      }
    >
      {!photoUrl && (
        <>
          {firstName ? firstName[0].toUpperCase() : ''}
          {lastName ? lastName[0].toUpperCase() : ''}
        </>
      )}
    </Avatar>
  );
}
