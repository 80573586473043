import { createAsyncThunk } from '@reduxjs/toolkit';

import insightsAPICall from 'apis/insights';
import { showErrorToast } from 'utils/helpers';

const getInsights = createAsyncThunk(
  'insights/getInsights',
  async (params, { rejectWithValue }) =>
    insightsAPICall(params).catch((e) => showErrorToast(e, rejectWithValue)),
);

export default getInsights;
