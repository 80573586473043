import React, { useEffect } from 'react';

import { get, has } from 'lodash';

import {
  BranchExpFilter,
  BranchListFilter,
  ExpListFilter,
} from 'components/branchExpFilter';
import MultiBranchListFilter from 'components/branchExpFilter/MultiBranchListFilter';
import { DateFilter } from 'components/dateFilter';
import { LocationFilter } from 'components/locationFilter';
import RegionFilter from 'components/regionFilter/RegionFilter';
import { ReviewFilter, StatusFilter } from 'components/reviewsFilters';
import { Searchbar } from 'components/searchbar';
import { SingleDateFilter } from 'components/singleDateFilter';
import { Icon } from 'components/ui';
import { useFetchFiltersData } from 'utils/hooks';

import {
  BRANCH_EXP_FILTER,
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  LOCATION_FILTER,
  MULTI_BRANCH_FILTER,
  REGION_FILTER,
  REVIEW_FILTER,
  SEARCH_FILTER,
  SINGLE_DATE_FILTER,
  STATUS_FILTER,
} from './filterTypes';

import './_filters.scss';

function Filters({
  value,
  doFetchFiltersData = false,
  addMainWrapper = true,
  addControlsWrapper = true,
  onChange,
  disableFilter = [],
  ...rest
}) {
  const { fetchFiltersData } = useFetchFiltersData();
  const onFilterChange = (key) => (val) => {
    onChange({ ...value, [key]: val });
  };

  useEffect(() => {
    if (doFetchFiltersData) {
      fetchFiltersData(value);
    }
  }, []);

  /* Search bar */
  const SearchFilter = has(value, SEARCH_FILTER) ? (
    <div style={{ flex: 1 }}>
      <Searchbar
        value={value[SEARCH_FILTER]}
        onChange={onFilterChange(SEARCH_FILTER)}
      />
      {value[SEARCH_FILTER] && (
        <p className="button button-primary mt-3">
          <span>{value[SEARCH_FILTER]}</span>
          <button onClick={() => onFilterChange(SEARCH_FILTER)('')}>
            <Icon type="close" />
          </button>
        </p>
      )}
    </div>
  ) : null;

  const DropdownFilters = (
    <>
      {/* Select Region */}
      {has(value, REGION_FILTER) && (
        <RegionFilter
          value={get(value, REGION_FILTER, {})}
          onChange={onFilterChange(REGION_FILTER)}
          placeholder={rest.regionPlaceholder}
          {...rest}
        />
      )}

      {/* Select Location */}
      {has(value, LOCATION_FILTER) && (
        <LocationFilter
          value={get(value, LOCATION_FILTER, {})}
          onChange={onFilterChange(LOCATION_FILTER)}
        />
      )}

      {/* Select by date */}
      {has(value, SINGLE_DATE_FILTER) && (
        <SingleDateFilter
          value={get(value, SINGLE_DATE_FILTER)}
          onChange={onFilterChange(SINGLE_DATE_FILTER)}
        />
      )}
      {has(value, BRANCH_EXP_FILTER) && (
        <BranchExpFilter
          value={get(value, BRANCH_EXP_FILTER)}
          onChange={onFilterChange(BRANCH_EXP_FILTER)}
        />
      )}
      {has(value, BRANCH_FILTER) && (
        <BranchListFilter
          value={get(value, BRANCH_FILTER)}
          onChange={onFilterChange(BRANCH_FILTER)}
          placeholder={rest.branchPlaceholder}
          {...rest}
        />
      )}
      {has(value, MULTI_BRANCH_FILTER) && (
        <MultiBranchListFilter
          value={get(value, MULTI_BRANCH_FILTER)}
          onChange={onFilterChange(MULTI_BRANCH_FILTER)}
          disabled={disableFilter.includes(MULTI_BRANCH_FILTER)}
        />
      )}
      {has(value, EXPERIENCE_FILTER) && (
        <ExpListFilter
          value={get(value, EXPERIENCE_FILTER)}
          onChange={onFilterChange(EXPERIENCE_FILTER)}
          placeholder={rest.experiencePlaceholder}
          {...rest}
        />
      )}

      {has(value, REVIEW_FILTER) && (
        <ReviewFilter
          value={get(value, REVIEW_FILTER)}
          onChange={onFilterChange(REVIEW_FILTER)}
        />
      )}
      {has(value, STATUS_FILTER) && (
        <StatusFilter
          value={get(value, STATUS_FILTER)}
          onChange={onFilterChange(STATUS_FILTER)}
        />
      )}
      {/* Select Date Range */}
      {has(value, DATE_RANGE_FILTER) && (
        <DateFilter
          value={get(value, DATE_RANGE_FILTER)}
          onChange={onFilterChange(DATE_RANGE_FILTER)}
          showText={rest.dateRangePlaceholder}
        />
      )}
    </>
  );

  const DropdownFiltersWrapper = addControlsWrapper ? (
    <div className="controlsWrap">{DropdownFilters}</div>
  ) : (
    DropdownFilters
  );

  return addMainWrapper ? (
    <div className="MainFilterWrap">
      {SearchFilter}
      {DropdownFiltersWrapper}
    </div>
  ) : (
    <>
      {SearchFilter}
      {DropdownFiltersWrapper}
    </>
  );
}
export default Filters;
