import { useDispatch } from 'react-redux';

import { has } from 'lodash';

import {
  BRANCH_EXP_FILTER,
  BRANCH_FILTER,
  EXPERIENCE_FILTER,
  MULTI_BRANCH_FILTER,
  REGION_FILTER,
  REVIEW_FILTER,
} from 'components/filters/filterTypes';
import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from 'redux/paginatedDropdowns/paginatedDropdownsActions';
import { getSegments } from 'redux/reviewsListing/reviewsListingActions';

const useFetchFiltersData = () => {
  const dispatch = useDispatch();

  const fetchFiltersData = (filtersList) => {
    if (
      has(filtersList, REGION_FILTER) ||
      has(filtersList, BRANCH_EXP_FILTER)
    ) {
      dispatch(getDropdownRegions());
    }
    if (
      has(filtersList, MULTI_BRANCH_FILTER) ||
      has(filtersList, BRANCH_FILTER) ||
      has(filtersList, BRANCH_EXP_FILTER)
    ) {
      dispatch(getDropdownLocations());
    }
    if (
      has(filtersList, EXPERIENCE_FILTER) ||
      has(filtersList, BRANCH_EXP_FILTER)
    ) {
      dispatch(getDropdownExperiences());
    }
    if (has(filtersList, REVIEW_FILTER)) {
      dispatch(getSegments());
    }
  };

  return { fetchFiltersData };
};

export default useFetchFiltersData;
