import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getSaasAgreementVersionAPICall,
  postSaasAgreementVersionAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const saveSaasAgreementVersion = createAsyncThunk(
  'saasAgreement/postAgreement',
  async (data) => postSaasAgreementVersionAPICall(data).catch(showErrorToast),
);

export const getSaasAgreement = createAsyncThunk(
  'saasAgreement/getAgreement',
  async () => getSaasAgreementVersionAPICall().catch(showErrorToast),
);
