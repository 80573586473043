import { useState } from 'react';

import html2canvas from 'html2canvas';

const useScreenshot = (
  { type, quality } = { type: 'image/png', quality: 1.0 },
) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = (node) => {
    if (!node) {
      throw new Error('You should provide correct html node.');
    }
    return html2canvas(node, { backgroundColor: null })
      .then((canvas) => {
        const base64Image = canvas.toDataURL(type, quality);
        setImage(base64Image);
        return base64Image;
      })
      .catch(setError);
  };

  return [
    image,
    takeScreenShot,
    {
      error,
    },
  ];
};

export default useScreenshot;
