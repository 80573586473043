import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedPage: 1,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState: INITIAL_STATE,
  reducers: {
    setPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    resetPage: () => INITIAL_STATE,
  },
});

const { actions, reducer } = pageSlice;
export const { setPage, resetPage } = actions;

export default reducer;
