import React, { useMemo, useState } from 'react';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from 'components/dragAndDrop';
import { QUESTION_CONSTANTS } from 'utils/constants';
import { arrayMove, surveyHelper } from 'utils/helpers';

import SurveyBuilderSection from './surveyBuilderSection/SurveyBuilderSection';
import SurveyNpsSection from './surveyNpsSection/SurveyNpsSection';

import './_surveyBuilder.scss';
import 'draft-js/dist/Draft.css';

function SurveyBuilder({
  surveySections,
  setQuestionsEdited,
  setIsSimilarErrorActive,
  formik,
  duplicateOptionsSections,
}) {
  const [selectedSection, setSelectedSection] = useState(0);
  const {
    formatSurveyQuestion,
    formatSurveySection,
    getQuestionId,
    getSectionId,
  } = surveyHelper;
  const { TEXT } = QUESTION_CONSTANTS;
  const itemIds = useMemo(
    () => surveySections.map((item) => item.id),
    [surveySections],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const sortedSections = arrayMove(surveySections, active.id, over.id);
      formik.setFieldValue('sections', sortedSections);
    }
    const activeIndex = surveySections.findIndex(
      (section) => section.id === active.id,
    );
    setSelectedSection(activeIndex);
  };

  const setSortedQuestions = (sectionId, questions) => {
    const fieldPath = `sections[${sectionId}].questions`;
    formik.setFieldValue(fieldPath, questions);
  };

  const getNewSection = () => {
    const sectionId = getSectionId(surveySections.length);
    const questionId = getQuestionId(sectionId, 0);
    const questions = [
      formatSurveyQuestion({
        id: questionId,
        label: '',
        question_type: TEXT,
      }),
    ];
    return formatSurveySection(sectionId, questions);
  };

  const handleAddSection = (index) => () => {
    const section = getNewSection();
    const temp = [...surveySections];
    temp.splice(index + 1, 0, section);
    formik.setFieldValue('sections', temp);
  };

  const handleRemoveSection = (id) => () => {
    const temp = [...surveySections];
    const index = temp.findIndex((section) => section.id === id);
    temp.splice(index, 1);
    if (temp.length === 0) temp.push(getNewSection());
    formik.setFieldValue('sections', temp);
  };

  const requiredRatingQuestionCount = useMemo(
    () =>
      surveySections.filter(
        (section) =>
          section.questions[0].question_type === 'rating' &&
          section.questions[0].is_required,
      ).length,
    [surveySections],
  );

  const [npsSection] = surveySections ?? [];

  return (
    <main className="surveyBuilderArea">
      <SurveyNpsSection
        npsSection={npsSection}
        sectionErrors={formik.errors.sections}
        setSortedQuestions={setSortedQuestions}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        formik={formik}
      />
      <DndContext
        id="sections-context"
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {surveySections.map((section, index) =>
            index !== 0 ? (
              <SortableItem
                key={section.id}
                id={section.id}
                index={index}
                setSelectedSection={setSelectedSection}
                isSurveySection
              >
                <SurveyBuilderSection
                  id={index}
                  surveyQuestions={section.questions}
                  isDeletable={requiredRatingQuestionCount > 1}
                  setSortedQuestions={setSortedQuestions}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  handleAddSection={handleAddSection(index)}
                  handleRemoveSection={handleRemoveSection(section.id)}
                  setQuestionsEdited={setQuestionsEdited}
                  setIsSimilarErrorActive={setIsSimilarErrorActive}
                  formik={formik}
                  duplicateOptionsSections={duplicateOptionsSections}
                />
              </SortableItem>
            ) : null,
          )}
        </SortableContext>
      </DndContext>
    </main>
  );
}

export default SurveyBuilder;
