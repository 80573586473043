import './modal.scss';

function WarningModal({
  className = '',
  title,
  subTitle,
  primaryAction,
  primaryActionTitle = 'Yes',
  primaryActionIcon,
  secondaryAction,
  secondaryActionTitle = 'No',
  secondaryActionIcon,
}) {
  return (
    <div className={`warning-modal ${className}`}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <p className="modal-message">{subTitle}</p>
        <div className="modal-actions">
          {secondaryAction ? (
            <button
              onClick={secondaryAction}
              className="modal-button secondary-button"
            >
              {secondaryActionIcon}
              {secondaryActionTitle}
            </button>
          ) : null}
          {primaryAction ? (
            <button
              onClick={primaryAction}
              className="modal-button primary-button"
            >
              {primaryActionIcon}
              <span>{primaryActionTitle}</span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default WarningModal;
