import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from 'redux/paginatedDropdowns/paginatedDropdownsActions';

import { ExpListFilter } from '.';

import './_branchExpFilter.scss';

/**
 * @author
 * @function BranchList
 * */

function RegionLocationExpFilter({ onChange, value, ...rest }) {
  const dispatch = useDispatch();
  const { data: locations, loading: locationsLoading } = useSelector(
    (state) => state.paginatedDropdowns.locations,
  );
  const { data: regions, loading: regionsLoading } = useSelector(
    (state) => state.paginatedDropdowns.regions,
  );

  const regionAndLocationsTabs = useMemo(() => {
    return [
      {
        id: 'regions',
        label: 'Regions',
        options: regions.results,
        paginationData: regions,
        isPaginationLoading: regionsLoading,
        nextDataAction: getDropdownRegions,
      },
      {
        id: 'locations',
        label: 'Locations',
        options: locations.results,
        paginationData: locations,
        isPaginationLoading: locationsLoading,
        nextDataAction: getDropdownLocations,
      },
    ];
  }, [regions, regionsLoading, locations, locationsLoading]);

  const currentTabRef = useRef(regionAndLocationsTabs[0]);

  const onTabChange = (newTab) => {
    currentTabRef.current = newTab;
  };

  const handleFilterChange = (newValue, activeTab) => {
    if (newValue === undefined && !activeTab) {
      onChange({ branch: null, region: null, experience: null });
    } else if (activeTab?.id === 'regions') {
      onChange({ region: newValue, branch: null, experience: null });
      dispatch(getDropdownExperiences({ page: 1, region_id: newValue.value }));
    } else if (activeTab?.id === 'locations') {
      onChange({ branch: newValue, region: null, experience: null });
      dispatch(getDropdownExperiences({ page: 1, entity_id: newValue.value }));
    }
  };

  const handleExperienceChange = (newExperience) => {
    onChange({ ...value, experience: newExperience });
  };

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <SelectWithCustomSuffixIcon
        id="region-location-list"
        name="region-location-name"
        currentFilterClassName="region-location-list-filter"
        placeholder="Region/Location"
        selectTabs={regionAndLocationsTabs}
        onTabChange={onTabChange}
        onChange={handleFilterChange}
        value={
          currentTabRef.current?.id === 'regions' ? value.region : value.branch
        }
        {...rest}
      />
      {value.region || value.branch ? (
        <ExpListFilter
          value={value.experience}
          onChange={handleExperienceChange}
          placeholder="Select Experience"
          {...rest}
        />
      ) : null}
    </div>
  );
}

export default RegionLocationExpFilter;
