/* eslint-disable no-underscore-dangle */
import React from 'react';

import { RichTextEditor } from 'components/richTextEditor';
import { createMarkup } from 'utils/helpers/surveyHelpers';

import '../_qrSetup.scss';

export default function HOCRichText({
  value,
  onChange,
  editorState,
  placeholder,
  isPreview,
  ...props
}) {
  const editorContent = value ?? editorState;
  return isPreview ? (
    editorContent && (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html:
            typeof editorContent === 'string'
              ? editorContent
              : createMarkup(editorContent.getCurrentContent()).__html,
        }}
      />
    )
  ) : (
    <RichTextEditor
      textAlignment="center"
      placeholder={placeholder}
      wrapperStyle={{ width: '100%' }}
      editorState={editorState}
      onEditorStateChange={onChange}
      {...props}
    />
  );
}
