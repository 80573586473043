import React from 'react';

import { Ratings } from 'components/ratings';
import { markupToText } from 'utils/helpers';

export default function ReviewRating({ question }) {
  return (
    <div className="feedback-ratings">
      {question.answer.map((answer) => (
        <div key={answer.option_id} className="feedback-rating">
          <p>
            {markupToText(
              question.options.filter(
                (option) => option.option_id === answer.option_id,
              )[0].label,
            )}
          </p>
          <Ratings disabled stars={answer.value} />
        </div>
      ))}
    </div>
  );
}
