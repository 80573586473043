import { useMemo } from 'react';

import { Dropdown } from 'components/ui';
import {
  COMPARISON_METHODS,
  COMPARISON_PREVIEWS,
} from 'utils/constants/compareFeedbacks';

import ComparisonControls from './ComparisonControls';

export default function NonSelectedComparisonFilters({
  onChangeComparisonMethod,
  comparisonMethod,
  comparisonPreview,
  setComparisonPreview,
  onChangeComparisonParams,
}) {
  const comparisonPreviewItems = useMemo(() => {
    return COMPARISON_PREVIEWS.map((preview) => ({
      label: preview.name,
      className: 'enabled-link',
      onClick: () => setComparisonPreview(preview),
    }));
  }, [setComparisonPreview]);

  return (
    <>
      <div className="comparison__methods-section">
        <div className="methods-container">
          <p className="m-0">By: </p>
          <div className="methods">
            {COMPARISON_METHODS.map((method) => (
              <button
                key={method.id}
                onClick={() => onChangeComparisonMethod(method)}
                className={`method ${
                  comparisonMethod.id === method.id ? 'active-method' : ''
                }`}
              >
                {method.name}
              </button>
            ))}
          </div>
        </div>
        <div className="comparison__preview-type">
          <p className="m-0">Compare in</p>
          <Dropdown
            dropdownClass="cxmeter-nav-profile"
            items={comparisonPreviewItems}
          >
            <button className="comparison__preview-dropdown">
              {comparisonPreview.name}
            </button>
          </Dropdown>
        </div>
      </div>
      <ComparisonControls
        onChangeComparisonParams={onChangeComparisonParams}
        comparisonMethod={comparisonMethod}
      />
    </>
  );
}
