import React from 'react';
import { useTranslation } from 'react-i18next';

import PortalSegmentOption from './PortalSegmentOption';

function PortalSegment({ setFeedbackStep, setSelectedSegment, skipFeedback }) {
  const { t } = useTranslation();
  return (
    <div className="feedback-segment">
      <div>
        <div>
          <p className="feedback-segment__title">
            {t('portalFeedback.segment.title')}
          </p>
          <p className="feedback-segment__subtitle">
            {t('portalFeedback.segment.subtitle')}
          </p>
        </div>
        <div className="feedback-segment__options">
          <PortalSegmentOption
            segment="happy"
            setFeedbackStep={setFeedbackStep}
            setSelectedSegment={setSelectedSegment}
          />
          <PortalSegmentOption
            segment="neutral"
            setFeedbackStep={setFeedbackStep}
            setSelectedSegment={setSelectedSegment}
          />
          <PortalSegmentOption
            segment="unhappy"
            setFeedbackStep={setFeedbackStep}
            setSelectedSegment={setSelectedSegment}
          />
        </div>
      </div>
      <button
        className="button button-secondary feedback-segment__skip-btn"
        onClick={() => skipFeedback()}
      >
        {t('portalFeedback.skip')}
      </button>
    </div>
  );
}

export default PortalSegment;
