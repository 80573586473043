function ImageBlock({ entity }) {
  const { src, width, height } = entity.getData();
  return (
    <div>
      <img
        width={width ?? '90%'}
        height={height ?? '90%'}
        src={src}
        alt="Uploaded"
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
}

export default ImageBlock;
