import { createSlice } from '@reduxjs/toolkit';

import {
  archiveNotificationsAction,
  getNotificationsAction,
  updateNotificationsAction,
} from './notificationActions';

const INITIAL_STATE = {
  notifications: {
    count: 0,
    next: null,
    prev: null,
    results: [],
  },
  isFetching: false,
  isUpdating: false,
  isArchiving: false,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetNotificationSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsAction.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getNotificationsAction.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
      state.isFetching = false;
      state.notifications = action.payload;
    });

    builder.addCase(updateNotificationsAction.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(updateNotificationsAction.rejected, (state) => {
      state.isUpdating = false;
    });
    builder.addCase(updateNotificationsAction.fulfilled, (state, action) => {
      state.notifications.results.forEach((notification) => {
        if (action.meta.arg.mark_read_ids.includes(notification.id)) {
          notification.is_read = true;
        }
      });
      state.isUpdating = false;
    });

    builder.addCase(archiveNotificationsAction.pending, (state) => {
      state.isArchiving = true;
    });
    builder.addCase(archiveNotificationsAction.rejected, (state) => {
      state.isArchiving = false;
    });
    builder.addCase(archiveNotificationsAction.fulfilled, (state, action) => {
      const targetIndex = state.notifications.results.findIndex(
        ({ id }) => id === action.payload.id,
      );
      state.notifications[targetIndex] = action.payload;
      state.isArchiving = false;
    });

    return builder;
  },
});
export const { resetNotificationSlice } = notificationSlice.actions;
export default notificationSlice;
