import React, { useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { List, Modal } from 'antd';
import _ from 'lodash';

import { getParentEntityAction } from 'redux/authentication/authenticationActions';
import { getQr } from 'redux/qrSetup/qrSetupActions';
import { removeSelectedQr } from 'redux/qrSetup/qrSetupSlice';
import { qrSetupHelper } from 'utils/helpers';

import qrInfoImage from '../../../assets/images/qr-guide.png';
import HOCRichText from './HOCRichText';

import '../_qrSetup.scss';

export default function PrintQrModal({ qr, onClose }) {
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const qrCodeImg = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({ content: () => qrCodeImg.current });
  const { selectedQrCode, isInitializing } = useSelector((s) => s.qrSetup);

  useEffect(() => {
    dispatch(getQr(qr.id));
    dispatch(getParentEntityAction());
    return () => dispatch(removeSelectedQr());
  }, []);

  return (
    <Modal
      centered
      maskClosable
      closeIcon
      destroyOnClose
      title={<div>Print QR</div>}
      open
      onCancel={onClose}
      cancelText="Back"
      onOk={handlePrint}
      okText="Print"
      cancelButtonProps={{
        className: 'button button-secondary',
      }}
      okButtonProps={{
        className: 'button button-primary',
      }}
    >
      {isInitializing ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <div className="printModalContainer" ref={qrCodeImg}>
          <div className="qrDetail">
            <div className="mb-30 qrFormContainer">
              <div className="mb-30">
                <List
                  className="qrForm"
                  style={{
                    background: _.get(selectedQrCode, 'backdrop_image')
                      ? `url(${_.get(selectedQrCode, 'backdrop_image')})`
                      : _.get(selectedQrCode, 'backdrop_color'),
                    'background-color': _.get(selectedQrCode, 'backdrop_color'),
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  <List.Item className="qrFormInner">
                    {_.get(selectedQrCode, 'include_logo') && (
                      <div
                        className="organisation"
                        style={{
                          color: _.get(selectedQrCode, 'foreground_color'),
                        }}
                      >
                        <img src={parentEntity.logo} alt="" />
                        <h3>{parentEntity.name}</h3>
                      </div>
                    )}
                    <div className="mt-20 rte">
                      <div
                        style={{
                          color: _.get(selectedQrCode, 'foreground_color'),
                        }}
                      >
                        <HOCRichText
                          value={_.get(selectedQrCode, 'name', '')}
                          placeholder="Title"
                          isPreview
                        />
                      </div>
                    </div>
                    <div className="qrCode">
                      <div>
                        <img
                          src={_.get(selectedQrCode, 'qrcode_image')}
                          alt=""
                          className="qr"
                          width={200}
                          height={200}
                        />
                      </div>
                      <img
                        className="info"
                        src={qrInfoImage}
                        alt=""
                        width={60}
                        height={80}
                        style={
                          _.get(selectedQrCode, 'foreground_color')
                            ? {
                                filter: qrSetupHelper.tintImage(
                                  _.get(selectedQrCode, 'foreground_color'),
                                ).filter,
                              }
                            : {}
                        }
                      />
                    </div>
                    <div className="rte mt-20 ">
                      <div
                        style={{
                          color: _.get(selectedQrCode, 'foreground_color'),
                        }}
                      >
                        <HOCRichText
                          value={_.get(selectedQrCode, 'description', '')}
                          placeholder="Description"
                          isPreview
                        />
                      </div>
                    </div>
                  </List.Item>
                </List>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="qr-links">
        <p>{t('settings.qrSetup.thirdPartyPrint.title')}</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.office.fedex.com/default/pvc-signs.html"
        >
          {t('settings.qrSetup.thirdPartyPrint.fedex')}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.vistaprint.com/signs-posters/plastic-hanging-signs?swsquery=Plastic&querycat=search_dropdown"
        >
          {t('settings.qrSetup.thirdPartyPrint.vista')}
        </a>
      </div>
    </Modal>
  );
}
