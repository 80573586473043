import { Entity } from 'draft-js';

const findSurveyLinkEntities = (contentBlock, callback) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey) {
      const entity = Entity.get(entityKey);
      const entityType = entity.getType();
      return entityType === 'LINK';
    }
    return null;
  }, callback);
};

function SurveyLinkComponent({ entityKey, children }) {
  const { url, isSurveyLink, targetOption } = Entity.get(entityKey).getData();
  const linkOpener = (e) => {
    e.preventDefault();
    if (isSurveyLink) return;
    if (targetOption === '_self') {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };
  return (
    <a
      target={targetOption}
      href={url}
      onClick={linkOpener}
      className={`${isSurveyLink ? 'survey-link' : 'editor-link'}`}
    >
      {children}
    </a>
  );
}

const SURVEY_LINK_DECORATOR = {
  strategy: findSurveyLinkEntities,
  component: (props) => <SurveyLinkComponent {...props} />,
};
export default SURVEY_LINK_DECORATOR;
