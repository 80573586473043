import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Menu as AntDMenu, ConfigProvider, Drawer } from 'antd';
import { isEmpty } from 'lodash';

import { Icon } from 'components/ui';
import {
  findSettingsActiveMenu,
  getSettingsMenuItems,
  settingMenuOpenKeysList,
} from 'utils/helpers/settingsHelpers';

import './_settingTabs.scss';

function SettingTabs({ children }) {
  return <div className="cxmeter-settings">{children}</div>;
}

function MenuContent({ setDrawerVisible }) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { t } = useTranslation();

  const [selectedKeys, setSelectedKeys] = useState([]);

  const menuItems = useMemo(() => {
    return getSettingsMenuItems(t);
  }, [t]);

  const onClick = (e) => {
    setSelectedKeys([e.key]);
    if (setDrawerVisible) {
      setDrawerVisible(false);
    }
  };

  useEffect(() => {
    if (!state?.isSettingMenuLink || isEmpty(selectedKeys)) {
      navigate(pathname, { replace: true, state: null });
      const selectedKey = findSettingsActiveMenu(menuItems, pathname);
      if (selectedKey) setSelectedKeys([selectedKey]);
    }
  }, [pathname]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemMarginInline: 0,
            itemBg: 'transparent',
            itemColor: '#353535',
            itemHoverColor: '#de0024',
            itemHoverBg: '#de002414',
            itemSelectedColor: '#de0024',
            itemSelectedBg: '#de002414',
            itemActiveBg: 'transparent',
          },
        },
      }}
    >
      <AntDMenu
        expandIcon={null}
        className="cxmeter-settings-tabs"
        onClick={onClick}
        inlineIndent={10}
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={settingMenuOpenKeysList}
        items={menuItems}
      />
    </ConfigProvider>
  );
}

function Menu() {
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <div className="cxmeter-mobile-navbar">
        <button onClick={() => setDrawerVisible(true)}>
          <Icon type="menu" />
        </button>
        <Drawer
          title="Settings Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          style={{ padding: 0 }}
        >
          <MenuContent setDrawerVisible={setDrawerVisible} />
        </Drawer>
      </div>
      <div className="cxmeter-desktop-navbar">
        <MenuContent />
      </div>
    </>
  );
}

function Content({ children }) {
  return <article className="cxmeter-settings-content">{children}</article>;
}

SettingTabs.Menu = Menu;
SettingTabs.Content = Content;
export default SettingTabs;
