import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { getDropdownRegions } from 'redux/paginatedDropdowns/paginatedDropdownsActions';

function RegionFilter({ placeholder, ...rest }) {
  const { data: regions, loading } = useSelector(
    (state) => state.paginatedDropdowns.regions,
  );

  return (
    <SelectWithCustomSuffixIcon
      id="regions-list"
      name="region-name"
      currentFilterClassName="region-list-filter"
      options={regions.results}
      paginationData={regions}
      nextDataAction={getDropdownRegions}
      isPaginationLoading={loading}
      placeholder={placeholder ?? 'Select Region'}
      {...rest}
    />
  );
}

export default RegionFilter;
