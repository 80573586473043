import { createAsyncThunk } from '@reduxjs/toolkit';

import { segmentListAPICall } from 'apis/cxmeter';
import {
  feedbackNoteAPICall,
  postCommentAPICall,
  resolveFeedbackAPICall,
  reviewDetailAPICall,
  reviewsListingAPICall,
  updateFeedbackNoteAPICall,
} from 'apis/feedbacks';
import { RESOLVED_STATUS } from 'utils/constants';
import { showErrorToast } from 'utils/helpers';

export const getReviews = createAsyncThunk(
  'reviewsListing/getReviews',
  async (params) => reviewsListingAPICall(params).catch(showErrorToast),
);

export const getCurrentReview = createAsyncThunk(
  'reviewsListing/getCurrentReview',
  async (reviewId) => reviewDetailAPICall(reviewId).catch(showErrorToast),
);

export const getSegments = createAsyncThunk(
  'reviewsListing/getSegments',
  async (params) => segmentListAPICall(params).catch(showErrorToast),
);

export const postFeedBackNote = createAsyncThunk(
  'reviewsListing/postFeedBackNote',
  async (params) => feedbackNoteAPICall(params).catch(showErrorToast),
);

export const updateFeedBackNote = createAsyncThunk(
  'reviewsListing/updateFeedBackNote',
  async (params) =>
    updateFeedbackNoteAPICall(params.id, params.data).catch(showErrorToast),
);

export const postComment = createAsyncThunk(
  'reviewsListing/postComment',
  async (params) =>
    postCommentAPICall(params.feedbackId, params.data).catch(showErrorToast),
);

export const resolveFeedback = createAsyncThunk(
  'reviewsListing/resolveFeedback',
  async (params) =>
    resolveFeedbackAPICall(params.feedbackId, { status: RESOLVED_STATUS })
      .then(() =>
        postCommentAPICall(params.feedbackId, { text: 'Marked as Resolved' }),
      )
      .catch(showErrorToast),
);

export const getFirstFeedback = createAsyncThunk(
  'reviewsListing/firstFeedback',
  async () =>
    reviewsListingAPICall({
      sort_by: 'created_at',
      sort_order: 'ascend',
    }).catch(showErrorToast),
);
