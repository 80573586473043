import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  compareDateRangeAPICall,
  compareEntityAPICall,
  compareExperienceAPICall,
  compareRegionAPICall,
} from 'apis/analytics';
import { entityListAPICallV2 } from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getComparisonByRegion = createAsyncThunk(
  'branchComparison/getComparisonByRegion',
  async (params) => compareRegionAPICall(params).catch(showErrorToast),
);

export const getComparisonByBranch = createAsyncThunk(
  'branchComparison/getComparisonByBranch',
  async (params) => compareEntityAPICall(params).catch(showErrorToast),
);

export const getComparisonByExperience = createAsyncThunk(
  'branchComparison/getComparisonByExperience',
  async (params) => compareExperienceAPICall(params).catch(showErrorToast),
);

export const getComparisonByDateRange = createAsyncThunk(
  'branchComparison/getComparisonByDateRange',
  async (params) => compareDateRangeAPICall(params).catch(showErrorToast),
);

export const getLocationsV2 = createAsyncThunk(
  'branchComparison/getLocationsV2',
  async (params) => entityListAPICallV2(params).catch(showErrorToast),
);
