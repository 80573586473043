import React from 'react';
import { useTranslation } from 'react-i18next';

function PortalSuggestion({ setSuggestionInput, submitFeedback, question }) {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div>
          <p className="feedback-segment__title">
            {t('portalFeedback.suggestion.title')}
          </p>
          <p className="feedback-segment__subtitle">
            {t('portalFeedback.suggestion.subtitle')}
          </p>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: question.label }} />
          <textarea
            name="suggestion"
            id="suggestion"
            cols="30"
            rows="5"
            className="feedback-suggestion__textbox"
            placeholder={t('portalFeedback.suggestion.placeholder')}
            onChange={(e) => setSuggestionInput(e.target.value)}
          />
        </div>
      </div>
      <button
        className="feedback-suggestion__submit-btn"
        onClick={submitFeedback}
      >
        {t('submit')}
      </button>
    </div>
  );
}

export default PortalSuggestion;
