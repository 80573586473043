import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDropdownExperiencesAPICall,
  getDropdownLocationsAPICall,
  getDropdownRegionsAPICall,
} from 'apis/paginatedDropdowns';
import { showErrorToast } from 'utils/helpers';

export const getDropdownRegions = createAsyncThunk(
  'paginatedDropdowns/getDropdownRegions',
  async (params) => getDropdownRegionsAPICall(params).catch(showErrorToast),
);

export const getDropdownLocations = createAsyncThunk(
  'paginatedDropdowns/getDropdownLocations',
  async (params) => getDropdownLocationsAPICall(params).catch(showErrorToast),
);

export const getDropdownExperiences = createAsyncThunk(
  'paginatedDropdowns/getDropdownExperiences',
  async (params) => getDropdownExperiencesAPICall(params).catch(showErrorToast),
);
