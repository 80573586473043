import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';

import { ProfileAvatar } from 'components';
import { editProfile } from 'redux/authentication/authenticationActions';
import editProfileValidationSchema from 'utils/validations/editProfileValidation';

import './_editProfile.scss';

function EditProfile() {
  const { user, isLoading } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(user.profile_photo);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      profile_photo: '',
    },
    validationSchema: editProfileValidationSchema,
    onSubmit: async (values) => {
      const payload = { ...values };
      if (!payload.profile_photo || typeof payload.profile_photo === 'string') {
        delete payload.profile_photo;
      }
      delete payload.survey_skipped_at;
      const userFormData = new FormData();
      Object.entries(payload).forEach(([k, v]) => userFormData.append(k, v));
      dispatch(editProfile({ id: payload.id, formData: userFormData }));
    },
  });

  useEffect(() => {
    formik.setValues(user);
  }, [user]);

  const handlePhotoUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.style.display = 'none';
    fileInput.onchange = (event) => {
      formik.setFieldValue('profile_photo', event.target.files[0]);
      const objectURL = URL.createObjectURL(event.target.files[0]);
      setPhotoUrl(objectURL);
    };
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  return (
    <article className="edit-profile-container">
      <header className="heading mb-20">
        <h3>Edit Profile</h3>
        <p>You can edit your profile here</p>
      </header>
      <div
        className="avatar-container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ProfileAvatar
          size={96}
          photoUrl={photoUrl}
          firstName={formik.values.first_name}
          lastName={formik.values.last_name}
          userId={user.id}
        />
        <div className="actions">
          {isHovered && (
            <FontAwesomeIcon
              className="actionIcon"
              icon={faPencilAlt}
              onClick={handlePhotoUpload}
            />
          )}
        </div>
      </div>
      <form className="cxmeter-form small" onSubmit={formik.handleSubmit}>
        <div className="control-group">
          <label
            className={`control ${
              formik.touched.first_name && formik.errors.first_name
                ? 'control-invalid'
                : ''
            }`}
          >
            <span className="required-field">
              First Name <span>*</span>
            </span>
            <input
              name="first_name"
              type="text"
              placeholder="First Name"
              value={formik.values?.first_name}
              onChange={formik.handleChange}
            />
            {formik.touched.first_name && (
              <span className="control-error">{formik.errors.first_name}</span>
            )}
          </label>
          <label
            className={`control ${
              formik.touched.last_name && formik.errors.last_name
                ? 'control-invalid'
                : ''
            }`}
          >
            <span className="required-field">
              Last Name <span>*</span>
            </span>
            <input
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={formik.values?.last_name}
              onChange={formik.handleChange}
            />
            {formik.touched.last_name && (
              <span className="control-error">{formik.errors.last_name}</span>
            )}
          </label>
        </div>
        <label
          className={`control ${
            formik.touched.email && formik.errors.email ? 'control-invalid' : ''
          }`}
        >
          <span>Email</span>
          <input
            disabled
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            value={formik.values?.email}
            onChange={formik.handleChange}
          />
          {formik.touched.email && (
            <span className="control-error">{formik.errors.email}</span>
          )}
        </label>
        <button
          aria-busy={isLoading ? true : null}
          className="button button-primary"
          type="submit"
        >
          Save Profile
        </button>
      </form>
    </article>
  );
}

export default EditProfile;
