/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';

import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './_audioPlayer.scss';

function AudioPlayer({ src }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const calculateTime = (secs) => {
    if (!secs || secs === Infinity) return '00:00';
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  };

  const handlePlayAudio = () => {
    if (isPlaying) audioRef.current.pause();
    else audioRef.current.play();
    setIsPlaying(!isPlaying);
  };

  const handleOnLoadedMetadata = () => {
    setDuration(audioRef.current?.duration);
  };

  const handleOnTimeUpdate = () => {
    setTimeElapsed(audioRef.current.currentTime);
    if (duration === 0 || duration === Infinity)
      setDuration(audioRef.current.duration);
  };

  const handleOnEnded = () => {
    setIsPlaying(false);
    setTimeElapsed(0);
  };

  return (
    <div className="customAudioTag">
      <audio
        className="audio"
        src={src}
        type="audio/mp3"
        ref={audioRef}
        preload="metadata"
        onLoadedMetadata={handleOnLoadedMetadata}
        onTimeUpdate={handleOnTimeUpdate}
        onEnded={handleOnEnded}
      />
      <div className="audioControls">
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          onClick={handlePlayAudio}
          className="pausePlayIcon"
        />
        <div className="progressBar">
          <div
            className="progressComplete"
            style={{ width: `${(timeElapsed / duration) * 100}%` }}
          />
        </div>
        <p className="time">
          {calculateTime(timeElapsed)}/{calculateTime(duration)}
        </p>
      </div>
    </div>
  );
}

export default AudioPlayer;
