import React, { useEffect, useRef } from 'react';

import { Modal } from 'antd';

import { SurveyPreview } from 'components/surveyBuilder';

import './_SurveyPreviewModal.scss';

export default function SurveyPreviewModal({
  isOpenModal,
  survey,
  previewRef,
  onClose,
}) {
  const ref = useRef(null);
  useEffect(() => {
    if (previewRef && ref.current) {
      previewRef.current = ref.current;
    }
  });

  return (
    <Modal
      className="survey-preview-modal"
      centered
      onCancel={onClose}
      footer={null}
      maskClosable
      closeIcon
      width={1000}
      title={<div>Preview Form</div>}
      open
      {...(!isOpenModal
        ? {
            wrapProps: { style: { transform: 'translateX(100vw)' } },
            maskStyle: { transform: 'translateX(100vw)' },
          }
        : {})}
    >
      <SurveyPreview survey={isOpenModal ? survey : null} innerRef={ref} />
    </Modal>
  );
}
