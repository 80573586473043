import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { getFirstFeedback } from 'redux/reviewsListing/reviewsListingActions';

import './_dateFilter.scss';

function DateSelector({ value, onChange }) {
  const dateFormat = 'YYYY-MM-DD';
  const dispatch = useDispatch();
  const dateType = { default: 0, custom: 1 };
  const [selectedDateOption, setSelectedDateOption] = useState(
    dateType.default,
  );
  const [selectedDate, setSelectedDate] = useState(value);
  const { RangePicker } = DatePicker;
  const { firstFeedback, isLoading } = useSelector((s) => s.reviews);
  const { parentEntity } = useSelector((s) => s.authentication);

  useEffect(() => {
    dispatch(getFirstFeedback());
  }, []);

  const definedOpts = [
    {
      id: 3,
      label: 'Last 7 Days',
      startDate: dayjs().subtract(7, 'days'),
      endDate: dayjs(),
    },
    {
      id: 5,
      label: 'Last Month',
      startDate: dayjs().subtract(1, 'month').startOf('month'),
      endDate: dayjs().subtract(1, 'month').endOf('month'),
    },
    {
      id: 6,
      label: 'Last 30 days',
      startDate: dayjs().subtract(30, 'days'),
      endDate: dayjs(),
    },
    {
      id: 8,
      label: 'Last Quarter',
      startDate: dayjs().subtract(1, 'quarter').startOf('quarter'),
      endDate: dayjs().subtract(1, 'quarter').endOf('quarter'),
    },
    {
      id: 9,
      label: 'YTD',
      startDate: dayjs().startOf('year'),
      endDate: dayjs(),
    },
  ];
  const reset = () => {
    onChange({});
  };

  const rangeChangeHandler = (range) => {
    const [startDate, endDate] = range ?? [];
    setSelectedDate({ startDate, endDate, label: 'Custom Range' });
  };

  const apply = () => {
    onChange({
      startDate: selectedDate.startDate
        ? selectedDate.startDate.format(dateFormat)
        : firstFeedback?.created_at
        ? dayjs(firstFeedback?.created_at).format(dateFormat)
        : dayjs(parentEntity?.created_at).format(dateFormat),
      endDate: selectedDate.endDate
        ? selectedDate.endDate.format(dateFormat)
        : dayjs().format(dateFormat),
      label: selectedDate.label,
    });
  };

  const select = (key) => () => {
    setSelectedDateOption(key);
    if (key === dateType.custom) {
      setSelectedDate({
        startDate: firstFeedback?.created_at
          ? dayjs(firstFeedback?.created_at)
          : dayjs(parentEntity?.created_at),
        endDate: dayjs(),
        label: 'Custom Range',
      });
    }
  };

  return (
    <div className="dateSelectorContainer">
      <div className="dateSelectorHeader">
        <button
          type="button"
          className={`dateSelectorHeaderButton ${
            selectedDateOption === dateType.default
              ? 'selectedDateSelectorHeaderButton'
              : ''
          }`}
          onClick={select(dateType.default)}
        >
          Default
        </button>
        <button
          type="button"
          className={`dateSelectorHeaderButton ${
            selectedDateOption === dateType.custom
              ? 'selectedDateSelectorHeaderButton'
              : ''
          }`}
          onClick={select(dateType.custom)}
        >
          Custom
        </button>
      </div>

      {dateType.default === selectedDateOption && (
        <div className="customOptContainer">
          {definedOpts.map((date, idx) => (
            <button
              type="button"
              onClick={() => setSelectedDate(date)}
              key={idx}
              className={selectedDate.id === date.id ? 'selected' : ''}
            >
              <div>{date.label}</div>
              <div>
                {' '}
                {date.startDate?.format(dateFormat)} -{' '}
                {date.endDate?.format(dateFormat)}
              </div>
            </button>
          ))}
        </div>
      )}

      {dateType.custom === selectedDateOption && (
        <div className="customBody">
          {isLoading ? (
            <Spinner />
          ) : (
            <RangePicker
              defaultValue={[
                dayjs(
                  firstFeedback?.created_at ?? parentEntity?.created_at,
                  dateFormat,
                ),
                dayjs(dayjs().format(dateFormat), dateFormat),
              ]}
              minDate={dayjs(
                firstFeedback?.created_at ?? parentEntity?.created_at,
                dateFormat,
              )}
              maxDate={dayjs()}
              onChange={rangeChangeHandler}
            />
          )}
        </div>
      )}

      <div className="drp-buttons">
        <button className="applyBtn" type="button" onClick={apply}>
          Apply
        </button>
        <button className="cancelBtn" type="button" onClick={reset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default DateSelector;
